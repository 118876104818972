import { Button, Form, Input, Col, Row } from "antd";
import { doPost} from "../../../../../API/apis";
import { useParams } from "react-router-dom";
import PainScale from "../../../../../assets/pain-scale.jpg";
import {
  validateBloodPressure,
  validateBasicVitals,
  validateDecimalNumber,
  validatePainScale,
} from "../../../../../utils/validator";
import Notification from "../../../../../components/Notification/Notification";
import { useState } from "react";

function BasicVitals({ onSave, onCancel, metric }) {
  const [form] = Form.useForm();
  const { id } = useParams();
  const [loading, setLoading] = useState(false)
 

  const handleCancel = ()=>{
    onCancel()
    form.resetFields()
  }

  //Post Basic Vitals
  const handleBasicVitals = async (values) => {
    setLoading(true)
    const data = {
      blood_pressure: values.blood_pressure,
      heart_rate: values.heart_rate,
      oxygen: values.O2_saturation,
      body_temp: values.body_temperatrue,
      resp_rate: values.respiratory_rate,
      pain_scale: values.pain_scale,
      height: values.height,
      weight: values.weight
    };
    try {
      const response = await doPost(
        `/patient/add-vitals/${id}/`,
        data,
      );
      if (response.status === 201) {
        Notification.success("Vital Signs Added Successfully");
        form.resetFields();
        onSave();
      } else {
        console.log(response);
      }
    } catch (error) {
      console.log(error);
    } finally{
      setLoading(false)
    }
  };

  return (
    <div>
      <Form form={form} layout="vertical" onFinish={handleBasicVitals}>
        <Row gutter={16}>
          <Col span={8}>
            <Form.Item
              name="blood_pressure"
              label="Blood Pressure(mmHg)"
              rules={[
                { validator: validateBloodPressure },
                {
                  required: true,
                  message: "Please Enter Blood Pressure!",
                },
              ]}
            >
              <Input />
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item
              name="heart_rate"
              label="Heart Rate(bpm)"
              rules={[
                {
                  validator: validateBasicVitals,
                },
              ]}
            >
              <Input maxLength={3} />
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item
              name="O2_saturation"
              label="O2 Saturation(%)"
              rules={[

                {
                  validator: validateDecimalNumber,
                },
              ]}
            >
              <Input maxLength={5} />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={16}>
          <Col span={8}>
            <Form.Item
              name="body_temperatrue"
              label="Body Temperature(C/F)"
              rules={[
                {
                  required: true,
                  message: "Please Enter Body Temperature!",
                },
                {
                  validator: validateDecimalNumber,
                },
              ]}
            >
              <Input maxLength={10} />
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item
              name="respiratory_rate"
              label="Respiratory Rate(bpm)"
              rules={[

                {
                  validator: validateBasicVitals,
                },
              ]}
            >
              <Input maxLength={5} />
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item
              name="pain_scale"
              label="Pain Scale(1-10)"
              rules={[
                { validator: validatePainScale },
              ]}
            >
              <Input maxLength={2} />
            </Form.Item>
          </Col>
        </Row>

        <Row gutter={[16,16]}>
          <Col span={12}>
            <Form.Item
              name="height"
              label={`${metric ? "Height (cm)" : "Height (in)"}`}
              rules={[
                {
                  validator: validateDecimalNumber,
                },
              ]}
            >
              <Input maxLength={10} placeholder="Height" />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              name="weight"
              label={`${metric ? "Weight (kg)" : "Weight (lb)"}`}
              rules={[
                {
                  validator: validateDecimalNumber,
                },
              ]}
            >
              <Input maxLength={10} placeholder="Weight" />
            </Form.Item>
          </Col>
        </Row>

        <div className="text-center">
          <img src={PainScale} alt="Pain Scale" width='100%'/>
        </div>

        <div className="flex justify-end">
          <Button onClick={handleCancel}>Cancel</Button>
          <Button className="ml-2" type="primary" htmlType="submit" loading={loading}>
            Save
          </Button>
        </div>
      </Form>
    </div>
  );
}

export default BasicVitals;
