import React, { useEffect, useState } from "react";
import {
  Button,
  Col,
  Form,
  Input,
  Modal,
  Row,
  Dropdown,
} from "antd";
import { EllipsisOutlined } from "@ant-design/icons";
import { emailValidator, phoneValidation } from "../../../utils/validator";
import { doDelete, doGet, doPatch, doPost } from "../../../API/apis";
import showConfirmModal from "../../../components/ModalConfirm/ModalConfirm";
import Notification from "../../../components/Notification/Notification";
import CustomTable from "../../../components/CustomTable/CustomTable";
import { actionItems } from "../../../utils/Data";


const ReferralDoctor = () => {
  const [form] = Form.useForm();
  const [edit, setEdit] = useState(false)
  const [loading, setLoading] = useState(false)
  const [selectedItem, setSelectedItem] = useState(null)
  const [visible, setVisible] = useState(false);
  const [showReferral, setShowReferral] = useState(false)
  const [referralDoctor, setReferralDoctor] = useState([]);

  const columns = [
    {
      key: "full_name",
      title: "Full Name",
      dataIndex: "full_name",
    },
    {
      key: "referral_from_speciality",
      title: "Specialty",
      dataIndex: "speciality",
    },
    {
      key: "phone",
      title: "Phone",
      dataIndex: "phone",
    },
    {
      key: "fax",
      title: "Fax",
      dataIndex: "fax",
    },
    {
      key: "email",
      title: "Email",
      dataIndex: "email",
    },
    {
      key: "facility_name",
      title: "Facility Name",
      dataIndex: "facility_name",
    },

    {
      key: "operating_hours",
      title: "Operating Hours",
      dataIndex: "operating_hrs",
    },
    {
      title: "Action",
      key: "action",
      width: "5%",
      align: "center",
      render: (_, record) => {
        const availableItems = actionItems;
        return (
          <Dropdown
            menu={{
              items: availableItems.map((item) => ({
                ...item,
                onClick: () => {
                  if (item.key === 1) {
                    handleView(record);
                  } else if (item.key === 2) {
                    handleEdit(record);
                  } else if (item.key === 3) {
                    handleDelete(record.id);
                  }
                },
              })),
            }}
            trigger={["click"]}
          >
            <EllipsisOutlined className="cursor-pointer" />
          </Dropdown>
        );
      },
    },
  ];

  const showModal = () => setVisible(true);
  const hideModal = () =>{
    setVisible(false)
    setEdit(false)
  }

  const handleDelete = (record) => {
    showConfirmModal({
      title: "Confirm Delete?",
      icon: null,
      content: "Are you sure you want to delete this?",
      okText: "Delete",
      okType: "danger",
      cancelText: "Cancel",
      className: "custom-modal",
      onOk() {
        deleteReferralDoctor(record);
      },
    });
  };

  const deleteReferralDoctor = async (id) => {
    try {
      const response = await doDelete(
        `/referral/referral-doctor-delete/${id}/`,
      );
      if (response.status === 200) {
        Notification.success("Referral Doctor deleted successfully");
       getReferralDoctor()
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  const handleEdit = (record) => {
    setSelectedItem(record)
    setEdit(true)
    form.setFieldsValue({
      full_name: record.full_name,
      speciality: record.speciality,
      phone: record.phone,
      fax: record.fax,
      email: record.email,
      full_address: record.full_address,
      facility_name: record.facility_name,
      operating_hours: record.operating_hrs,
    })
    setVisible(true)
  };
  const handleView = (record) => {
    setSelectedItem(record)
    setShowReferral(true)
  };


  const handleCancel = () => {
    form.resetFields();
    hideModal();
  };
  const handleClose = () => {
    setVisible(false);
    form.resetFields();
    setEdit(false)
  };

  const handleSave = async (values) => {
    const data = {
      full_name: values.full_name,
      speciality: values.speciality,
      phone: values.phone,
      fax: values.fax,
      email: values.email,
      full_address: values.full_address,
      facility_name: values.facility_name,
      operating_hrs: values.operating_hours,
    };
    try {
      const response = edit ? await doPatch(`/referral/referral-doctor-update/${selectedItem?.id}/`, data):
      await doPost("/referral/referral-doctor-add/", data);
      if (response.status === 201 || response.status === 200) {
        edit ? Notification.success("Referral Doctor Added Successfully"):
        Notification.success("Referral Doctor Updated Successfully")
        getReferralDoctor()
        handleClose();
      }
    } catch (error) {
      console.log(error);
    }
  };

  const getReferralDoctor = async () => {
    setLoading(true)
    try {
      const response = await doGet("/referral/referral-doctor-add/");
      if (response.status === 200) {
        setReferralDoctor(response.data);
      }
    } catch (error) {
      console.log(error);
    } finally{
      setLoading(false)
    }
  };

  useEffect(() => {
    getReferralDoctor();
  }, []);
  return (
    <div>
      <div className="flex justify-between items-center mb-2">
        <h1>Referring Provider List</h1>
        <Button type="primary" onClick={showModal}>
          Create Referral Doctor
        </Button>
      </div>
      <CustomTable loading={loading} columns={columns} dataSource={referralDoctor} key="referral_doctors"/>
      <Modal
        title={edit ? "Update Referral Doctor" : "Create Referral Doctor"}
        open={visible}
        onCancel={handleCancel}
        footer={null}
        maskClosable={false}
        width={578}
        closeIcon={
          <Button size="small" className="app-close-icon" shape="round">
            Close
          </Button>
        }
      >
        <Form form={form} layout="vertical" onFinish={handleSave}>
          <Row gutter={[16, 16]}>
            <Col xl={12} lg={12} md={12} sm={24}>
              <Form.Item
                label="Full Name"
                name="full_name"
                rules={[
                  {
                    required: true,
                    message: "Please Enter Full Name",
                  },
                ]}
              >
                <Input placeholder="Full Name" maxLength={30}/>
              </Form.Item>
            </Col>
            <Col xl={12} lg={12} md={12} sm={24}>
              <Form.Item label="Speciality" name="speciality">
                <Input placeholder="Speciality" maxLength={30}/>
              </Form.Item>
            </Col>
          </Row>

          <Row gutter={[16, 16]}>
            <Col xl={12} lg={12} md={12} sm={24}>
              <Form.Item
                label="Phone"
                name="phone"
                rules={[
                  {
                    validator: phoneValidation,
                  },
                  {
                    required: true,
                    message: "Please Enter Phone Number",
                  },
                ]}
              >
                <Input placeholder="Phone" maxLength={20} />
              </Form.Item>
            </Col>
            <Col xl={12} lg={12} md={12} sm={24}>
              <Form.Item label="Fax" name="fax">
                <Input placeholder="Fax" maxLength={30} />
              </Form.Item>
            </Col>
          </Row>
          <Form.Item label="Email" name="email"
           rules={[
            {
              validator: emailValidator
            }
           ]}
          >
            <Input placeholder="Email" maxLength={100} />
          </Form.Item>
          <Form.Item label="Facility Name" name="facility_name">
            <Input placeholder="Facility Name" maxLength={50} />
          </Form.Item>
          <Form.Item label="Full Address" name="full_address">
            <Input.TextArea
              placeholder="Full Address"
              maxLength={300}
              showCount
            />
          </Form.Item>
          <Form.Item label="Operating Hours" name="operating_hours">
            <Input placeholder="Operating Hours" maxLength={30}/>
          </Form.Item>

          <div className="flex justify-end">
            <Button className="mr-2" onClick={handleCancel}>
              Cancel
            </Button>
            <Button type="primary" htmlType="submit">
              {edit? 'Update': 'Save'}
            </Button>
          </div>
        </Form>
      </Modal>

      <Modal
       title='Referral Doctor'
       open={showReferral}
       footer={null}
       onCancel={()=> setShowReferral(false)}
       closeIcon={
        <Button size="small" className="app-close-icon" shape="round">
          Close
        </Button>
      }
      >
          <p><span className="font-medium">Full Name: </span>{selectedItem?.full_name}</p>
          <p><span className="font-medium">Speciality: </span>{selectedItem?.speciality}</p>
          <p><span className="font-medium">Phone: </span>{selectedItem?.phone}</p>
          <p><span className="font-medium">Email: </span>{selectedItem?.email}</p>
          <p><span className="font-medium">Fax: </span>{selectedItem?.fax}</p>
          <p><span className="font-medium">Facility Name: </span>{selectedItem?.facility_name}</p>
          <p><span className="font-medium">Full Address: </span>{selectedItem?.full_address}</p>
          <p><span className="font-medium">Operating Hours: </span>{selectedItem?.operating_hrs}</p>
      </Modal>
    </div>
  );
};

export default ReferralDoctor;
