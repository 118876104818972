import React, { useEffect, useState } from "react";
import {
  Button,
  Drawer,
  Form,
  Input,
  Modal,
  Dropdown,
  Card,
  Tooltip,
  Tag,
} from "antd";
import { EllipsisOutlined } from "@ant-design/icons";
import { doPatch, doGet, doPost, doDelete } from "../../../../../API/apis";
import { useParams } from "react-router-dom";
import ViewCreditMemo from "./ViewCreditMemo";
import { useStateGlobalContext } from "../../../../../contexts/GlobalContext";
import { NumberFormatter, validatePrice } from "../../../../../utils/validator";
import Notification from "../../../../../components/Notification/Notification";
import showConfirmModal from "../../../../../components/ModalConfirm/ModalConfirm";
import FormatDateTime from "../../../../../components/FormatDate/FormatDateTime";
import CustomTable from "../../../../../components/CustomTable/CustomTable";
import { creditMemoActions } from "../../../../../utils/Data";



const CreditMemo = () => {
  const { id } = useParams();
  const [form] = Form.useForm();
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [visible, setVisible] = useState(false);
  const [editMode, setEditMode] = useState(null);
  const [selectedItem, setSelectedItem] = useState(null);
  const [creditMemoView, setCreditMemoView] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const { patientBasicInfo, getCreditMemoInfo } = useStateGlobalContext();

  const columns = [
    {
      title: "Invoice ID#",
      dataIndex: "invoice_id",
      key: "invoice_id",
      width: "10%",
      align: "center",
      ellipsis: true,
    },
    {
      title: "Description",
      dataIndex: "description",
      key: "description",
      ellipsis: true,
    },

    {
      title: "Credit Amount",
      dataIndex: "credit_amount",
      key: "credit_amount",
      width: "10%",
      align: "center",
      ellipsis: true,
      render: (text) => (
        <>
          <NumberFormatter value={text} />
        </>
      ),
    },
    {
      title: "Created By",
      dataIndex: "added_by",
      key: "added_by",
      width: "15%",
      align: "center",
      ellipsis: true,
    },
    {
      title: "Created Date",
      dataIndex: "date_time",
      key: "date_time",
      width: "10%",
      align: "center",
      ellipsis: true,
      render: (text) => (
        <Tooltip title={<FormatDateTime date={text} type="time" />}>
          <span>{text ? <FormatDateTime date={text} type="date" /> : ""}</span>
        </Tooltip>
      ),
    },
    {
      title: "Status",
      dataIndex: "status",
      key: "status",
      width: "10%",
      align: "center",
      ellipsis: true,
      render: (status) =>
        status === "Redeemed" ? (
          <Tag className="bg-blue-200 font-semibold text-blue-500 rounded-md">
            {status}
          </Tag>
        ) : (
          ""
        ),
    },
    {
      title: "Action",
      key: "action",
      width: "8%",
      align: "center",
      fixed: "right",
      render: (_, record) => {
        const { status } = record;
        const availableItems =
          status === "Redeemed"
            ? creditMemoActions.filter((item) => [1].includes(item.key))
            : creditMemoActions.filter((item) => [1, 2, 3, 4].includes(item.key));
        return (
          <Dropdown
            menu={{
              items: availableItems.map((item) => ({
                ...item,
                onClick: () => {
                  if (item.key === 4) {
                    handleDelete(record.id);
                  } else if (item.key === 1) {
                    handleView(record);
                  } else if (item.key === 2) {
                    handleEdit(record);
                  } else if (item.key === 3) {
                    handleStatus(record);
                  }
                },
              })),
            }}
            trigger={["click"]}
          >
            <EllipsisOutlined className="cursor-pointer" />
          </Dropdown>
        );
      },
    },
  ];

  const showDrawer = () => {
    setVisible(true);
  };
  const onClose = () => {
    setVisible(false);
    setEditMode(false);
    form.resetFields();
  };

  const handleView = (record) => {
    setSelectedItem(record);
    setCreditMemoView(true);
  };

  const handleDelete = (record) => {
    showConfirmModal({
      title: "Confirm Delete?",
      icon: null,
      content: "Are you sure you want to delete this?",
      okText: "Delete",
      okType: "danger",
      cancelText: "Cancel",
      className: "custom-modal",
      onOk() {
        deleteCreditMemo(record);
      },
    });
  };

  const deleteCreditMemo = async (record) => {
    try {
      const response = await doDelete(
        `/bill/credit-memo-delete/${id}/${record}/`
      );
      if (response.status === 200) {
        Notification.success("Credit Memo deleted successfully");
        getCreditMemo();
        getCreditMemoInfo(id);
        onClose();
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  const handleEdit = (record) => {
    setSelectedItem(record);
    setEditMode(true);
    form.setFieldsValue({
      invoice_id: record?.invoice_id,
      description: record?.description,
      credit_amount: record?.credit_amount,
    });
    setVisible(true);
  };

  const handleStatus = async (record) => {
    let data = {
      status: "Redeemed",
    };
    const response = await doPatch(
      `/bill/credit-memo-update/${id}/${record.id}/`,
      data
    );
    if (response.status === 200) {
      Notification.success("Status udpated Successfully");
      getCreditMemo();
      getCreditMemoInfo(id);
    }
  };

  const handleCreditMemo = async (values) => {
    setIsSubmitting(true);
    const data = {
      invoice_id: values.invoice_id,
      description: values.description,
      credit_amount: values.credit_amount,
    };
    const url = editMode
      ? `/bill/credit-memo-update/${id}/${selectedItem?.id}/`
      : `/bill/credit-memo-add/${id}/`;
    try {
      const response = await (editMode
        ? doPatch(url, data)
        : doPost(url, data));
      if (response.status === 200 || response.status === 201) {
        const successMessage = editMode
          ? "Credit Memo updated successfully"
          : "Credit Memo Created Successfully";
        Notification.success(successMessage);
        getCreditMemo();
        getCreditMemoInfo(id);
        onClose();
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    } finally {
      setIsSubmitting(false);
    }
  };

  const getCreditMemo = async () => {
    setLoading(true);
    try {
      const response = await doGet(`/bill/credit-memo-get/${id}/`);
      if (response.status === 200) {
        const sortedCreditMemo = response.data.sort(
          (a, b) => new Date(b.date_time) - new Date(a.date_time)
        );
        setData(sortedCreditMemo);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    } finally {
      setLoading(false);
    }
  };

  const patientFullName = `${patientBasicInfo?.first_name} ${
    patientBasicInfo?.middle_name ? patientBasicInfo?.middle_name : ""
  } ${patientBasicInfo?.last_name}`;

  useEffect(() => {
    getCreditMemo();
    getCreditMemoInfo(id);
  }, [id]);

  return (
    <Card className="shadow-sm">
      <div className="flex justify-between mb-4">
        <h1>Credit History</h1>
        <Button type="primary" onClick={showDrawer}>
          Create Credit Memo
        </Button>
      </div>
      <CustomTable
        loading={loading}
        columns={columns}
        dataSource={data}
        key="credit_memo"
      />
      <Drawer
        title={editMode ? "Update Credit Memo" : "New Credit Memo"}
        placement="right"
        onClose={onClose}
        open={visible}
        width="578px"
        maskClosable={false}
        closeIcon={
          <Button size="small" className="drawer-close-icon" shape="round">
            Close
          </Button>
        }
      >
        <Form layout="vertical" onFinish={handleCreditMemo} form={form}>
          <div className="border border-slate-200 rounded-lg p-4 shadow-sm">
            <p>
              <span className="font-medium">Credit to: </span>
              <span>{patientFullName}</span>
            </p>
            <Form.Item name="invoice_id" label="Invoice ID">
              <Input placeholder="Enter Invoice ID" maxLength={30} />
            </Form.Item>
            <Form.Item
              name="description"
              label="Description"
              rules={[
                {
                  required: true,
                  message: "Please Enter Description",
                },
              ]}
            >
              <Input.TextArea
                placeholder="Enter description"
                maxLength={300}
                rows={4}
                showCount
              />
            </Form.Item>
            <Form.Item
              name="credit_amount"
              label="Credit Amount"
              rules={[
                {
                  required: true,
                  message: "Please Enter Amount",
                },
                {
                  validator: validatePrice,
                },
              ]}
            >
              <Input placeholder="Enter Credit Amount" maxLength={10} />
            </Form.Item>
          </div>
          <div className="mt-3 flex justify-between">
            <div>
              {editMode && (
                <Button
                  type="primary"
                  className="danger__button"
                  onClick={() => handleDelete(selectedItem?.id)}
                >
                  Delete
                </Button>
              )}
            </div>
            <div>
              <Button onClick={onClose}>Cancel</Button>
              <Button
                type="primary"
                htmlType="submit"
                className="ml-2"
                loading={isSubmitting}
              >
                {isSubmitting
                  ? editMode
                    ? "Updating..."
                    : "Saving..."
                  : editMode
                  ? "Update"
                  : "Save"}
              </Button>
            </div>
          </div>
        </Form>
      </Drawer>

      <Modal
        title="Credit Memo"
        open={creditMemoView}
        footer={null}
        onCancel={() => {
          setCreditMemoView(false);
        }}
        maskClosable={false}
        width={650}
        closeIcon={
          <Button size="small" className="app-close-icon" shape="round">
            Close
          </Button>
        }
      >
        <ViewCreditMemo creditMemoData={selectedItem} />
      </Modal>
    </Card>
  );
};

export default CreditMemo;
