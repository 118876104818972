import { Avatar, Button, Upload } from "antd";
import React, { useState } from "react";
import { UploadOutlined } from "@ant-design/icons";
import Notification from "../Notification/Notification";

const MAX_FILE_SIZE = 1 * 1024 * 1024; // 1MB

const FileUpload = ({ onChange, office_logo, accept = ".jpg, .jpeg, .png", maxFileSize = MAX_FILE_SIZE }) => {
  const [file, setFile] = useState(null);

  const handleRemove = () => {
    setFile(null);
    if (onChange) {
      onChange(null);
    }
  };

  const handleBeforeUpload = (file) => {
    if (file.size > maxFileSize) {
      Notification.error(`File size exceeds the maximum limit (${maxFileSize / (1024 * 1024)}MB)`);
      return false;
    }
    setFile(file);
    if (onChange) {
      onChange(file);
    }
    return false; // Prevent automatic upload
  };

  return (
    <>
    <Upload
      accept={accept}
      beforeUpload={handleBeforeUpload}
      onRemove={handleRemove}
      maxCount={1}
    >
      <Button icon={<UploadOutlined />}>Click to Upload</Button>
    </Upload>
    </>
  );
};

export default FileUpload;
