import React, { useState} from "react";
import { Button, Form, Input, Col, Row, Radio } from "antd";
import { doPost } from "../../../../../API/apis";
import { useParams } from "react-router-dom";
import {
  validateBasicVitals,
  validateDecimalNumber,
} from "../../../../../utils/validator";
import Notification from "../../../../../components/Notification/Notification";

function BodyFatCalculator({onSave, onCancel , metric}) {
  const { id } = useParams();
  const [form] = Form.useForm();
  const [bodyFat, setBodyFat] = useState(null);
  const [loading, setLoading] = useState(false)


  const handleCancel = ()=>{
    onCancel()
    form.resetFields()
    setBodyFat(null)
  }


  //calculate Body Fat
  const calculateBodyFat = () => {
    const ageInYears = form.getFieldValue("age");

    const gender = form.getFieldValue("gender");
    let bodyFatPercentage, heightinCm, weightInKg;
    if (metric) {
      heightinCm = form.getFieldValue("height");
      weightInKg = form.getFieldValue("weight");
    } else {
      heightinCm = form.getFieldValue("height") * 2.54;
      weightInKg = form.getFieldValue("weight") * 0.453592;
    }
    const heightInMeters = heightinCm / 100;
    const bmi = weightInKg / (heightInMeters * heightInMeters);
    if (ageInYears < 18) {
      // Calculate body fat percentage for minors (Children's Body Fat Formula)
      if (gender === "male") {
        bodyFatPercentage = 1.51 * bmi - 0.7 * ageInYears - 2.2;
      } else {
        bodyFatPercentage = 1.51 * bmi - 0.7 * ageInYears + 1.4;
      }
    } else {
      // Calculate BMI and body fat percentage for adults
      const bmi = weightInKg / (heightInMeters * heightInMeters);

      if (gender === "male") {
        bodyFatPercentage = 1.2 * bmi + 0.23 * ageInYears - 16.2;
      } else {
        bodyFatPercentage = 1.2 * bmi + 0.23 * ageInYears - 5.4;
      }
    }
    setBodyFat(bodyFatPercentage.toFixed(2));
  };

  //Add Body Fat
  const handleBodyFat = async (values) => {
    setLoading(true)
    const { height, weight, gender, age } = values;
    const data = {
      bodyfat_height: height,
      bodyfat_weight: weight,
      bodyfat_neck: gender,
      bodyfat_waist: age,
      bodyfat: bodyFat,
    };
    try {
      const response = await doPost(
        `/patient/add-bodyfat/${id}/`,
        data,
      );
      if (response.status === 201) {
        Notification.success("Body Fat Added Successfully");
        onSave()
        form.resetFields();
        setBodyFat(null);
      } 
    } catch (error) {
      console.log(error);
    } finally{
      setLoading(false)
    }
  };

  return (
    <div>
      <Form layout="vertical" form={form} onFinish={handleBodyFat}>
        <Row gutter={16}>
          <Col span={12}>
            <Form.Item
              name="age"
              label="Age"
              rules={[
                { required: true, message: "Please Enter age!" },
                {
                  validator: validateBasicVitals,
                },
              ]}
            >
              <Input maxLength={3} />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={16} className="mt-[-0.2rem]">
          <Col span={12}>
            <Form.Item
              name="gender"
              label="Gender"
              rules={[{ required: true, message: "Please Select gender!" }]}
            >
              <Radio.Group defaultChecked="male">
                <Radio value="male">Male</Radio>
                <Radio value="female">Female</Radio>
              </Radio.Group>
            </Form.Item>
          </Col>
        </Row>

        <Row gutter={16} className="mt-[-0.2rem]">
          <Col span={12}>
            <Form.Item
              name="height"
              label={metric ? "Height(cm)" : "Height(in)"}
              rules={[
                { required: true, message: "Please Enter height!" },
                {
                  validator: validateDecimalNumber,
                },
              ]}
            >
              <Input maxLength={10} placeholder="Height" />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              name="weight"
              label={metric ? "Weight(Kg)" : "Weight(lb)"}
              rules={[
                { required: true, message: "Please Enter weight!" },
                {
                  validator: validateDecimalNumber,
                },
              ]}
            >
              <Input maxLength={10} placeholder="Weight" />
            </Form.Item>
          </Col>
        </Row>
        <div className="flex items-center">
          <Button type="primary" onClick={calculateBodyFat}>
            Calculate
          </Button>
          <p className="ml-2 font-semibold">
            {bodyFat !== null && isNaN(bodyFat) === false
              ? `Body Fat: ${bodyFat}%`
              : null}
          </p>
        </div>
        <div className="flex justify-end">
          <Button onClick={handleCancel}> Cancel </Button>
          <Button
            type={!bodyFat ? "default" : "primary"}
            htmlType="submit"
            className="ml-2" 
            disabled={bodyFat === 0 || isNaN(bodyFat)}
            loading={loading}
          >
            Save
          </Button>
        </div>
      </Form>
    </div>
  );
}

export default BodyFatCalculator;
