// components/DateRangePickerCard.js
import React, { useState } from 'react';
import { Card, Form, DatePicker, Button } from 'antd';
import dayjs from 'dayjs';
import Notification from '../Notification/Notification';
import { useStateGlobalContext } from '../../contexts/GlobalContext';

const { RangePicker } = DatePicker;

const DateRangePickerCard = ({ onDateChange, onSearch, onReset }) => {
  const [form] = Form.useForm();
  const { officeSetting } = useStateGlobalContext();
  const [selectedDateRange, setSelectedDateRange] = useState(null);

  const officeDateFormat = officeSetting?.date_format === 'en-US'
    ? 'MM/DD/YYYY'
    : officeSetting?.date_format === 'en-GB'
    ? 'DD/MM/YYYY'
    : 'YYYY/MM/DD';

  // const presetRanges = {
  //   'Last 7 Days': [dayjs().subtract(7, 'days'), dayjs()],
  //   'Last 14 Days': [dayjs().subtract(14, 'days'), dayjs()],
  //   'Last 30 Days': [dayjs().subtract(30, 'days'), dayjs()],
  //   'This Month': [dayjs().startOf('month'), dayjs().endOf('month')],
  //   'Last Month': [
  //     dayjs().subtract(1, 'month').startOf('month'),
  //     dayjs().subtract(1, 'month').endOf('month')
  //   ]
  // };

  const rangePresets = [
    {
      label: 'Last 7 Days',
      value: [dayjs().add(-7, 'd'), dayjs()],
    },
    {
      label: 'Last 14 Days',
      value: [dayjs().add(-14, 'd'), dayjs()],
    },
    {
      label: 'Last 30 Days',
      value: [dayjs().add(-30, 'd'), dayjs()],
    },
    {
      label: 'Last 90 Days',
      value: [dayjs().add(-90, 'd'), dayjs()],
    },
  ];

  const handleDateRangeChange = (dates) => {
    setSelectedDateRange(dates);
    onDateChange(dates);
  };

  const handleResetForm = () => {
    form.resetFields();
    setSelectedDateRange(null);
    onReset();
  };

  const handleSearchClick = () => {
    if (selectedDateRange && selectedDateRange.length === 2) {
      onSearch(selectedDateRange);
    } else {
      Notification.warning('Please select a valid date range');
    }
  };

  return (
    <Card className="shadow-class mb-4 mt-4">
      <Form layout="vertical" form={form}>
        <div className="flex items-center">
          <Form.Item label="Select Date Range" name="date_range">
            <RangePicker
              size="medium"
              format={officeDateFormat}
              onChange={handleDateRangeChange}
              presets={rangePresets}
            />
          </Form.Item>
          <Button
            type="primary"
            className="ml-2 mt-2"
            onClick={handleSearchClick}
          >
            Search
          </Button>
          <Button className="ml-2 mt-2" onClick={handleResetForm}>
            Reset
          </Button>
        </div>
      </Form>
    </Card>
  );
};

export default DateRangePickerCard;
