import React, { useEffect, useState } from "react";
import { Modal, Form, Input, Button, AutoComplete, Row, Col } from "antd";
import { useParams } from "react-router-dom";
import { doPost } from "../../../../../../../../API/apis";
import { useStateGlobalContext } from "../../../../../../../../contexts/GlobalContext";
import { useStateInvoicesContext } from "../../../../../../../../contexts/InvoiceContext";
import Notification from "../../../../../../../../components/Notification/Notification";
import { useAppointmentSearchServices } from "../../../../../../../../API/apiUtils";
import {
  validatePrice,
  validateQuantity,
} from "../../../../../../../../utils/validator";

const CustomInvoice1 = ({ visible, onClose }) => {
  const [form] = Form.useForm();
  const { id } = useParams();
  const [saving, setSaving] = useState(false);
  const [isPriceDisabled, setIsPriceDisabled] = useState(false);
  const { patientBasicInfo, getPatientData, getInvoices } =
    useStateGlobalContext();
  const { options, handleSearchServices } = useAppointmentSearchServices();
  const { getUnpaidInvoicesCount } = useStateInvoicesContext();

  const { first_name, middle_name, last_name } = patientBasicInfo || {};

  const fullName = `${first_name || ""} ${middle_name || ""} ${
    last_name || ""
  }`;

  const handleCustomInvoice = async (values) => {
    setSaving(true);
    const quantity = Number(values.quantity);
    const serviceNameWithQuantity = `${values.service_name} x ${quantity}`;
    const totalPrice = Number(values.price) * quantity;

    const data = {
      patient_name: fullName,
      service_name: serviceNameWithQuantity,
      service_type: values?.service_type,
      price: totalPrice,
    };
    try {
      const response = await doPost(`/invoice/invoice-add/${id}/`, data);
      if (response.status === 201) {
        Notification.success("Invoice Created Successfully");
        getUnpaidInvoicesCount(id);
        getInvoices(id);
        handleCancel();
      }
    } catch (error) {
      Notification.warning("Something Went Wrong");
    } finally {
      setSaving(false);
    }
  };

  const handleCancel = () => {
    onClose();
    form.resetFields();
  };

  const handleSelect = (value) => {
    const selectedOption = options.find((option) => option.value === value);
    if (selectedOption) {
      form.setFieldsValue({ price: selectedOption.price }); // Set the price from the selected option
      setIsPriceDisabled(true); // Disable the price input
    }
  };

  const handleChange = (value) => {
    const isOptionSelected = options.some((option) => option.value === value);
    if (!isOptionSelected) {
      setIsPriceDisabled(false); // Enable the price input if not selected from options
      form.setFieldsValue({ price: undefined }); // Clear the price field
    }
  };

  useEffect(() => {
    getPatientData(id);
  }, []);

  return (
    <Modal
      open={visible}
      onCancel={onClose}
      footer={null}
      title="Create Custom Invoice"
      closeIcon={
        <Button size="small" className="app-close-icon" shape="round">
          Close
        </Button>
      }
    >
      <Form form={form} onFinish={handleCustomInvoice} layout="vertical">
        <Form.Item
          label="Service Name"
          name="service_name"
          rules={[
            {
              required: true,
              message: "Please Enter Service Name",
            },
          ]}
        >
          <AutoComplete
            onSearch={handleSearchServices}
            options={options}
            placeholder="Search Service Type"
            maxLength={100}
            allowClear
            onSelect={handleSelect}
            onChange={handleChange}
          />
        </Form.Item>
        <Form.Item label="Service Type" name="service_type">
          <Input placeholder="Service Type" maxLength={30} />
        </Form.Item>
        <Row gutter={[16, 16]}>
          <Col span={12}>
            <Form.Item
              label="Quantity"
              name="quantity"
              tooltip="Default Quantity is 1 if not selected"
              rules={[
                {
                  required: true,
                  message: "Please Enter Quantity",
                },
                {
                  validator: validateQuantity,
                },
              ]}
            >
              <Input placeholder="Quantity" ength={10} />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              label="Price"
              name="price"
              rules={[
                {
                  required: true,
                  message: "Please Enter Price",
                },
                {
                  validator: validatePrice,
                },
              ]}
            >
              <Input
                placeholder="price"
                maxLength={10}
                disabled={isPriceDisabled}
              />
            </Form.Item>
          </Col>
        </Row>

        <div className="flex justify-end">
          <Button onClick={handleCancel}>Cancel</Button>
          <Button
            type="primary"
            className="ml-2"
            htmlType="submit"
            loading={saving}
          >
            Create Custom Invoice
          </Button>
        </div>
      </Form>
    </Modal>
  );
};

export default CustomInvoice1;
