import React, { useEffect, useState } from "react";
import { Drawer, Table, Button, Form, Select, Radio,  Input, Switch } from "antd";
import {
  BsCashCoin,
  BsBank,
  BsFillCreditCard2BackFill,
  BsCardChecklist,
} from "react-icons/bs";
import { doPatch } from "../../../../../../../API/apis";
import { useParams } from "react-router-dom";
import { useStateGlobalContext } from "../../../../../../../contexts/GlobalContext";
import dayjs from "dayjs";
import { calTotal, discountCalulator, NumberFormatter } from "../../../../../../../utils/validator";
import Notification from "../../../../../../../components/Notification/Notification";
import InvoiceFooter from "../InvoiceFooter/InvoiceFooter";

const { Option } = Select;

const MySelect = ({ data, onChange }) => {
  return (
    <Select
      defaultValue="Select a discount"
      style={{ width: 200 }}
      onChange={onChange}
    >
      {Object.keys(data).map((key) => (
        <Option key={key} value={data[key].value}>
          <p>{data[key].name} </p>
          <p>{data[key].value} </p>
        </Option>
      ))}
    </Select>
  );
};

const radioOptions = [
  { label: "CARD/POS", icon: <BsFillCreditCard2BackFill />, value: "card" },
  { label: "CASH", icon: <BsCashCoin />, value: "cash" },
  { label: "BANK WIRE", icon: <BsBank />, value: "bank" },
  { label: "CHECK", icon: <BsCardChecklist />, value: "check" },
];

const LabInvoicesDrawer = ({ visible, data, onClose }) => {
  const { id } = useParams();
  const [form] = Form.useForm();
  const [discountApplied, setDiscountApplied] = useState(false);
  const [discount, setDiscount] = useState(0);
  const { getLabInvoices, patientBasicInfo, getPatientData, officeSetting ,getUnpaidLabs } =
  useStateGlobalContext();
  const [taxApplied, setTaxApplied] = useState(officeSetting?.apply_tax);

  const currentDate = dayjs().format("MM/DD/YYYY");

  const invoiceUID = data?.inhouseUID;

  const tax = officeSetting && officeSetting?.local_tax_rate;
  const taxRate = tax / 100;

  const patientFullName =
    `${patientBasicInfo?.first_name || ""} ${patientBasicInfo?.middle_name ? patientBasicInfo?.middle_name : " "} ${
      patientBasicInfo?.last_name || ""
    }` || "";


  const handleDiscountChange = (checked) => {
    if (!checked) {
      setDiscount(0);
    }
    setDiscountApplied(checked);
  };

  const handleDiscount = (value) => {
    setDiscount(parseFloat(value));
  };

  const handleTaxChange = (checked) => {
    setTaxApplied(checked);
  };

  const coloumn = [
    {
      key: 1,
      title: "#",
      render: (_, record, index) => index + 1,
    },
    {
      key: 2,
      title: "Lab Name",
      dataIndex: "lab_name",
    },
    {
      key: 3,
      title: "Service Type",
      dataIndex: "lab_type",
    },
    {
      key: 4,
      title: "Service Group",
      dataIndex: "lab_group",
    },
    {
      key: 5,
      title: "Price",
      dataIndex: "lab_price",
    },
  ];


  const totalPrice = calTotal(data?.lab_name);

  const calcTax = (invoice) => {
    const local_tax = taxApplied && invoice ? (invoice * (1 - (discount/100))) * taxRate : 0;
    return local_tax.toFixed(2);
  };

  const calcGrandTotal = (invoice) => {
    const subtotal = invoice || 0;
    const appliedDiscount = discountApplied ? discountCalulator(discount, subtotal) : 0;;
    const appliedTax = taxApplied ? calcTax(invoice) : 0;
    const total = subtotal - appliedDiscount
    const totalPrice = total + Number(appliedTax);
    return totalPrice.toFixed(2);
  };

  const updateLab = async () => {
    if (!form.getFieldValue("payment_method")) {
      Notification.error("Please select a payment method.");
      return; 
    }

    const subtotal = totalPrice;
    const discountValue = discountApplied ? discountCalulator(discount, subtotal) : 0;

    const data = {
      status: "Paid",
      payment_method: form.getFieldValue("payment_method"),
      discount: discount,
      discounted_amount: discountValue,
      tax: calcTax(totalPrice),
      notes: form.getFieldValue('notes'),
      grand_total: calcGrandTotal(totalPrice),
    };
    try {
      const response = await doPatch(
        `/labs/images/update/${id}/${invoiceUID}/`,
        data
      );
      if (response.status === 200) {
        Notification.success("Lab Invoice Updated Successfully");
        handleCancel()
        getLabInvoices(id);
        getUnpaidLabs(id)
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handleCancel = ()=>{
    setTimeout(() => {
      onClose();
      form.resetFields();
      setDiscountApplied(false)
    }, 200);

  }

  useEffect(() => {
    getPatientData(id);
  }, [id]);

  return (
    <Drawer
      title="Collect Payment"
      placement="right"
      width="780px"
      open={visible}
      onClose={onClose}
      closeIcon={
        <Button size="small" className="drawer-close-icon" shape="round">
          Close
        </Button>
      }
    >
      <p>
        <span className="font-medium">Patient Name:</span> {patientFullName}
      </p>
      <p className="mt-2">
        <span className="font-medium">Date Added:</span> {currentDate}
      </p>

      <Table
        className="mt-4"
        columns={coloumn}
        rowKey={(record) => record?.id}
        dataSource={data?.lab_name}
        size="small"
        pagination={false}
      />

      <Form form={form} className="mt-4" layout="vertical">


      <Form.Item label="Apply Discount" name="discount">
        <Switch onChange={handleDiscountChange} />
      </Form.Item>

      {discountApplied && (
        <Form.Item label="Discounts">
          <MySelect data={officeSetting?.discounts} onChange={handleDiscount} />
        </Form.Item>
      )}

      <Form.Item label="Apply Tax" name="tax">
        <Switch onChange={handleTaxChange} defaultChecked={taxApplied} />
      </Form.Item>

        <Form.Item label="Select Payment Collection Method" name="payment_method">
          <Radio.Group
            defaultValue="a"
            className="radio-tile-group"
            buttonStyle="solid"
          >
            {radioOptions.map((option, index) => (
              <Radio.Button key={index} value={option.value}>
                <span className="flex flex-row justify-center  items-center gap-2">
                  <p>{option.label}</p>
                  <p>{option.icon}</p>
                </span>
              </Radio.Button>
            ))}
          </Radio.Group>
        </Form.Item>

        <Form.Item label="Notes" name="notes">
          <Input.TextArea maxLength={300} showCount />
        </Form.Item>
      </Form>
      <div className="flex justify-end mt-2">
        <div></div>
        <InvoiceFooter subTotal={totalPrice} discount={discount} discountAmount={discountCalulator(discount, totalPrice)} tax={calcTax(totalPrice)} grandTotal={calcGrandTotal(totalPrice)}/>
      </div>

      <div className="flex justify-between mt-4">
        <Button type="primary" shape="round" onClick={onClose}>
          Close
        </Button>

        <Button
          shape="round"
          className="waiting-room-button"
          onClick={updateLab}
        >
          Mark as Paid
        </Button>
      </div>
    </Drawer>
  );
};

export default LabInvoicesDrawer;
