import React, { useEffect, useState } from "react";
import logo from "../../../assets/logo.png";
import { Link, useNavigate } from "react-router-dom";
import { Form, Input, Button, Checkbox } from "antd";
import { doPost } from "../../../API/apis";
import "./login.css";
import { setSession, getSession, clearSession } from "../../../customHooks";
import { useStateGlobalContext } from "../../../contexts/GlobalContext";
import Cookies from "js-cookie";
import {
  FacebookOutlined,
  LinkedinOutlined,
  YoutubeOutlined,
} from "@ant-design/icons";
import Notification from "../../../components/Notification/Notification";

const Login = () => {
  const [loading, setLoading] = useState(false);
  const [rememberMe, setRememberMe] = useState(false); 
  const navigate = useNavigate();
  const [form] = Form.useForm();
  const { appLogoData, getAppLogo } = useStateGlobalContext();

  document.title = "Login | American EMR";

  useEffect(() => {
    getAppLogo();

    // Check if credentials are stored in cookies
    const savedUsername = Cookies.get("username");
    if (savedUsername) {
      form.setFieldsValue({
        username: savedUsername,
      });
      setRememberMe(true);  // Set the "Remember Me" checkbox to checked
    }
  }, [form]);

  const onFinish = async (values) => {
    setLoading(true);

    try {
      const result = await doPost(`/user/login/`, values);
      if (result && result.status === 200 && result.data.role !== "admin") {
        const { access, refresh, role, change_password } = result.data;
        setSession({
          token: access,
          refreshToken: refresh,
          userRole: role,
          changePassword: change_password,
        });

        // Handle "Remember Me"
        if (rememberMe) {
          Cookies.set("username", values.username, { expires: 7 });
        } else {
          Cookies.remove("username");
        }

        setLoading(false);
        if (role === "nurse" || role === "doctor") {
          navigate("/main/work_dashboard");
        } else if (role === "manager" || role === "clinic") {
          navigate("/main");
        } else {
          Notification.error("Unauthorized User");
          clearSession(false);
        }
      } else if (result.status === 401) {
        Notification.error(result.message || "Unauthorized");
        setLoading(false);
        form.resetFields();
      } else {
        Notification.error("Unexpected error occurred");
        setLoading(false);
      }
    } catch (error) {
      Notification.error("An unexpected error occurred");
      setLoading(false);
    }
  };

  useEffect(() => {
    const token = getSession("token");
    const userRole = getSession("userRole");
    if (token) {
      if (userRole === "nurse" || userRole === "doctor") {
        navigate("/main/work_dashboard");
      } else if (userRole === "manager" || userRole === "clinic") {
        navigate("/main");
      } else {
        navigate("/");
      }
    }
  }, [navigate]);

  const handleRememberMeChange = (e) => {
    setRememberMe(e.target.checked);
  };

  return (
    !getSession("token") ? (
      <div className="flex flex-col lg:flex-row h-screen">
        {/* Left Side */}
        <div
          className="lg:w-1/2 flex items-center justify-center xs:hidden md:block"
          style={{
            backgroundImage: appLogoData?.login_image
              ? `url(${appLogoData?.login_image})`
              : "none",
            backgroundColor: !appLogoData?.login_image ? "white" : "initial",
            backgroundSize: appLogoData?.login_image ? "cover" : "initial",
            backgroundPosition: appLogoData?.login_image ? "center" : "initial",
          }}
        ></div>

        {/* Right Side */}
        <div className="lg:w-1/2 flex items-center justify-center">
          <Form
            layout="vertical"
            className="w-11/12 lg:w-1/2"
            onFinish={onFinish}
            form={form}
          >
            <div className="flex justify-center mb-20">
              <img
                src={appLogoData?.icon || logo}
                alt="Logo"
                className="w-[500px]"
              />
            </div>
            <h1 className="text-3xl font-extrabold mb-3 text-[#3484F0]">
              Log In
            </h1>
            <p className="mb-3 text-base font-normal">
              Welcome back! Please login to your account.
            </p>
            <Form.Item
              label="Username"
              className="custom-label"
              name="username"
              rules={[{ required: true, message: "Please enter your username!" }]}
            >
              <Input placeholder="Enter username" size="large" />
            </Form.Item>
            <Form.Item
              label="Password"
              name="password"
              rules={[{ required: true, message: "Please enter your password!" }]}
            >
              <Input.Password placeholder="Enter Password" size="large" />
            </Form.Item>

            <div className="flex justify-between mt-[-1rem] mb-2">
              <Form.Item name="remember" valuePropName="checked" noStyle>
                <Checkbox checked={rememberMe} onChange={handleRememberMeChange}>
                  Remember me
                </Checkbox>
              </Form.Item>
              <Link to="/forgetpassword" className="text-[#3484F0]">
                Forgot Password
              </Link>
            </div>

            <Button
              size="large"
              className="bg-[#3484F0] text-[#fff] w-full mb-2"
              type="primary"
              htmlType="submit"
              loading={loading}
            >
              {loading ? "Loading" : "Login"}
            </Button>
            <div className="flex justify-center">
              <p className="text-sm">
                View
                <span className="text-blue-500 ml-1">
                  <a href="https://www.americanemr.com/terms--conditions.html">
                    terms and conditions
                  </a>
                </span>{" "}
                and
                <span className="text-blue-500 ml-1">
                  <a href="https://www.americanemr.com/privacy-policy.html">
                    privacy policy
                  </a>
                </span>
              </p>
            </div>

            <div className="flex justify-center gap-4 mt-10">
              <a
                href="https://www.linkedin.com/company/americanemr"
                target="_blank"
                rel="noopener noreferrer"
              >
                <LinkedinOutlined className="social-icons" />
              </a>
              <a
                href="https://www.facebook.com/AmericanEMR"
                target="_blank"
                rel="noopener noreferrer"
              >
                <FacebookOutlined className="social-icons" />
              </a>
              <a
                href="https://www.youtube.com/@AmericanEMR"
                target="_blank"
                rel="noopener noreferrer"
              >
                <YoutubeOutlined className="social-icons" />
              </a>
            </div>
          </Form>
        </div>
      </div>
    ) : null
  );
};

export default Login;
