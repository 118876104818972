import React from "react";
import LabelDisplay from "../../../../../../components/LabelDisplay/LabelDisplay";
import FormatDate from "../../../../../../components/FormatDate/FormatDate";
import { Typography } from "antd";

const SuperbillHeader = ({patientInfo, authNumber}) => {
  return (
    <div>
      <Typography.Text className="text-center font-semibold">
        Patient Demographics
      </Typography.Text>
      <div className="flex justify-between">
      <LabelDisplay title="Full Name" description={patientInfo?.first_name}/>
      <LabelDisplay title="Gender" description={patientInfo?.gender}/>
      <LabelDisplay title={"DOB"} description={<FormatDate date={patientInfo?.date_of_birth}/>}/>
      </div>
       <LabelDisplay title="Full Address" description=        {`${patientInfo?.current_address_line_1 || ""} ${
          patientInfo?.current_address_line_2
            ? `, ${patientInfo?.current_address_line_2}`
            : ""
        } ${
          patientInfo?.current_city ? `, ${patientInfo?.current_city}` : ""
        } ${
          patientInfo?.current_state
            ? `, ${patientInfo?.current_state}`
            : ""
        } ${
          patientInfo?.current_country
            ? `, ${patientInfo?.current_country}`
            : ""
        }`}/>
      <div className="flex justify-between">
        <LabelDisplay title="Phone" description={patientInfo?.patient_phone}/>
        <LabelDisplay title="Prior Authorization Number" description={authNumber}/>
      </div>
    </div>
  );
};

export default SuperbillHeader;
