import React, { useEffect, useState } from "react";
import { Form, Input, Button, Row, Col } from "antd";
import { doPatch } from "../../../../../../API/apis";
import { useStateGlobalContext } from "../../../../../../contexts/GlobalContext";
import { useParams } from "react-router-dom";
import Loader from "../../../../../../components/Loader/Loader";
import Notification from "../../../../../../components/Notification/Notification";

const IdentficationCard = () => {
  const { id } = useParams();
  const [form] = Form.useForm();
  const [edit, setEdit] = useState(false);
  const { patientBasicInfo, getPatientData, loading } = useStateGlobalContext();

  const { govId, id_notes, license, passport, ssn } = patientBasicInfo || {}

  useEffect(() => {
    const fetchData = async () => {
      await getPatientData(id);
      if (patientBasicInfo) {
        const { govId, id_notes, license, passport, ssn } =
          patientBasicInfo 

        form.setFieldsValue({
          govId,
          id_notes,
          license,
          passport,
          ssn,
        });
      }
    };

    fetchData();
  }, [id]);

  const onEdit = () => {
    setEdit(true);
  };

  const onEditCancel = () => {
    setEdit(false);
  };

  const handleUpdate = async (values)=>{
    try {
      const response = await doPatch(`/patient/update-info/${id}/`, values);
      if (response.status === 200) {
        Notification.success("Patient Profile is Updated Successfully");
        await getPatientData(id); 
        onEditCancel()
      }
    } catch (error) {
      console.log(error);
    }
  }

  const renderFormItem = (label, name, inputComponent) => (
    <Col span={8} xs={24} md={12} lg={12}>
      <Form.Item label={label} name={name}>
        {inputComponent}
      </Form.Item>
    </Col>
  );

  if (loading) {
    return <Loader />;
  }

  return (
    <div className="wrapper shadow-class">
      <div className="flex justify-between">
        <h1 className="text-[1.125rem] font-bold mb-4">
          Identification Cards Information
        </h1>
        {edit ? (
          <Button onClick={onEditCancel}>Close</Button>
        ) : (
          <Button onClick={onEdit}>Edit</Button>
        )}
      </div>

      {edit ? (
        <Form layout="vertical" form={form} onFinish={handleUpdate}>
          <Row gutter={{ xs: 10, md: 15, xl: 30 }}>
            {renderFormItem(
              "Government ID",
              "govId",
              <Input placeholder="Government ID" />
            )}
            {renderFormItem(
              "Social Security Number",
              "ssn",
              <Input placeholder="Social Security Number" />
            )}
          </Row>

          <Row gutter={{ xs: 10, md: 15, xl: 30 }}>
            {renderFormItem(
              "Driver's License",
              "license",
              <Input placeholder="Driver's License" />
            )}
            {renderFormItem(
              "Passport Number",
              "passport",
              <Input placeholder="Passport Number" />
            )}
          </Row>

          <Form.Item label="Notes" name="id_notes">
            <Input.TextArea placeholder="Notes..." maxLength={300} showCount />
          </Form.Item>

          <div className="flex justify-end">
            <Button onClick={onEditCancel}>Cancel Changes</Button>
            <Button type="primary" className="ml-2" htmlType="submit">
              Save Changes
            </Button>
          </div>
        </Form>
      ) : (
        <div className="view-wrapper">
          <p>Government ID: {govId}</p>
          <p>Social Security Number: {ssn}</p>
          <p>Driver's License: {license}</p>
          <p>Passport Number: {passport}</p>
          <p className="notes">Notes: {id_notes}</p>
        </div>
      )}
    </div>
  );
};

export default IdentficationCard;
