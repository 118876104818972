// src/components/GlobalBreadcrumb/GlobalBreadcrumb.js
import React from "react";
import { Breadcrumb } from "antd";
import { Link } from "react-router-dom";

const GlobalBreadcrumb = ({ routes }) => {
  return (
    <Breadcrumb>
      {routes.map((route, index) => (
        <Breadcrumb.Item key={index}>
          {route.link ? (
            <Link to={route.link} className="font-semibold">
              {route.label}
            </Link>
          ) : (
            <span className="font-semibold">{route.label}</span>
          )}
        </Breadcrumb.Item>
      ))}
    </Breadcrumb>
  );
};

export default GlobalBreadcrumb;
