import React, { useState } from "react";
import { Table, Input, Button, Space } from "antd";
import { SearchOutlined } from "@ant-design/icons";
import Highlighter from "react-highlight-words";

// Updated GetColumnSearchProps function
export const GetColumnSearchProps = (dataIndex, customRender) => {
  const [searchText, setSearchText] = useState("");
  const [searchedColumn, setSearchedColumn] = useState("");

  return {
    filterDropdown: function FilterDropdown({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
    }) {
      const handleSearch = (selectedKeys, confirm, dataIndex) => {
        confirm();
        setSearchText(selectedKeys[0]);
        setSearchedColumn(dataIndex);
      };

      const handleReset = (clearFilters) => {
        clearFilters();
        setSearchText("");
      };

      return (
        <div style={{ padding: 8 }}>
          <Input
            placeholder={`Search ${dataIndex}`}
            value={selectedKeys[0]}
            onChange={(e) =>
              setSelectedKeys(e.target.value ? [e.target.value] : [])
            }
            onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
            style={{ width: 188, marginBottom: 8, display: "block" }}
          />
          <Space>
            <Button
              type="primary"
              onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
              icon={<SearchOutlined />}
              size="small"
              style={{ width: 90 }}
            >
              Search
            </Button>
            <Button
              onClick={() => handleReset(clearFilters)}
              size="small"
              style={{ width: 90 }}
            >
              Reset
            </Button>
          </Space>
        </div>
      );
    },
    filterIcon: (filtered) => (
      <SearchOutlined style={{ color: filtered ? "#1890ff" : undefined }} />
    ),
    onFilter: (value, record) =>
      record[dataIndex]
        ?.toString()
        .toLowerCase()
        .includes(value.toLowerCase()),
    onFilterDropdownVisibleChange: (visible) => {
      if (visible) {
        setTimeout(() => {
          const input = document.querySelector(
            `input.ant-input[placeholder="Search ${dataIndex}"]`
          );
          input && input.focus();
        }, 100);
      }
    },
    render: (text, record, index) => {
      const defaultRender = customRender
        ? customRender(text, record, index)
        : text;

      return searchedColumn === dataIndex ? (
        <Highlighter
          highlightStyle={{ backgroundColor: "#ffc069", padding: 0 }}
          searchWords={[searchText]}
          autoEscape
          textToHighlight={text ? text.toString() : ""}
        />
      ) : (
        defaultRender
      );
    },
  };
};

const CustomTable = ({
  columns,
  dataSource,
  rowKey,
  onPageChange,
  loading,
  indexColWidth = "5%",
  scroll,
  pagination = true,
  showIndex = true,
  rowSelection
}) => {
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);

  console.log(rowSelection, "ROW SELECTIOn")

  // Enhance columns with search and sorting functionality
  const enhancedColumns = columns.map((col) => ({
    ...col,
    ...(col.searchable ? GetColumnSearchProps(col.dataIndex, col.render) : {}),
    sorter: col.sortable
      ? (a, b) => (a[col.dataIndex] > b[col.dataIndex] ? 1 : -1)
      : undefined,
  }));

  const columnsWithIndex = showIndex
    ? [
        {
          title: "#",
          dataIndex: "serial_number",
          width: indexColWidth,
          key: "serial_number",
          render: (_, record, index) =>
            (currentPage - 1) * pageSize + index + 1,
          align: "center",
        },
        ...enhancedColumns,
      ]
    : enhancedColumns;

  // Handle table change (pagination, filters, sorter)
  const handleTableChange = (pagination, filters, sorter) => {
    setCurrentPage(pagination.current); // Update the current page
    setPageSize(pagination.pageSize); // Update the page size
    if (onPageChange) {
      onPageChange(pagination.current); // Pass the current page to the parent
    }
  };

  // Calculate the row index based on current page and page size
  const dataSourceWithIndex = dataSource?.map((item, index) => ({
    ...item,
    rowIndex: (currentPage - 1) * pageSize + index + 1,
  }));

  return (
    <Table
      columns={columnsWithIndex}
      dataSource={dataSourceWithIndex}
      rowSelection={rowSelection}
      rowKey={rowKey}
      loading={loading}
      pagination={
        pagination
          ? {
              defaultPageSize: 10,
              showSizeChanger: true,
              total: dataSource?.length,
              showTotal: (total, range) =>
                `${range[0]}-${range[1]} of ${total} items`,
              current: currentPage,
            }
          : false
      }
      onChange={handleTableChange}
      size="small"
      scroll={scroll}
    />
  );
};

export default CustomTable;
