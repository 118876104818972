import React, { useState, useEffect } from "react";
import {  Row } from "antd";
import InventoryCards from "./component/InventoryCards";
import { doGet } from "../../../API/apis";
import OutOfStock from "./component/OutOfStock";
import Expired from "./component/Expired";
import GuestCheckout from "./component/GuestCheckout";
import { useStateGlobalContext } from "../../../contexts/GlobalContext";
import Notification from "../../../components/Notification/Notification";

function InventoryDashboard() {
  const [loading, setLoading] = useState(false);
  const [lowInStockCount, setLowInStockCount] = useState(null);
  const [expiringSoonCount, setExpiringSoonCount] = useState(null);
  const [soldItemCount, setSoldItemCount] = useState(null);
  // const [totalInvRevenue, setTotalInvRevenue] = useState(null);
  const { profile } = useStateGlobalContext();
  const role = profile?.user_info[0]?.role;

  const fetchData = async (endpoint, setDataCallback) => {
    setLoading(true);
    try {
      const response = await doGet(endpoint);
      if (response.status === 200) {
        setDataCallback(response.data);
      }
    } catch (error) {
      Notification.error("Something Went Wrong");
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchData("/inventory/low-stock/", setLowInStockCount);
    fetchData("/inventory/expiring-soon/", setExpiringSoonCount);
    fetchData("/inventory/get-month-sold-items/", setSoldItemCount);
    // fetchData("/inventory/total-revenue/", (data) => setTotalInvRevenue(data["total revenue"]));
  }, []);

  return (
    <>
      <InventoryCards
        expiringSoon={expiringSoonCount}
        loading={loading}
        lowInStock={lowInStockCount?.count}
        soldItemCount={soldItemCount?.total_quantity}
        // totalInvRevenue={totalInvRevenue}
        role={role}
      />
      <Row gutter={[16,16]}>
      {/* {role !== 'nurse' && role !== 'doctor' && <InventoryBestSeller />} */}
      <GuestCheckout role={role}/>
      </Row>
      <Row gutter={[16, 16]}>
        <OutOfStock />
        <Expired />
      </Row>
    </>
  );
}

export default InventoryDashboard;
