import React, { useState, useEffect } from "react";
import {   Button, Card, Tooltip } from "antd";
import { doGet } from "../../../../API/apis";
import { formatDate, formatTime } from "../../../../utils/DateFormat";
import { CSVLink } from "react-csv";
import { useStateGlobalContext } from "../../../../contexts/GlobalContext";
import { NumberFormatter } from "../../../../utils/validator";
import Notification from "../../../../components/Notification/Notification";
import FormatDateTime from "../../../../components/FormatDate/FormatDateTime";
import CustomTable from "../../../../components/CustomTable/CustomTable";
import DateRangePickerCard from "../../../../components/GlobalComponents/DateRangePickerCard";


const CreditMemoReport = () => {
  const [data, setData] = useState([]);
  const { profile } = useStateGlobalContext();
  const [loading, setLoading] = useState(false);
  const [searchClicked, setSearchClicked] = useState(false);
  const [selectedDateRange, setSelectedDateRange] = useState(null);

  const transformedData = (records) => {
    if (!records || !Array.isArray(records)) {
      return [];
    }

    return records?.map((record) => ({
      // ID: record?.id,
      "First Name": record?.patient_first_name,
      "Middle Name": record?.patient_middle_name,
      "Last Name": record?.patient_last_name,
      "Invoice ID": record.invoice_id,
      "Date Added": record?.date_time && formatDate(
        record?.date_time,
      ),
      "Time Added": record?.date_time && formatTime(
        record?.date_time,
      ),
      Description: record?.description,
      Amount: record?.credit_amount,
      "Added By": record?.added_by,
      "Redeemed By": record?.redeemed_by,
      "Paid Date":
        record?.redeemed_by_datetime &&
        formatDate(
          record?.redeemed_by_datetime,
        ),
      "Paid Time":
        record?.redeemed_by_datetime &&
        formatTime(
          record?.redeemed_by_datetime,
        ),
    }));
  };

  const role = profile && profile?.user_info[0]?.role;

  const coloumn = [
    {
      key: 1,
      title: "Invoice #",
      align: "center",
      dataIndex: "invoice_id",
    },
    {
      key: 2,
      title: "Patient Name",
      align: "center",
      render: (_, record) => (
        <span>
          {record?.patient_first_name}{" "}
          {record?.patient_middle_name ? record?.patient_middle_name : " "}{" "}
          {record?.patient_last_name}
        </span>
      ),
    },
    {
      key: 3,
      title: "Date",
      align: "center",
      dataIndex: "date_time",
      render: (text) => (
        <Tooltip title={text ? <FormatDateTime date={text} type="time" /> : ""}>
          <span>{text ? <FormatDateTime date={text} type="date" /> : ""}</span>
        </Tooltip>
      ),
    },
    {
      key: 4,
      align: "center",
      title: "Amount",
      dataIndex: "credit_amount",
      render: (text) => (
        <NumberFormatter value={text} options={{ style: "decimal" }} />
      ),
    },
    {
      key: 5,
      title: "Status",
      align: "center",
      dataIndex: "status",
      render: (text) => <span>{text.length === 0 ? "Unpaid" : text}</span>,
    },
  ];

  const handleDateChange = (dates) => {
    setSelectedDateRange(dates);
  };

  const handleReset = ()=>{
    setData([])
  }

  const handleSearch = () => {
    if (selectedDateRange && selectedDateRange.length === 2) {
      setSearchClicked(true);
      getCreditMemoReports();
    } else {
      Notification.warning("Please select a date range");
    }
  };

  const getCreditMemoReports = async () => {
    setLoading(true);
    try {
      if (selectedDateRange && selectedDateRange.length === 2) {
        const startDate = selectedDateRange[0].format("YYYY-MM-DD");
        const endDate = selectedDateRange[1].format("YYYY-MM-DD");

        const response = await doGet(
          `/reports/credit-memo/?start_date=${startDate}&end_date=${endDate}`
        );
        if (response.status === 200) {
          setData(response.data);
        }
      } else {
        Notification.warning("Please select a valid date range");
      }
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (searchClicked) {
      getCreditMemoReports();
      setSearchClicked(false);
    }
  }, [searchClicked, selectedDateRange]);

  return (
    <div>
      <h1 className="font-bold text-lg mb-2">Credit Memo Report </h1>
      <DateRangePickerCard
        onDateChange={handleDateChange}
        onSearch={handleSearch}
        onReset={handleReset}
      />

      <Card className="shadow-class">
        <div className="flex justify-between items-center mb-2">
          <h1>Search Results</h1>
          {role !== "nurse" && role !== "doctor" && data?.length > 0 && (
            <Button type="primary">
              <CSVLink
                data={transformedData(data)}
                filename={"Credit_memo_report.csv"}
                className="csv-link"
              >
                Download Report
              </CSVLink>
            </Button>
          )}
        </div>
        <CustomTable
          loading={loading}
          columns={coloumn}
          dataSource={data}
          scroll={{
            x: 1500,
          }}
          key="creditmemo_report"
        />
      </Card>
    </div>
  );
};

export default CreditMemoReport;
