import { Button, Modal } from "antd";
import LabelDisplay from "../../../../components/LabelDisplay/LabelDisplay";
import FormatDateTime from "../../../../components/FormatDate/FormatDateTime";

const ViewTask = ({ open, onCancel, data }) => {
  return (
    <Modal
      open={open}
      onCancel={onCancel}
      title="Task Details"
      footer={null}
      closeIcon={
        <Button size="small" className="app-close-icon" shape="round">
          Close
        </Button>
      }
    >
      <LabelDisplay title={"Assigned By"} description={data?.assigned_by} />
      <LabelDisplay title={"Details"} description={data?.detail} />
      <LabelDisplay title={"Assigned to"} description={data?.assigned_to} />
      <LabelDisplay title={"Status"} description={data?.status} />
      <LabelDisplay
        title={"Date"}
        description={
          <FormatDateTime date={data?.entered_date} type="datetime" />
        }
      />
      <LabelDisplay
        title={"Status Updated on"}
        description={
          <FormatDateTime date={data?.status_updated_on} type="datetime" />
        }
      />
      <LabelDisplay title={"Signed By"} description={data?.signed_by} />
    </Modal>
  );
};

export default ViewTask;
