import { Card, Button } from "antd";
import { useReactToPrint } from "react-to-print";
import PrintDrawer from "../../../../../../../../components/PrintDrawer/PrintDrawer";
import FaceSheetHeader from "../../../../../../../../components/FaceSheet/FaceSheetHeader";
import SingleInvoiceComponent from "./SingleInvoiceComponent";
import { useRef } from "react";

const SelectedInvoicesPrint = ({ visible, onClose, data }) => {
  const contentRef = useRef(null);

  const handlePrint = useReactToPrint({
    content: () => contentRef.current,
  });

  return (
    <PrintDrawer visible={visible} onClose={onClose}>
      <div className="prescription-pres-print max-w-[900px]">
        <div className="prescription-container">
          <Button type="primary" onClick={handlePrint}>
            Print Document
          </Button>
          <div className="invoice-content" ref={contentRef}>
            <FaceSheetHeader />
            {data?.map((rxData, index) => (
              <Card className="flex flex-col mb-3 shadow-md">
                <SingleInvoiceComponent rxData={rxData} />
              </Card>
            ))}
          </div>
        </div>
      </div>
    </PrintDrawer>
  );
};

export default SelectedInvoicesPrint;
