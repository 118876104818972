import React, { useState, useEffect, useCallback } from "react";
import {
  Drawer,
  Input,
  Button,
  Form,
  Table,
  Row,
  Col,
  AutoComplete,
  Switch,
  Select,
} from "antd";
import { doPatch, doPost } from "../../API/apis";
import dayjs from "dayjs";
import {
  discountCalulator,
  NumberFormatter,
  validateQuantity,
} from "../../utils/validator";
import { DeleteOutlined, EditOutlined } from "@ant-design/icons";
import { useStateGlobalContext } from "../../contexts/GlobalContext";
import Notification from "../Notification/Notification";
import showConfirmModal from "../ModalConfirm/ModalConfirm";
import InvoiceFooter from "../../pages/Dashboard/PatientSummary/InvoiceAndBilling/Invoices/component/InvoiceFooter/InvoiceFooter";
import { useSearchProduct } from "../../API/apiUtils";
import { RadioGroup } from "../GlobalComponents/GlobalComponents";

const { Option } = Select;

const MySelect = ({ data, onChange }) => {
  return (
    <Select
      defaultValue="Select a discount"
      style={{ width: 200 }}
      onChange={onChange}
    >
      {Object.keys(data).map((key) => (
        <Option key={key} value={data[key].value}>
          <p>{data[key].name} </p>
          <p>{data[key].value}% </p>
        </Option>
      ))}
    </Select>
  );
};

const GuestCheckoutDrawer = ({ visible, onClose }) => {
  const [form] = Form.useForm();
  const [edit, setEdit] = useState(false);
  const [saving, setSaving] = useState(false);
  const [discount, setDiscount] = useState(0);
  const [productList, setProductList] = useState([]);
  const [editingItemKey, setEditingItemKey] = useState(null);
  const [selectedProduct, setSelectedProduct] = useState([]);
  const [discountApplied, setDiscountApplied] = useState(false);
  const { officeSetting } = useStateGlobalContext();
  const [taxApplied, setTaxApplied] = useState(officeSetting?.apply_tax);
  const [totalPriceBeforeTax, setTotalPriceBeforeTax] = useState(0);
  const [totalPriceAfterTax, setTotalPriceAfterTax] = useState(0);
  const { getGuestInvoices } = useStateGlobalContext();
  const currentDate = dayjs().format("MM/DD/YYYY");
  const { options, handleSearchProduct } = useSearchProduct();

  const tax = officeSetting && officeSetting?.local_tax_rate;
  const taxRate = tax / 100;

  const columns = [
    {
      title: "Date",
      dataIndex: "date",
      key: "date",
      render: () => new Date().toLocaleDateString(),
    },
    {
      title: "Items",
      dataIndex: "items",
      key: "items",
      width: "40%",
      ellipsis: true,
    },
    {
      title: "Unit Price",
      dataIndex: "unit_price",
      key: "unit_price",
      ellipsis: true,
      render: (price) => <NumberFormatter value={price} />,
    },
    {
      title: "Quantity",
      dataIndex: "quantity",
      key: "quantity",
      ellipsis: true,
      align: "center ",
    },

    {
      title: "Price",
      dataIndex: "price",
      key: "price",
      ellipsis: true,
      render: (price) => <NumberFormatter value={price} />,
    },
    {
      title: "Action",
      width: "8%",
      align: "center",
      render: (text, record) => (
        <span>
          <EditOutlined
            className="mr-2 text-[#1890ff]"
            onClick={() => handleEdit(record)}
          />
          <DeleteOutlined
            className="text-red-500"
            onClick={() => handleDelete(record)}
          />
        </span>
      ),
    },
  ];

  const handleCancel = () => {
    setTimeout(() => {
      onClose();
      form.resetFields();
      setProductList([]);
      setDiscountApplied(false);
      setTaxApplied(0);
      setDiscount(0);
    }, 200);
  };

  const onSelect = (value,) => {
    const selectedMed = options.find((item) => item.value === value);
    if (selectedMed) {
      setSelectedProduct({
        date: new Date(),
        id: selectedMed?.id,
        items: selectedMed?.value,
        price: parseFloat(selectedMed?.price).toFixed(2),
        quantity: selectedMed?.quantity,
      });
    }
  };

  const handleDiscountChange = (checked) => {
    if (!checked) {
      setDiscount(0);
    }
    setDiscountApplied(checked);
  };

  const handleDiscount = (value) => {
    setDiscount(parseFloat(value));
  };

  const handleTaxChange = (checked) => {
    setTaxApplied(checked);
  };

  const calcTax = (invoice) => {
    const local_tax =
      taxApplied && invoice ? invoice * (1 - discount / 100) * taxRate : 0;
    return local_tax.toFixed(2);
  };

  useEffect(() => {
    let subtotal = 0.0;
    productList.forEach((product) => {
      subtotal += product.price;
    });

    const appliedDiscount = discountApplied
      ? discountCalulator(discount, subtotal)
      : 0;
    const appliedTax = taxApplied ? calcTax(subtotal) : 0;
    const total = subtotal - appliedDiscount;
    const totalPrice = total + Number(appliedTax);
    setTotalPriceBeforeTax(subtotal);
    setTotalPriceAfterTax(totalPrice);
  }, [productList, discount, taxApplied]);

  const handleProduct = (values) => {
    if (selectedProduct?.quantity < values.quantity) {
      return Notification.error(
        "Quantity should not be greater than available stock!"
      );
    }
    const subtotal = selectedProduct?.price * values.quantity;
    const newProductList = {
      id: selectedProduct.id,
      items: selectedProduct.items,
      unit_price: parseFloat(selectedProduct?.price).toFixed(2),
      quantity: values.quantity,
      price: subtotal,
      instruction: values.instructions,
      key: Date.now(),
    };
    setProductList([...productList, newProductList]);
    form.setFieldsValue({
      product_name: undefined,
      quantity: undefined,
    });
  };

  const handleEdit = (item) => {
    form.setFieldsValue({
      product_name: item?.items,
      quantity: item?.quantity,
    });
    setEdit(true);
    setEditingItemKey(item.key);
  };

  const handleUpdate = (values) => {
    const updatedProductList = productList.map((item) =>
      item.key === editingItemKey
        ? {
            ...item,
            ...values,
            items: selectedProduct?.items,
            unit_price: selectedProduct?.price,
            price: selectedProduct?.price * values?.quantity,
          }
        : item
    );
    setProductList(updatedProductList);
    form.setFieldsValue({
      product_name: undefined, // Reset the search product field
      quantity: undefined, // Reset the quantity field
    });
    setEdit(false);
    setEditingItemKey(null);
  };

  const handleDelete = (item) => {
    showConfirmModal({
      title: "Confirm Delete?",
      icon: null,
      content: "Are you sure you want to delete this?",
      okText: "Delete",
      okType: "danger",
      cancelText: "Cancel",
      className: "custom-modal",
      onOk() {
        const updatedProductList = productList.filter(
          (product) => product.key !== item.key
        );
        setProductList(updatedProductList);
      },
    });
  };

  const handleGuestCheckout = async () => {
    setSaving(true);
    if (!form.getFieldValue("payment_method")) {
      Notification.error("Please select a payment method.");
      setSaving(false);
      return;
    }
    const subtotal = totalPriceBeforeTax;
    const discountValue = discountApplied
      ? discountCalulator(discount, subtotal)
      : 0;

    const data = {
      patient_name: form.getFieldValue("customer_name"),
      product_details: productList,
      price: totalPriceBeforeTax,
      discount: discount,
      discounted_amount: discountValue,
      tax: taxApplied ? calcTax(totalPriceAfterTax) : 0,
      grand_total: totalPriceAfterTax,
      notes: form.getFieldValue("notes"),
      payment_method: form.getFieldValue("payment_method"),
    };
    try {
      const response = await doPost(`/inventory/add-guest/`, data);
      if (response.status === 201) {
        await updateProductQuantities(productList);
        setSaving(false);
        Notification.success("Guest Invoice Created Successfully");
        getGuestInvoices();
        handleCancel();
      }
    } catch (error) {
      Notification.error("Something went Wrong");
    } finally {
      setSaving(false);
    }
  };

  const updateProductQuantities = async (productList) => {
    try {
      const updateRequests = productList.map((product) => {
        const ids = product.id;
        const quantities = product.quantity.toString();
        return doPatch(`/inventory/update-guest/${ids}/?q=sell&guest=true`, {
          quantity: [quantities],
        });
      });

      await Promise.all(updateRequests);
    } catch (error) {
      console.error("Error updating product quantities:", error);
    }
  };

  return (
    <>
      <Drawer
        title="Guest Checkout"
        placement="right"
        closable={true}
        onClose={handleCancel}
        open={visible}
        width={900}
        closeIcon={
          <Button size="small" className="drawer-close-icon" shape="round">
            Close
          </Button>
        }
      >
        <Form
          layout="vertical"
          form={form}
          onFinish={edit ? handleUpdate : handleProduct}
        >
          <p>Date: {currentDate}</p>
          <Row gutter={8}>
            <Col span={12}>
              <Form.Item name="customer_name" label="Customer Name">
                <Input placeholder="Customer Name" />
              </Form.Item>
            </Col>
          </Row>

          <Row gutter={8} className="flex items-center">
            <Col span={12}>
              <Form.Item
                label="Search Product"
                name="product_name"
                tooltip="Brand Name , Generic Name, Quantity , Price"
                rules={[
                  {
                    required: true,
                    message: "Please Enter Product Name First",
                  },
                ]}
              >
                <AutoComplete
                  options={options}
                  allowClear={true}
                  onSelect={onSelect}
                  onSearch={handleSearchProduct}
                  placeholder="Search Products"
                />
              </Form.Item>
            </Col>
            <Col span={10}>
              <Form.Item
                label="Quantity"
                name="quantity"
                rules={[{ validator: validateQuantity }]}
              >
                <Input placeholder="Quantity" />
              </Form.Item>
            </Col>
            <Col span={2}>
              <Button type="primary" className="mt-1" htmlType="submit">
                {edit ? "Update" : "Add"}
              </Button>
            </Col>
          </Row>
          <Table
            columns={columns}
            dataSource={productList}
            pagination={false}
            size="small"
          />

          <Form.Item label="Apply Discount" name="discount">
            <Switch onChange={handleDiscountChange} />
          </Form.Item>

          {discountApplied && (
            <Form.Item label="Discounts">
              <MySelect
                data={officeSetting?.discounts}
                onChange={handleDiscount}
              />
            </Form.Item>
          )}

          <Form.Item label="Apply Tax" name="tax">
            <Switch onChange={handleTaxChange} defaultChecked={taxApplied} />
          </Form.Item>
          <div className="flex justify-end mt-2">
            <div></div>
            <InvoiceFooter
              subTotal={totalPriceBeforeTax}
              discount={discount}
              discountAmount={discountCalulator(discount, totalPriceBeforeTax)}
              tax={calcTax(totalPriceBeforeTax)}
              grandTotal={totalPriceAfterTax}
            />
          </div>
          <Form.Item
            label="Select Payment Collection Method"
            name="payment_method"
          >
            <RadioGroup />
          </Form.Item>

          <Form.Item label="Notes" name="notes">
            <Input.TextArea placeholder="Free Text" showCount maxLength={300} />
          </Form.Item>

          <div className="flex justify-end">
            <Button className="mr-2" onClick={handleCancel}>
              Cancel
            </Button>
            <Button
              type={productList.length === 0 ? "default" : "primary"}
              onClick={handleGuestCheckout}
              disabled={productList.length === 0}
              loading={saving}
            >
              Buy Products
            </Button>
          </div>
        </Form>
      </Drawer>
    </>
  );
};

export default GuestCheckoutDrawer;
