import React, { useState, useEffect } from "react";
import { Col,  Card } from "antd";
import { doGet } from "../../../../API/apis";
import Notification from "../../../../components/Notification/Notification";
import FormatDate from "../../../../components/FormatDate/FormatDate";
import CustomTable from "../../../../components/CustomTable/CustomTable";



const Expired = () => {
  const [expired, setExpired] = useState(null);
  const [loading, setLoading] = useState(false);

  const coloumn1 = [
    {
      title: "Brand Name",
      dataIndex: "medicine_name",
      width: "10%",
      align: "center",
      ellipsis: true,
    },

    {
      title: "Category",
      dataIndex: "category",
      width: "10%",
      align: "center",
      ellipsis: true,
    },
    {
      title: "Location",
      dataIndex: "location",
      width: "10%",
      align: "center",
      ellipsis: true,
    },

    {
      title: "Expiry Date",
      dataIndex: "expiry_date",
      width: "10%",
      align: "center",
      ellipsis: true,
      render : (text)=> <FormatDate date={text}/>
    },
  ];

  const getExpiredProducts = async () => {
    setLoading(true);
    try {
      const response = await doGet(`/inventory/expired-items/`);
      if (response.status === 200) {
        setExpired(response?.data?.expired_items);
      }
    } catch (error) {
      Notification.error("Something Went Wrong");
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    getExpiredProducts();
  }, []);
  return (
    <Col xl={12}>
      <Card>
        <h1 className="mb-2">Expired</h1>
        <CustomTable
          columns={coloumn1}
          dataSource={expired}
          loading={loading}
          size="small"
          pagination={false}
          scroll={{
            y: 200,
          }}
        />
      </Card>
    </Col>
  );
};

export default Expired;
