import React, { useEffect, useMemo, useState } from "react";
import { useParams } from "react-router-dom";
import { doPost } from "../../../../API/apis";
import { Modal, Form, Button, Select, Row, Col } from "antd";
import { useStateGlobalContext } from "../../../../contexts/GlobalContext";
import Notification from "../../../../components/Notification/Notification";
import SelectProvider from "../../../../components/SelectProvider/SelectProvider";
import FormatDateTime from "../../../../components/FormatDate/FormatDateTime";

const { Option } = Select;

const AddToWatingRoom = ({ visible, onClose, fullName, date_of_birth }) => {
  const { id } = useParams();
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);
  const { invoices, getInvoices, officeSetting } = useStateGlobalContext();
  const [selectedInvoice, setSelectedInvoice] = useState(null);

  const rooms = useMemo(() => officeSetting?.exam_rooms, [officeSetting]);

  useEffect(() => {
    const fetchData = async () => {
      await getInvoices(id);
    };

    fetchData();
  }, [id]);

  const handleInvoiceSelect = (value) => {
    const selectedInvoiceData = invoices?.find(
      (invoice) => invoice.invoice_id === value
    );
    setSelectedInvoice({
      service_name: selectedInvoiceData?.service_name,
      visit_reason: selectedInvoiceData?.visit_reason,
    });
  };

  const handleWaitingList = async (values) => {
    setLoading(true);
    if (selectedInvoice) {
      const data = {
        patient_id: id,
        patient: fullName,
        dob: date_of_birth,
        service_name: selectedInvoice?.service_name,
        reason_of_visit: selectedInvoice?.visit_reason,
        provider: values?.provider,
        room: values?.room,
      };
      try {
        const response = await doPost("/waiting-room/send/", data);
        if (response.status === 201) {
          Notification.success("Patient Added to waiting Room successfully");
          handleCancel();
        }
      } catch (error) {
        console.log("Error", error);
      } finally {
        setLoading(false);
      }
    }
  };

  const handleCancel = () => {
    form.resetFields();
    onClose();
  };

  return (
    <Modal
      title="Add To Waiting Room"
      open={visible}
      onCancel={handleCancel}
      closeIcon={
        <Button
          size="small"
          className="mr-10 bg-gray-400 text-white app-close-icon"
          shape="round"
        >
          Close
        </Button>
      }
      footer={null}
    >
      <Form layout="vertical" form={form} onFinish={handleWaitingList}>
        <Form.Item
          label="Invoice ID"
          name="invoice_id"
          tooltip="Invoice ID, Issue Date, Service Name, Reason For Visit"
          rules={[{ required: true, message: "Please Select Invoice ID" }]}
        >
          <Select
            placeholder="Select Invoice ID"
            value={selectedInvoice}
            onChange={handleInvoiceSelect}
            showSearch
            optionFilterProp="children"
            filterOption={(input, option) => {
              const { invoice_id, service_name, visit_reason, issue_date } =
                option?.props?.data || {};
              return (
                invoice_id
                  ?.toString()
                  ?.toLowerCase()
                  .includes(input.toLowerCase()) ||
                service_name?.toLowerCase().includes(input.toLowerCase()) ||
                visit_reason?.toLowerCase().includes(input.toLowerCase()) ||
                issue_date?.toLowerCase().includes(input.toLowerCase())
              );
            }}
          >
            {invoices &&
              invoices
                .filter(
                  (invoice) =>
                    invoice?.status !== "Cancelled" &&
                    invoice?.visit_reason !== ""
                )
                .map((invoice, index) => (
                  <Select.Option
                    key={index}
                    value={invoice?.invoice_id}
                    data={invoice}
                  >
                    <div>
                      <p>{invoice?.invoice_id}</p>
                      <p>
                        <span className="font-semibold">Issue Date: </span>
                        <FormatDateTime
                          date={invoice?.issue_date}
                          type="datetime"
                        />
                      </p>
                      <p>
                        <span className="font-semibold">Service Name: </span>
                        {invoice?.service_name}
                      </p>
                      <p>
                        <span className="font-semibold">
                          Reason For Visit:{" "}
                        </span>
                        {invoice?.visit_reason}
                      </p>
                      <p>
                        <span className="font-semibold">Payment Status: </span>
                        {invoice?.status}
                      </p>
                    </div>
                  </Select.Option>
                ))}
          </Select>
        </Form.Item>
        <Row gutter={[16, 16]}>
          <Col span={12}>
            <Form.Item label="Provider" name={"provider"}>
              <SelectProvider waitingRoom={true} />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item label="Room" name={"room"}>
              <Select placeholder="Select Room">
                {Object.keys(rooms).map((room) => (
                  <Option key={room} value={room}>
                    {rooms[room]}
                  </Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
        </Row>
        <div className="flex justify-end">
          <Button onClick={handleCancel} className="mr-2">
            Cancel
          </Button>
          <Button type="primary" htmlType="submit" loading={loading}>
            Add To Waiting Room
          </Button>
        </div>
      </Form>
    </Modal>
  );
};

export default AddToWatingRoom;
