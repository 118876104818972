import { Button, Form } from "antd";
import React, { useEffect, useState } from "react";
import { useStateGlobalContext } from "../../../../contexts/GlobalContext";
import { doPatch, doPost } from "../../../../API/apis";
import Notification from "../../../../components/Notification/Notification";
import BasicButton from "../../../../components/BasicButton/BasicButton"; 
import FileUpload from "../../../../components/GlobalComponents/FileUpload";


const OfficeLogo = () => {
  const [form] = Form.useForm()
  const [edit, setEdit] = useState(false)
  const [officeLogo, setOfficeLogo] = useState(null);
  const { officeSetting, getOfficeSettings,submitLoading,
    setSubmitLoading  } = useStateGlobalContext();


  const handleCancel = () => {
    getOfficeSettings();
  };

  useEffect(()=>{
    officeSetting?.clinic ? setEdit(true): setEdit(false)
  },[])



  const convertToBase64 = (file) => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result);
      reader.onerror = (error) => reject(error);
    });
  };

  const handleOfficeLogo = async () => {

    if (!officeLogo) {
      Notification.error("Logo is required.");
      return;
    }

    let user_office_logo_base64 = null;
    if (officeLogo) {
      user_office_logo_base64 = await convertToBase64(officeLogo);
    }
    const data = {
      user_office_logo: user_office_logo_base64,
    };
    setSubmitLoading(true)
    try {
      const edit = officeSetting && officeSetting?.clinic;
      const endpoint = edit
        ? `/user/office-settings/update/?q=basic/&update=true`
        : `/user/office-settings/?q=basic/`;
      const response = await (edit ? doPatch : doPost)(
        endpoint,
        data
      );
      if (response.status === 200 || response.status === 201) {
        Notification.success("Office Logo Updated Successfully");
        getOfficeSettings();
      } else {
        Notification.error("Something went wrong error");
      }
    } catch (error) {
      Notification.warning("Something went wrong", error);
    } finally{
      setSubmitLoading(false)
    }
  };

  useEffect(() => {
    getOfficeSettings();
  }, []);

  return (
    <div>
      <Form layout="vertical" form={form} onFinish={handleOfficeLogo}>
            <Form.Item className="mt-4" label="Office Logo"
            rules={[{ required: true, message: "Logo is required" }]}
            >
               <FileUpload onChange={setOfficeLogo} office_logo={officeSetting?.user_office_logo}/>
            </Form.Item>

        <div className="flex justify-start mb-10 mt-4">
          <Button className="mr-2" onClick={handleCancel}>
            Cancel
          </Button>
          <BasicButton loading={submitLoading} title={edit? 'Update': "Save"} saving={!edit}/>
        </div>
      </Form>
    </div>
  );
};

export default OfficeLogo;
