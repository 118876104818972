import { Modal, Button } from "antd";
import React from "react";
import {
  calTotal,
  NumberFormatter,
} from "../../../../../../../../utils/validator";
import FormatDateTime from "../../../../../../../../components/FormatDate/FormatDateTime";
import LabelDisplay from "../../../../../../../../components/LabelDisplay/LabelDisplay";
import {
  CopyableText,
} from "../../../../../../../../components/GlobalComponents/GlobalComponents";

const ViewLabInvoice = ({ visible, onClose, rxData }) => {

  return (
    <Modal
      open={visible}
      onCancel={onClose}
      title={<CopyableText text={rxData?.inhouseUID} />}
      footer={null}
      closeIcon={
        <Button size="small" className="app-close-icon" shape="round">
          Close
        </Button>
      }
    >
      <LabelDisplay title="Added By" description={rxData?.added_by} />
      <LabelDisplay
        title="Added Date"
        description={
          <FormatDateTime date={rxData?.issue_date} type="datetime" />
        }
      />
      <LabelDisplay title="Invoice ID" description={rxData?.inhouseUID} />
      <LabelDisplay title="Status" description={rxData?.status} />
      {rxData &&
        rxData?.lab_name?.map((item, idx) => (
          <div key={idx} className="bg-gray-200 p-2 mt-2 mb-2">
            <LabelDisplay title="Lab Name" description={item?.lab_name} />
            <LabelDisplay title="Type" description={item?.lab_type} />
            <LabelDisplay title="Group" description={item?.lab_group} />
            <LabelDisplay
              title="Instructions"
              description={item?.lab_instructions}
            />
            <LabelDisplay
              title="Price"
              description={
                <NumberFormatter
                  value={item?.lab_price}
                  options={{ style: "decimal" }}
                />
              }
            />
            <LabelDisplay title="Diagnosis" description={item?.lab_diagnosis} />
          </div>
        ))}
      <LabelDisplay
        title="Sub Total"
        description={
          <NumberFormatter
            value={rxData?.total_price}
            options={{ style: "decimal" }}
          />
        }
      />
      <LabelDisplay
        title={`Discount ${rxData?.discount}%`}
        description={<NumberFormatter value={rxData?.discounted_amount} />}
      />
      <LabelDisplay
        title="Tax"
        description={
          <NumberFormatter value={rxData?.tax} options={{ style: "decimal" }} />
        }
      />
      <LabelDisplay
        title="Grand Total"
        description={
          <NumberFormatter
            value={rxData?.grand_total}
            options={{ style: "decimal" }}
          />
        }
      />
      <LabelDisplay
        title="Payment Method"
        description={rxData?.payment_method}
      />
      {rxData?.mark_as_paid_by && (
        <LabelDisplay
          title="Mark as Paid By"
          description={rxData?.mark_as_paid_by}
        />
      )}
      {rxData?.mark_as_paid_by_date && (
        <LabelDisplay
          title="Mark as Paid By Date"
          description={
            <FormatDateTime
              date={rxData?.mark_as_paid_by_date}
              type="datetime"
            />
          }
        />
      )}
    </Modal>
  );
};

export default ViewLabInvoice;
