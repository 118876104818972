import React, { useEffect, useState } from "react";
import {  Button, Modal, Spin } from "antd";
import { useOutletContext, useParams } from "react-router-dom";
import { doPost } from "../../../../API/apis";
import { useStateGlobalContext } from "../../../../contexts/GlobalContext";
import FormatDate from '../../../../components/FormatDate/FormatDate'
import Notification from "../../../../components/Notification/Notification";



const PrescriptionItem = ({ prescriptions, showButton }) => {
  const { id } = useParams();
  const [visible, setVisible] = useState(false);
  const [loading , setLoading] = useState(false)
  const [selectedItem, setSelectedItem] = useState(null);
  const [updateHistoricalMedication, setUpdateHistoricalMedication] =
    useState(false);
  const [keysList, setKeysList] = useState([]);
  const { data, getHistoricalMedication } = useStateGlobalContext();

  useEffect(() => {
    setLoading(true)

    data?.forEach((item) => {
      setKeysList((keysList) => [
        ...keysList,
        item?.historical_medication?.key,
      ]);
    });
    setLoading(false)
  }, [id, updateHistoricalMedication]);

  const handlePrescription = (id) => {
    setSelectedItem(id);
    setVisible(true);
  };

  const handelHistoricalMedication = async (record) => {
    const data = {
      historical_medication: record,
    };
    try {
      const response = await doPost(
        `/prescriptions/add-to-history/${id}/`,
        data
      );
      if (response.status === 201) {
        Notification.success("Marked as Historical Successfully");
        setUpdateHistoricalMedication((prev) => !prev); 
        setKeysList([...keysList, record.key]); 
        getHistoricalMedication(id)
      }
    } catch (error) {
      Notification.error("Something went wrong");
    }
  };

  const filteredPrescriptions = prescriptions?.filter(
    (prescription) => !keysList?.includes(prescription?.key)
  );

  return (
    <>
      {loading ? (
        <div className="flex justify-center items-center">
          <Spin />
        </div>
      ) : (
        <div>
          {filteredPrescriptions?.map((prescription) => (
            <div key={prescription.key} className="bg-gray-100 px-3 py-1 mb-1">
              <div className="flex justify-between">
              <p
                onClick={() => {
                  handlePrescription(prescription);
                }}
              >
                <span className="text-[#1890ff] font-semibold cursor-pointer mr-2">
                  {prescription?.searched_medicine.slice(0,50)}
                </span>
                <span
                  className={
                    prescription?.end_date &&
                    new Date(prescription?.end_date) < new Date()
                      ? "text-white bg-slate-500 text-xs rounded-md px-1 py-[2px]"
                      : "text-white bg-[#3484F0] text-xs rounded-md px-1 py-[2px]"
                  }
                >
                  {prescription?.end_date &&
                  new Date(prescription?.end_date) < new Date()
                    ? "Inactive"
                    : "Active"}
                </span>
              </p>
              {showButton && (
                <div className="mb-1">
                  <Button
                    type="primary"
                    size="small"
                    onClick={() => handelHistoricalMedication(prescription)}
                  >
                    Mark as Historical
                  </Button>
                </div>
              )}
              </div>

              <span >
                <span className="font-medium">Start Date: </span>
                {prescription?.start_date
                  ? <FormatDate date={prescription?.start_date}/>
                  : "No Date Entered"}
                - <span className="font-medium">End Date: </span>
                {prescription?.end_date
                  ? <FormatDate date={prescription?.end_date}/>
                  : "No Date Entered"}
              </span>
            </div>
          ))}
        </div>
      )}

      <Modal
        closeIcon={<Button size="small" className="app-close-icon" shape="round">Close</Button>}
        open={visible}
        onCancel={() => setVisible(false)}
        footer={null}
        title="Medicine Details"
      >
        {selectedItem && (
          <div>
            <p className="text-[#1890ff] font-semibold">
              {selectedItem?.searched_medicine}
            </p>
            <p >
              <span className="font-medium">Start Date: </span>
              {selectedItem?.start_date
                ? <FormatDate date={selectedItem?.start_date}/>
                : "No Date Entered"}
            </p>
            <p >
              <span className="font-medium">End Date: </span>
              {selectedItem?.end_date
                ? <FormatDate date={selectedItem?.end_date}/>
                : "No Date Entered"}
            </p>
            <p >
              <span className="font-medium">Instructions: </span>
              {selectedItem?.instructions}
            </p>
            <p >
              <span className="font-medium">Dispense Quantity: </span>
              {selectedItem?.dispense_quantity}
            </p>
            <p>
              <span className="font-medium">Refills: </span>
              {selectedItem?.refills}
            </p>
            <p >
              <span className="font-medium">Dispense as Written: </span>
              {selectedItem?.dispense_as_written?.toString() === "true"
                ? "Yes"
                : "No"}
            </p>
            <p >
              <span className="font-medium">Associated Diagnosis: </span>
              {selectedItem?.associated_diagnosis}
            </p>
          </div>
        )}
      </Modal>
    </>
  );
};

export default PrescriptionItem;
