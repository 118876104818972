import React, { useEffect, useState, useCallback, useMemo } from "react";
import { Button, Tooltip } from "antd";
import { doPatch, doDelete } from "../../../API/apis";
import {  PlusOutlined } from "@ant-design/icons";
import { useStateGlobalContext } from "../../../contexts/GlobalContext";
import { CSVLink } from "react-csv";
import { SlRefresh } from "react-icons/sl";
import Notification from "../../../components/Notification/Notification";
import showConfirmModal from "../../../components/ModalConfirm/ModalConfirm";
import TasksModal from "./components/TasksModal";
import CustomTable from "../../../components/CustomTable/CustomTable";
import { getTasksColumn } from "../../../components/TableColumns/TableColumns";
import { taskActions , taskStatusMap} from "../../../utils/Data";
import ViewTask from "./components/ViewTask";

const Tasks = () => {
  const [edit, setEdit] = useState(false);
  const [loading, setLoading] = useState(false);
  const [selectedItem, setSelectedItem] = useState(null);
  const [taskModal, setTaskModal] = useState(false);
  const [viewTask, setViewTask] = useState(null)


  const { providers, profile, tasks, getTasks } = useStateGlobalContext();


  const handleUpdate = useCallback((record) => {
    console.log(record, "RECORD")
    if (record.status !== "Completed") {
      setEdit(true);
      setSelectedItem(record);
      setTaskModal(true);
    } else {
      Notification.warning("Cannot edit a completed task.");
    }
  }, []);

  const handleStatus = useCallback(
    async (record, key) => {
      
      const status = taskStatusMap[key] || "To Do";

      try {
        const response = await doPatch(`/tasks/update/${record.id}`, {status});
        if (response.status === 200) {
          Notification.success("Status updated Successfully");
          getTasks(false);
        }
      } catch (error) {
        Notification.error("An error occurred while updating status");
      }
    },
    [getTasks]
  );

  const columns = getTasksColumn({
    providers,
    taskActions,
    handleStatus,
    handleUpdate,
    setViewTask,
  });

  const handleCancel = useCallback(() => {
    setEdit(false);
    setTaskModal(false);
  }, []);


  const handleDelete = useCallback(() => {
    showConfirmModal({
      title: "Confirm Removal",
      icon: null,
      content: "Are you sure you want to Remove All Completed Tasks?",
      okText: "Remove",
      okType: "danger",
      cancelText: "Cancel",
      className: "custom-modal",
      onOk: confirmDelete,
    });
  }, []);

  const confirmDelete = useCallback(async () => {
    try {
      const response = await doDelete("/tasks/delete-old-tasks/");
      if (response.status === 200) {
        Notification.success("All Completed Tasks Deleted Successfully");
        getTasks(false);
      }
    } catch (error) {
      Notification.error("Something went wrong");
    }
  }, []);

  useEffect(() => {
    const fetchTasks = async () => {
      setLoading(true);
      await getTasks(false);
      setLoading(false);
    };
    fetchTasks();
  }, []);

  document.title = "Tasks | American EMR";

  const userRole = profile?.user_info[0]?.role;
  return (
    <div className="mx-auto">
      <div className="flex justify-between items-center mb-4">
        <h1>Tasks</h1>
        <div>
          <Tooltip title="Refresh" onClick={() => getTasks(false)}>
            <SlRefresh size={16} className="cursor-pointer mr-4" />
          </Tooltip>

          {((profile && userRole === "clinic") ||
            profile?.user_info[0]?.role === "manager") && (
            <Button className="danger__button mr-2" onClick={handleDelete}>
              Remove
            </Button>
          )}
          {tasks && tasks?.length > 0 && (
            <Button className="mr-2">
              <CSVLink data={tasks} filename={"Tasks.csv"} className="csv-link">
                Download Report
              </CSVLink>
            </Button>
          )}
          <Button
            type="primary"
            className="font-bold"
            onClick={() => setTaskModal(true)}
          >
            New Task <PlusOutlined />
          </Button>
        </div>
      </div>

      <CustomTable
        columns={columns}
        dataSource={tasks}
        loading={loading}
        rowKey="tasks_lists"
      />

      <TasksModal
        visible={taskModal}
        onClose={handleCancel}
        edit={edit}
        selectedItem={selectedItem}
        taskActions={taskActions}
      />

      <ViewTask
        open={viewTask?.open}
        onCancel={()=>setViewTask(null)}
        data={viewTask?.detail}
      />
    </div>
  );
};

export default Tasks;
