import { Button, Modal, Typography, Image, Space, Input } from "antd";
import React from "react";
import {
  FilePdfOutlined,
} from "@ant-design/icons";
import FormatDateTime from "../../../../../components/FormatDate/FormatDateTime";
import LabelDisplay from "../../../../../components/LabelDisplay/LabelDisplay";
import { ImageView } from "../../../../../components/GlobalComponents/GlobalComponents";
import ViewTemplate from "../../../Templates/components/ViewTemplate";

const ViewLabResults = ({ visible, onClose, data, fileData, loading }) => {
  const showFile = (fileContent) => {
    window.open(fileContent, "_blank");
  };

  const renderFileContent = () => {
    const fileExtension = fileData?.type?.split("/").pop();
    if (fileExtension === "pdf") {
      return (
        <div className="flex justify-start">
          <FilePdfOutlined style={{ fontSize: "64px", color: "#ff0000" }} />
          <Typography.Text strong className="pdf-label">
            PDF Document
          </Typography.Text>
        </div>
      );
    } else {
      return (
        <ImageView src={fileData?.url}/>
      );
    }
  };

  return (
    <Modal
      open={visible}
      onCancel={onClose}
      footer={null}
      width={900}
      title={data && data?.filename}
      confirmLoading={loading}
      className="lab-results-modal"
      closeIcon={
        <Button size="small" className="app-close-icon" shape="round">
          Close
        </Button>
      }
    >
      <div className="lab-details">
        <LabelDisplay title="Uploaded By" description={data?.added_by}/>
        <LabelDisplay title="Date Added" description={<FormatDateTime date={data?.date_time} type="datetime"/>}/>
        <LabelDisplay title="Lab Order ID" description={data?.lab_order_id}/>
        <LabelDisplay title="Lab Name" description={data?.service_name}/>
        <LabelDisplay title="Description" description={<ViewTemplate template={data?.description}/>}/>
        <LabelDisplay title="Uploaded By" description={data?.added_by}/>
      </div>
      
      {data?.uploaded_file_encrypted_name && renderFileContent()}

      {data?.uploaded_file_encrypted_name && <div className="view-file-button">
        <Button
          type="primary"
          onClick={() => showFile(fileData && fileData?.url)}
        >
          View File
        </Button>
      </div>}
    </Modal>
  );
};

export default ViewLabResults;
