import React from 'react'
import dayjs from 'dayjs';
import { Divider } from 'antd';
import FormatDate from '../../../../../../../components/FormatDate/FormatDate';
import { NumberFormatter } from '../../../../../../../utils/validator';

const InsuranceView = ({data}) => {
    const isExpirationDatePast = (expirationDate) => {
        return dayjs().isAfter(dayjs(expirationDate));
      };

  return (
    <div className="view-wrapper">
    <div>
      {isExpirationDatePast(
        data &&
          data[0]?.insured_person_info?.expiration_date
      ) && (
        <span className="text-red-500 font-semibold">
          (Insurance is expired)
        </span>
      )}
      <h1 className="font-medium mb-2">Insurance Company Details </h1>
      <p>
        Company Name:{" "}
        {data &&
          data[0]?.insurance_company?.insurance_company_name}
      </p>
      <p>
        Company Address:{" "}
        {data &&
          data[0]?.insurance_company
            ?.insurance_company_address}{" "}
      </p>
      <p>
        Company Email:{" "}
        {data &&
          data[0]?.insurance_company?.insurance_company_email}
      </p>
      <p>
        Company Phone:{" "}
        {data &&
          data[0]?.insurance_company?.insurance_company_phone}
      </p>
      <p>
        Company Fax:{" "}
        {data &&
          data[0]?.insurance_company?.insurance_company_fax}
      </p>
      <p>
        Notes:{" "}
        {data &&
          data[0]?.insurance_company?.insurance_company_notes}
      </p>
    </div>
    <Divider />
    <div className="mt-4">
      <h1 className="font-medium mb-2">
        Primary Account Holder Details{" "}
      </h1>
      <p>
        First Name:{" "}
        {data &&
          data[0]?.insured_person_info?.first_name}
      </p>
      <p>
        Middle Name:{" "}
        {data &&
          data[0]?.insured_person_info?.middle_name}
      </p>
      <p>
        Last Name:{" "}
        {data &&
          data[0]?.insured_person_info?.last_name}
      </p>
      <p>
        Insurer ID:{" "}
        {data &&
          data[0]?.insured_person_info?.insured_id}
      </p>
      <p>
        Group:{" "}
        {data &&
          data[0]?.insured_person_info?.insured_group}
      </p>
      <p>
        Plan:{" "}
        {data &&
          data[0]?.insured_person_info?.insurance_plan}
      </p>
      <p>
        Policy #:{" "}
        {data &&
          data[0]?.insured_person_info?.insurance_policy}
      </p>
      <p>
        Relationship:{" "}
        {data &&
          data[0]?.insured_person_info?.relationship}
      </p>
      <p>
        Notes:{" "}
        {data &&
          data[0]?.insured_person_info?.insurer_notes}
      </p>
      <p>
        Effective Date:{" "}
        {data &&
          data[0]?.insured_person_info?.effective_date &&
          <FormatDate date={data[0]?.insured_person_info?.effective_date}/>
      }
      </p>
      <p>
        Expiration Date:{" "}
        {data &&
          data[0]?.insured_person_info?.expiration_date && 
          <FormatDate date={data[0]?.insured_person_info?.expiration_date}/>
        }
      </p>
    </div>

    <Divider />
    <div className="mt-4">
      <h1 className="font-medium mb-2">Payment Info </h1>
      <p>
        Copay Amount: {data && data[0]?.payment_info?.copay_amount &&  <NumberFormatter value={data[0]?.payment_info?.copay_amount}/>}
      </p>
      <p>
        Copay Percentage:{" "}
        {data &&
          data[0]?.payment_info?.copay_percentage}%
      </p>
      <p>
        Deductible Amount: {data && data[0]?.payment_info?.deductible_amount && <NumberFormatter value={data[0]?.payment_info?.deductible_amount}/>}
      </p>
      <p>
        Deductible Amount Met:{" "}
        {data && data[0]?.payment_info?.deductible_percentage && 
          <NumberFormatter value={data[0]?.payment_info?.deductible_percentage}/>}
      </p>
      <p>
        {" "}
        Prior Authorization Number:{" "}
        {data &&
          data[0]?.payment_info?.prior_authorization_number}
      </p>
      <p>
        Notes:{" "}
        {data &&
          data[0]?.payment_info?.payment_info_notes}
      </p>
    </div>
  </div>
  )
}

export default InsuranceView
