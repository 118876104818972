import React from "react";
import { Card, Col } from "antd";
import TotalPatientCharts from "./TotalPatientCharts";


const DashboardChart = ({graph, loading}) => {

  return (
    <Col xl={12} sm={24}>
      <Card className="mt-4 shadow-sm max-h-[400px]" loading={loading}>
        <div className="flex justify-between">
          <h1 className="font-bold text-lg">Total Patients </h1>
        </div>
        <TotalPatientCharts data={graph} loading={loading}/>
        
      </Card>
    </Col>
  );
};

export default DashboardChart;
