import {
  PlusOutlined,
  EditOutlined,
  DeleteOutlined,
} from "@ant-design/icons";
import dayjs from "dayjs";
import { useParams } from "react-router-dom";
import React, { useEffect, useState } from "react";
import { Modal, Form, Input, Button, Spin } from "antd";
import { doPost, doGet, doPatch, doDelete } from "../../../../../API/apis";
import BaseDatePicker from "../../../../../components/BaseDatePicker/BaseDatePicker";
import MedicalCardsTitle from "../MedicalCardsTitle";
import Notification from "../../../../../components/Notification/Notification";
import showConfirmModal from "../../../../../components/ModalConfirm/ModalConfirm";
import FormatDate from "../../../../../components/FormatDate/FormatDate";
import FormatDateTime from "../../../../../components/FormatDate/FormatDateTime";




function PastSurgicalHistory() {
  const { id } = useParams();
  const [form] = Form.useForm();
  const [data, setData] = useState([]);
  const [visible, setVisible] = useState(false);
  const [loading, setLoading] = useState(false);
  const [editMode, setEditMode] = useState(null);
  const [hoveredItemId, setHoveredItemId] = useState(null);
  const [selectedItem, setSelectedItem] = useState(null);
  const [pastSurgicalHistoryVisible, setPastSurgicalHistoryVisible] = useState(false);
  

  const disabledDate = (current) => {
    return current && current > new Date();
  };



  const openModal = () => {
    setVisible(true);
  };

  const handleCancel = () => {
    setVisible(false);
    setEditMode(false);
    setPastSurgicalHistoryVisible(false);
    form.resetFields();
  };

  const handleItemClick = (record) => {
    setSelectedItem(record);
    setPastSurgicalHistoryVisible(true);
  };

  const handleEdit = (record) => {
    setEditMode(true);
    setSelectedItem(record);
    form.setFieldsValue({
      past_surgical_history: record?.past_surgical_history,
      surgery_date: record?.surgery_date ? dayjs(record?.surgery_date) : "",
    });
    setVisible(true);
  };

  const handleDelete = (record) => {
    showConfirmModal({
      title: "Are you sure you want to delete this item?",
      icon: <DeleteOutlined />,
      content: "This action cannot be undone.",
      okText: "Delete",
      okType: "danger",
      cancelText: "Cancel",
      className:'custom-modal',
      onOk() {
        deletePastSurgicalHistory(record);
      },
    });
  };

  const deletePastSurgicalHistory = async (record) => {
    try {
      const response = await doDelete(
        `/patient/delete-past-surgical-history/${id}/${record}/`,
      );
      if (response.status === 200) {
        Notification.success("Past surgical history deleted successfully");
        getPatientSurgicalHistory();
      }
    } catch (error) {
      Notification.error("Something went wrong");
    }
  };

  const handlePatientSurgicalHistory = async (values) => {
    setLoading(true)
    const data = {
      surgery_date: values.surgery_date ? values.surgery_date : "",
      past_surgical_history: values.past_surgical_history,
    };
    try {
      const response = editMode
        ? await doPatch(
            `/patient/update-past-surgical-history/${id}/${selectedItem.id}/`,
            data,
          )
        : await doPost(
            `/patient/add-past-surgical-history/${id}/`,
            data,
          );
      if (response.status === 200 || response.status === 201) {
        const successMessage = editMode
          ? "Past medical history updated successfully"
          : "Past medical history added successfully";
        Notification.success(successMessage);
        getPatientSurgicalHistory();
        handleCancel();
      }
    } catch (error) {
      console.log(error);
    } finally{
      setLoading(false)
    }
  };

  const getPatientSurgicalHistory = async () => {
    setLoading(true);
    try {
      const response = await doGet(
        `/patient/get-past-surgical-history/${id}/`,
      );
      if (response.status === 200) {
        setData(response.data.reverse());
      }
    } catch (error) {
      console.log(error);
    } finally{
      setLoading(false)
    }
  };

  useEffect(() => {
    getPatientSurgicalHistory();
  }, []);

  return (
    <div>
      <div className="shadow-class p-4 border rounded-md h-[180px]  max-h-[180px]">
        <div className="flex items-center justify-between">
          <MedicalCardsTitle title='Past Surgical History'/>
          <Button type="primary" size="small" shape='round' onClick={openModal}>Add <PlusOutlined  /></Button>
        </div>
        {loading ? (
          <div className="flex justify-center items-center">
            <Spin size="small" />
          </div>
        ) : (
          <div className="flex max-h-[130px]  overflow-auto mt-1">
            <ul>
              {data.map((item, index) => (
                <li
                  key={item.id}
                  className="text-[#1890ff] cursor-pointer flex justify-between items-center"
                  onMouseEnter={() => setHoveredItemId(item.id)}
                  onMouseLeave={() => setHoveredItemId(null)}
                >
                  <span
                    onClick={() => {
                      handleItemClick(item);
                    }}
                    title={item?.past_surgical_history}
                  >
                    {index + 1}. {item?.past_surgical_history?.slice(0, 26)}
                    {item.past_surgical_history?.length > 26 ? "..." : ""}
                  </span>
                  {hoveredItemId === item.id && (
                    <div className="flex items-center ml-3">
                      <EditOutlined
                        className="text-[#1890ff] mr-2"
                        onClick={() => {
                          handleEdit(item);
                        }}
                      />
                      <DeleteOutlined
                        className="text-[#1890ff]"
                        onClick={() => {
                          handleDelete(item.id);
                        }}
                      />
                    </div>
                  )}
                </li>
              ))}
              {data.length === 0 && (
                <li className="flex justify-between items-center">
                  <span>No past Surgical history</span>
                </li>
              )}
            </ul>
          </div>
        )}
      </div>

      <Modal
        title="Past Surgical History"
        open={visible}
        closeIcon={
          <Button size="small" className="app-close-icon" shape="round">Close</Button>
        }
        onCancel={handleCancel}
        maskClosable={false}
        footer={null}
        width={650}
      >
        <Form
          layout="vertical"
          name="past-surgical-history"
          form={form}
          onFinish={handlePatientSurgicalHistory}
        >
          <Form.Item name="surgery_date" label="Date">
            <BaseDatePicker
              disabledDate={disabledDate}
            />
          </Form.Item>
          <Form.Item
            name="past_surgical_history"
            label="Past Surgical History"
            rules={[
              {
                required: true,
                message: "Please enter past surgical history",
              },
            ]}
          >
            <Input.TextArea maxLength={500} showCount />
          </Form.Item>
          <div className="flex justify-end">
            <Button onClick={handleCancel}>Cancel</Button>
            <Button type="primary" htmlType="submit" className="ml-2" loading={loading}>
              {editMode ? "Update" : "Save"}
            </Button>
          </div>
        </Form>
      </Modal>

      <Modal
        title="Past Surgical History"
        open={pastSurgicalHistoryVisible}
        onCancel={handleCancel}
        maskClosable={false}
        footer={null}
        width={650}
        closeIcon={
          <Button size="small" className="app-close-icon" shape="round">Close</Button>
        }
      > 
        {selectedItem && (
          <>
            <p>
              <span className="font-medium">Surgery Date: </span>
              <span>
                {selectedItem?.surgery_date
                  ? <FormatDate date={selectedItem?.surgery_date}/>
                  : "No Date Entered"}
              </span>
            </p>
            <p>
              <span className="font-medium">Surgery Name:</span>
              <span className="ml-2">
                {selectedItem?.past_surgical_history}
              </span>
            </p>
            <p>
              <span className="font-medium">Added By:</span>
              <span className="ml-2">{selectedItem?.added_by}</span>
            </p>
            <p>
              <span className="font-medium">Date:</span>
              <span className="ml-2">
                <FormatDateTime date={selectedItem?.date_time} type="datetime"/>
              </span>
            </p>
          </>
        )}
      </Modal>
    </div>
  );
}

export default PastSurgicalHistory;
