import React from "react";
import "./invoiceheader.css";
import FormatDateTime from "../FormatDate/FormatDateTime";
import ClinicInformation from "../PrescriptionsPad/ClinicInformation";
import { Status } from "../../utils/Data";


const InvoiceHeader = ({
  officeInformation,
  patientInformation,
  rxData,
  // billingInformation,
}) => {
  return (
    <div>
      <p className="text-center">
        <span className="font-semibold">Invoice ID: </span>
        {rxData?.inhouseUID}
        {rxData?.invoice_id}{" "}
        <span className="font-semibold ml-4">Status: </span> <Status status={rxData?.status}/>
      </p>
      <div className="flex justify-between mt-4 invoice-header">
        <div className="w-[33%]">
          <p className="font-semibold">Facility Information</p>
          {ClinicInformation(officeInformation)}

        </div>
        <div className="w-[33%]">
          <p className="font-semibold">Customer Information</p>
          <p>
            Full Name: {patientInformation?.first_name}{" "}
            {patientInformation?.middle_name && patientInformation?.middle_name}{" "}
            {patientInformation?.last_name}
          </p>
          <p>Address: {`${patientInformation?.current_address_line_1 || ""} ${patientInformation?.current_address_line_2 ? `, ${patientInformation?.current_address_line_2}` : ""} ${patientInformation?.current_city ? `, ${patientInformation?.current_city}` : ""} ${patientInformation?.current_state ? `, ${patientInformation?.current_state}` : ""} ${patientInformation?.current_country ? `, ${patientInformation?.current_country}` : ""}`}</p>
          <p>Phone: {patientInformation?.patient_phone}</p>
          <p>Email: {patientInformation?.patient_email}</p>
          <p>Payment Method: {rxData?.payment_method}</p>
          <p className="font-semibold">Created By:</p>
          <p>
            Name User ID: {rxData?.created_by} {rxData?.added_by}
          </p>
          <p>Date: <FormatDateTime date={rxData?.issue_date || rxData?.date_added} type="date"/></p>
          <p>Time: <FormatDateTime date={rxData?.issue_date || rxData?.date_added} type="time"/></p>
        </div>
      </div>
    </div>
  );
};

export default InvoiceHeader;
