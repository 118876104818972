import { useStateGlobalContext } from "../contexts/GlobalContext";
import { FormattedNumber } from 'react-intl'; 
// import PropTypes from 'prop-types';

const Currency = ({ value }) => {
  const { officeSetting } = useStateGlobalContext();
  let currency = officeSetting?.local_currency || 'USD';

  return (
    <FormattedNumber value={value} style="currency" currency={currency} />
  );
};

// Currency.propTypes = {
//   value: PropTypes.number.isRequired 
// };

export default Currency;
