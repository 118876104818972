import React from "react";
import ReactDOM from "react-dom/client";
import App from "./App";
import "./index.css";
import 'react-quill/dist/quill.snow.css';
import { BrowserRouter } from "react-router-dom";
import { GlobalContextProvider } from "./contexts/GlobalContext";
import { ThemeProvider } from "./contexts/ThemeContext";
import ScrolltoTop from "./utils/ScrolltoTop";
import { IntlProvider } from "react-intl";

const locale = 'en'; 
const messages = {}; 

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
    <GlobalContextProvider>
      <ThemeProvider>
        <BrowserRouter>
           <ScrolltoTop/>
           <IntlProvider locale={locale} messages={messages}>
          <App />
          </IntlProvider>
        </BrowserRouter>
      </ThemeProvider>
    </GlobalContextProvider>
  </React.StrictMode>
);
