import React from "react";
import { Menu, Layout } from "antd";
import { Outlet, useNavigate, useLocation } from "react-router-dom";
import { useStateGlobalContext } from "../../../../contexts/GlobalContext";

const { Content } = Layout;

const items = [
  { label: "Summary", key: "" },
  { label: "Visits", key: "visits" },
  { label: "General Notes", key: "general-notes" },
  { label: "Medications", key: "medications" },
  { label: "Lab & Imaging", key: "labs&imaging" },
  {
    label: "Invoice & Billing",
    key: "invoice&billing",
  },
  { label: "Memberships", key: "memberships" },
  {
    label: "Patient Portal",
    key: "patient-portal",
  },
  { label: "Referral", key: "referral" },
  { label: "Files", key: "patient-files" },
  { label: "Insurances", key: "insurances" },
  { label: "Profile", key: "profile" },
];

const PatientTabs = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { officeSetting } = useStateGlobalContext();

  const officeDateFormat = officeSetting?.date_format;
  const officeTimeFormat = officeSetting?.time_format;
  const officeTimezone = officeSetting?.facility_time_zone;

  // Extract the last segment of the URL
  const lastPathSegment = location.pathname.split("/").filter(Boolean).pop();

  return (
    <Layout style={{ backgroundColor: "transparent" }}>
      <Menu
        onClick={({ key }) => navigate(key)}
        mode="horizontal"
        selectedKeys={[lastPathSegment]}  // Set the last path segment as the selected key
        items={items}
        className="summary-menu mb-4 bg-white font-medium tracking-[0.2px]"
      />
      <Content
        className="layout"
        style={{
          backgroundColor: "white",
        }}
      >
        <Outlet context={[officeDateFormat, officeTimeFormat, officeTimezone]} />
      </Content>
    </Layout>
  );
};

export default PatientTabs;
