import { Button, Modal } from "antd";
import React from "react";
import FormatDateTime from "../../../../../components/FormatDate/FormatDateTime";
import FormatDate from "../../../../../components/FormatDate/FormatDate";
import LabelDisplay from "../../../../../components/LabelDisplay/LabelDisplay";

const ViewReferralNotes = ({ open, onClose, data }) => {
  return (
    <Modal
      title={data?.referral_id}
      open={open}
      onCancel={onClose}
      footer={null}
      width={750}
      closeIcon={
        <Button size="small" className="app-close-icon" shape="round">Close</Button>
      }
    >
      <LabelDisplay title="Added By" description={data?.added_by} />
      <LabelDisplay 
        title="Date Added" 
        description={<FormatDateTime date={data?.date_time} type="datetime" />} 
      />
      <LabelDisplay title="Status" description={data?.status} />

      <div className="bg-gray-200 p-4">
        <LabelDisplay 
          title="Start Date" 
          description={data?.start_date && <FormatDate date={data?.start_date} />} 
        />
        <LabelDisplay 
          title="Expiry Date" 
          description={data?.end_date && <FormatDate date={data?.end_date} />} 
        />
        <LabelDisplay title="Authorization Code" description={data?.auth_code} />

        <hr className="my-2 bg-grey-400" />
        <h4>Referring From:</h4>
        <LabelDisplay title="Name" description={data?.referral_from_name} />
        <LabelDisplay title="Speciality" description={data?.referral_from_speciality} />
        <LabelDisplay title="Phone" description={data?.referral_from_phone} />
        <LabelDisplay title="Fax" description={data?.referral_from_fax} />
        <LabelDisplay title="Email" description={data?.referral_from_email} />

        <hr className="my-2 bg-grey-400" />
        <h4>Referring To:</h4>
        <LabelDisplay title="Name" description={data?.referral_to_full_name} />
        <LabelDisplay title="Facility Name" description={data?.referral_to_facility_name} />
        <LabelDisplay title="Speciality" description={data?.referral_to_speciality} />
        <LabelDisplay title="Phone" description={data?.referral_to_phone} />
        <LabelDisplay title="Fax" description={data?.referral_to_fax} />
        <LabelDisplay title="Email" description={data?.referral_to_email} />
        <LabelDisplay title="Address" description={data?.referral_to_address} />
        <LabelDisplay title="Opening Hours" description={data?.referral_to_operating_hours} />

        <hr className="my-2 bg-grey-400" />
        <h4>Patient Insurance Information:</h4>
        <LabelDisplay title="Type" description={data?.insurance_type} />
        <LabelDisplay title="Company" description={data?.insurance_company} />
        <LabelDisplay title="Plan" description={data?.insurance_plan} />
        <LabelDisplay title="Group" description={data?.insurance_group} />
        <LabelDisplay title="Policy" description={data?.insurance_policy} />

        <hr className="my-2 bg-grey-400" />
        <h4>Clinical Notes:</h4>
        <LabelDisplay title="Instructions" description={data?.instructions} />
        <LabelDisplay title="Referral For Reason" description={data?.reason_for_referral} />
        <LabelDisplay title="Patient Diagnosis" description={data?.patient_diagnostic_code} />
        <LabelDisplay title="Clinical Notes" description={data?.clinic_notes} />

        <hr className="my-2 bg-grey-400" />
        <LabelDisplay title="Internal Notes" description={data?.other_notes} />
      </div>
    </Modal>
  );
};

export default ViewReferralNotes;
