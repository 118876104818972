import React, { useEffect, useState } from "react";
import { Form, Input, Button, Row, Col, Checkbox } from "antd";
import { useParams } from "react-router-dom";
import { useStateGlobalContext } from "../../../../../../contexts/GlobalContext";
import { doPatch } from "../../../../../../API/apis";
import Loader from "../../../../../../components/Loader/Loader";
import Notification from "../../../../../../components/Notification/Notification";
import { emailValidator, phoneValidator } from "../../../../../../utils/validator";
import LabelDisplay from "../../../../../../components/LabelDisplay/LabelDisplay";

const ContactInformation = () => {
  const { id } = useParams();
  const [form] = Form.useForm();
  const [edit, setEdit] = useState(false);
  const { patientBasicInfo, getPatientData , loading} = useStateGlobalContext();

  const {
    patient_phone,
    patient_email,
    phone_decline,
    email_decline,
    patient_home,
    patient_work,
    extension,
    patient_contact_notes,
  } = patientBasicInfo || {};

  useEffect(() => {
    const fetchData = async () => {
      await getPatientData(id);
      if (patientBasicInfo) {
        form.setFieldsValue({
          patient_phone,
          phone_decline,
          patient_email,
          email_decline,
          patient_home,
          patient_work,
          extension,
          patient_contact_notes,
        });
      }
    };
    fetchData();
  }, [id]);

  const handleUpdate = async (values) => {
    try {
      const response = await doPatch(`/patient/update-info/${id}/`, values);
      if (response.status === 200) {
        Notification.success("Patient Profile is Updated Successfully");
        await getPatientData(id);
        setEdit(false);
      }
    } catch (error) {
      console.log(error);
    }
  };

  if (loading) {
    return <Loader />;
  }

  return (
    <div className="wrapper shadow-class">
      <div className="flex justify-between">
        <h1 className="text-[1.125rem] font-bold mb-4">Contact Information</h1>
        <Button onClick={() => setEdit(!edit)}>
          {edit ? "Close" : "Edit"}
        </Button>
      </div>

      {edit ? (
        <Form layout="vertical" form={form} onFinish={handleUpdate}>
          <Row gutter={{ xs: 10, md: 15, xl: 30 }}>
            <Col span={12}>
              <Form.Item
                label="Phone"
                name="patient_phone"
                rules={[
                  {
                    validator: phoneValidator
                  },
                ]}
              >
                <Input placeholder="Phone" maxLength={30} />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                label="Email"
                name="patient_email"
                rules={[
                  {
                    validator: emailValidator
                  },
                ]}
              >
                <Input placeholder="Email" maxLength={30} />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={{ xs: 10, md: 15, xl: 30 }}>
            <Col span={12}>
              <Form.Item name="phone_decline" valuePropName="checked">
                <Checkbox>Patient declines to provide phone</Checkbox>
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item name="email_decline" valuePropName="checked">
                <Checkbox>Patient declines to provide email</Checkbox>
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={{ xs: 10, md: 15, xl: 30 }}>
          <Col span={8} xs={24} md={12} lg={8}>
              <Form.Item
                label="Home"
                name="patient_home"
                rules={[
                  {
                    validator: phoneValidator
                  },
                ]}
              >
                <Input placeholder="Home" maxLength={30} />
              </Form.Item>
            </Col>
            <Col span={8} xs={24} md={12} lg={8}>
              <Form.Item
                label="Work"
                name="patient_work"
                rules={[
                  {
                    validator: phoneValidator
                  },
                ]}
              >
                <Input placeholder="Work" maxLength={30} />
              </Form.Item>
            </Col>
            <Col span={8} xs={24} md={12} lg={8}>
              <Form.Item
                label="Extension"
                name="extension"
                rules={[
                  {
                    pattern: /^[0-9]+$/,
                    message: "Extension must only contain digits",
                  },
                ]}
              >
                <Input placeholder="Extension" maxLength={10} />
              </Form.Item>
            </Col>
          </Row>
          <Form.Item label="Notes" name="patient_contact_notes">
            <Input.TextArea placeholder="Notes" maxLength={300} showCount />
          </Form.Item>
          <div className="flex justify-end">
            <Button onClick={() => setEdit(false)}>Cancel</Button>
            <Button type="primary" htmlType="submit" className="ml-2">
              Save Changes
            </Button>
          </div>
        </Form>
      ) : (
        <div className="view-wrapper">
          <LabelDisplay title="Phone" description={patient_phone || "Patient Declined to Provide"}/>
          <LabelDisplay title="Email" description={patient_email || "Patient Declined to Provide"}/>
          <LabelDisplay title="Home" description={patient_home}/>
          <LabelDisplay title="Work" description={patient_work}/>
          <LabelDisplay title="Extension" description={extension}/>
          <LabelDisplay title="Notes" description={patient_contact_notes} className="notes"/>
        </div>
      )}
    </div>
  );
};

export default ContactInformation;
