import React, { useEffect, useState } from "react";
import {
  Modal,
  Form,
  Button,
  Spin,
} from "antd";
import { PlusOutlined } from "@ant-design/icons";
import {  doDelete } from "../../../../API/apis";
import { EditOutlined, DeleteOutlined } from "@ant-design/icons";
import { useParams} from "react-router-dom";
import { yearCalculator } from "../../../../utils/ageCalculator";
import CurrentMedicationModal from "./component/CurrentMedicationModal";
import { useStateGlobalContext } from "../../../../contexts/GlobalContext";
import Notification from "../../../../components/Notification/Notification";
import showConfirmModal from "../../../../components/ModalConfirm/ModalConfirm";
import FormatDate from "../../../../components/FormatDate/FormatDate";
import FormatDateTime from "../../../../components/FormatDate/FormatDateTime";
import LabelDisplay from "../../../../components/LabelDisplay/LabelDisplay";

const SUCCESS_MESSAGES = {
  UPDATE: "Medication History updated successfully",
  ADD: "Medication History added successfully",
  DELETE: "Medication History Deleted Successfully",
};

function CurrentMedications() {
  const { id } = useParams();
  const [form] = Form.useForm();
  const [visible, setVisible] = useState(false);
  const [loading, setLoading] = useState(false);
  const [editMode, setEditMode] = useState(false);
  const [selectedItem, setSelectedItem] = useState(null);
  const [hoveredItemId, setHoveredItemId] = useState(null);
  const [currentMedicationView, setCurrentMedicationView] = useState(false);
  const { currentMedication: data, getCurrentMedications } =
    useStateGlobalContext();


  const openModal = () => {
    setVisible(true);
  };

  const handleCancel = () => {
    setTimeout(() => {
      form.resetFields();
      setCurrentMedicationView(false);
      setVisible(false);
      setEditMode(false);
    }, 300); 
  };
  

  const handleCurrentMedicationView = (record) => {
    setSelectedItem(record);
    setCurrentMedicationView(true);
  };

  const handleEdit = (record) => {
    setSelectedItem(record);
    setEditMode(true);
    setVisible(true);
  };
  const handleDelete = (record) => {
    showConfirmModal({
      title: "Confirm Delete?",
      icon: null,
      content: "Are you sure you want to delete this?",
      okText: "Delete",
      okType: "danger",
      cancelText: "Cancel",
      className: "custom-modal",
      onOk() {
        deletePastSurgicalHistory(record);
      },
    });
  };

  const deletePastSurgicalHistory = async (record) => {
    try {
      const response = await doDelete(
        `/patient/delete-current-medications/${id}/${record}/`
      );
      if (response.status === 200) {
        Notification.success(SUCCESS_MESSAGES.DELETE);
        getCurrentMedications(id);
      }
    } catch (error) {
      Notification.error("Something went wrong");
    }
  };

  useEffect(() => {
    getCurrentMedications(id);
  }, [id]);

  return (
    <div>
      <div className="shadow-class p-4 border rounded-md mt-4 h-[180px]  max-h-[180px]">
        <div className="flex items-center justify-between">
          <h1 className="font-semibold cards__heading ">
            {" "}
            All Current Medications
          </h1>
          <Button type="primary" size="small" shape="round" onClick={openModal}>
            Add <PlusOutlined />
          </Button>
        </div>

        {loading ? (
          <div className="flex justify-center items-center">
            <Spin size="small" />
          </div>
        ) : (
          <div className="max-h-[130px]  overflow-auto mt-1">
            {data?.map((item, idx) => (
              <div key={idx}>
                <div
                  className="flex"
                  onMouseEnter={() => setHoveredItemId(item?.id)}
                  onMouseLeave={() => setHoveredItemId(null)}
                >
                  <p>
                    <span
                      className="text-[#1890ff] cursor-pointer"
                      onClick={() => handleCurrentMedicationView(item)}
                    >
                      {item?.medicine_name?.slice(0, 50)}{" "}
                      {item?.end_date
                        ? ` (${yearCalculator(
                            item?.start_date,
                            item?.end_date
                          )} years)`
                        : " (Present)"}
                    </span>
                    <span
                      className={
                        item?.end_date && new Date(item?.end_date) < new Date()
                          ? "text-white bg-slate-500 text-xs rounded-md px-1 py-[2px] ml-2"
                          : "text-white bg-[#3484F0] text-xs rounded-md px-1 py-[2px] ml-2"
                      }
                    >
                      {item?.end_date && new Date(item?.end_date) < new Date()
                        ? "Inactive"
                        : "Active"}
                    </span>
                  </p>
                  <p>
                    {hoveredItemId === item.id && (
                      <div className="flex items-center ml-3">
                        <EditOutlined
                          className="text-[#1890ff] mr-2"
                          onClick={() => {
                            handleEdit(item);
                          }}
                        />
                        <DeleteOutlined
                          className="text-[#1890ff]"
                          onClick={() => {
                            handleDelete(item.id);
                          }}
                        />
                      </div>
                    )}
                  </p>
                </div>
                <p>
                  <span className="font-medium">Start Date:</span>
                  <span>
                    {" "}
                    {item?.start_date
                      ? <FormatDate date={item?.start_date}/>
                      : "No Date Entered"}
                  </span>
                  <span className="font-medium ml-3 ">End Date: </span>
                  <span>
                    {item?.end_date
                      ? <FormatDate date={item?.end_date}/>
                      : "No Date Entered"}
                  </span>
                </p>
              </div>
            ))}
          </div>
        )}
      </div>

      <CurrentMedicationModal
        open={visible}
        onCancel={handleCancel}
        edit={editMode}
        editRecord={selectedItem}
      />

      <Modal
        title="Current Medication Details"
        open={currentMedicationView}
        footer={null}
        onCancel={handleCancel}
        closeIcon={
          <Button size="small" className="app-close-icon" shape="round">
            Close
          </Button>
        }
      >
        <LabelDisplay title={"Medicine Name"} description={selectedItem?.medicine_name}/>
        <LabelDisplay title={"Start Date"} description={selectedItem?.start_date && <FormatDate date={selectedItem?.start_date}/>}/>
        <LabelDisplay title={"End Date"} description={selectedItem?.start_date && <FormatDate date={selectedItem?.end_date}/>}/>
        <LabelDisplay title={"Notes"} description={selectedItem?.notes}/>
        <LabelDisplay title={"Added By"} description={selectedItem?.added_by}/>
        <LabelDisplay title={"Added Date"} description={<FormatDateTime date={selectedItem?.date_time} type="datetime"/>}/>
      </Modal>
    </div>
  );
}

export default CurrentMedications;
