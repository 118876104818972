import React, { useState, useEffect } from "react";
import {  Button, Card } from "antd";
import { doGet } from "../../../../API/apis";
import { CSVLink } from "react-csv";
import { NumberFormatter } from "../../../../utils/validator";
import ViewSuperbill from "../../PatientSummary/InvoiceAndBilling/Superbill/component/ViewSuperbill";
import Notification from "../../../../components/Notification/Notification";
import FormatDateTime from "../../../../components/FormatDate/FormatDateTime";
import CustomTable from "../../../../components/CustomTable/CustomTable";
import DateRangePickerCard from "../../../../components/GlobalComponents/DateRangePickerCard";


const transformedData = (records) => {
  if (!records || !Array.isArray(records)) {
    return [];
  }

  return records.map((record) => ({
    // ID: record?.id,
    "First Name": record?.patient_first_name,
    "Middle Name": record?.patient_middle_name,
  }));
};

const SuperbillReports = () => {
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState([]);
  const [viewRecord, setViewRecord] = useState([]);
  const [showSuperbill, setShowSuperbill] = useState(false);
  const [selectedDateRange, setSelectedDateRange] = useState(null);
  const [searchClicked, setSearchClicked] = useState(false);
 

  const coloumn = [
    {
      key: 2,
      title: "Superbill ID",
      dataIndex: "superbill_id",
      align: "center",
    },
    {
      key: 3,
      title: "Issue Date",
      dataIndex: "issue_date",
      align: "center",
      render: (text) => <FormatDateTime date={text} type="date"/>,
    },
    {
      key: 4,
      title: "Issue Time",
      dataIndex: "issue_date",
      align: "center",
      render: (text) => <FormatDateTime date={text} type="time"/>,
    },
    {
      key: 5,
      title: "Provider Name",
      dataIndex: "provider_info",
      align: "center",
      render: (text) =>
        text?.map((provider, index) => (
          <span key={index}>{provider?.provider_name}</span>
        )),
    },
    {
      key: 6,
      title: "Service",
      dataIndex: "super_data_details",
      render: (superDataDetails) =>
        superDataDetails?.map((data) => (
          <div key={data?.id}>{data?.service}</div>
        )),
    },
    {
      key: 7,
      title: "Fee",
      align: "center",
      dataIndex: "super_data_details",
      render: (superDataDetails) =>
        superDataDetails?.map((data) => (
          <div key={data?.id}>
            <NumberFormatter value={data?.fee} options={{ style: "decimal" }} />
          </div>
        )),
    },
    {
      key: 8,
      title: "Status",
      dataIndex: "status",
      align: "center",
      render:(text)=> 
        text ? <span className="bg-[#1890ff] px-1 py-[2px] text-white text-xs rounded-md">Sent</span>:
        <span className="bg-gray-400 px-1 py-[2px] text-white text-xs rounded-md">Not Sent</span> 
    },
    {
      key: 9,
      title: "Action",
      align: "center",
      render: (_, record) => (
        <Button type="link" onClick={() => handleView(record)}>
          {" "}
          View
        </Button>
      ),
    },
  ];

  const handleView = (record) => {
    setViewRecord(record);
    setShowSuperbill(true);
  };

  const closeSuperbill = () => {
    setShowSuperbill(null);
    setViewRecord(false);
  };

  const handleDateChange = (dates) => {
    setSelectedDateRange(dates);
  };

  const handleReset = ()=>{
    setData([])
  }

  const handleSearch = () => {
    if (selectedDateRange && selectedDateRange.length === 2) {
      setSearchClicked(true);
      getSuperBillReport();
    } else {
      Notification.warning("Please select a date range");
    }
  };

  const getSuperBillReport = async () => {
    setLoading(true);
    try {
      if (selectedDateRange && selectedDateRange.length === 2) {
        const startDate = selectedDateRange[0].format("YYYY-MM-DD");
        const endDate = selectedDateRange[1].format("YYYY-MM-DD");

        const response = await doGet(
          `/reports/superbill-data/?start_date=${startDate}&end_date=${endDate}`
        );
        if (response.status === 200) {
          setData(response.data);
        }
      } else {
        Notification.warning("Please select a valid date range");
      }
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (searchClicked) {
      getSuperBillReport();
      setSearchClicked(false);
    }
  }, [searchClicked, selectedDateRange]);

  return (
    <div>
      <h1 className="font-bold text-lg mb-2">Superbill Report </h1>
      <DateRangePickerCard
        onDateChange={handleDateChange}
        onSearch={handleSearch}
        onReset={handleReset}
      />

      <Card className="shadow-class">
        <div className="flex justify-between items-center mb-2">
          <h1>Search Results</h1>
          {data?.length > 0 && (
            <Button  type="primary">
              <CSVLink
                data={transformedData(data)}
                filename={"Outstanding-bill-report.csv"}
                className="csv-link"
              >
                Download Report
              </CSVLink>
            </Button>
          )}
        </div>
        <CustomTable
          loading={loading}
          columns={coloumn}
          dataSource={data}
          scroll={{
            x: 1500,
          }}
          key="superbill_report"
        />
      </Card>

      <ViewSuperbill
        open={showSuperbill}
        onClose={closeSuperbill}
        record={viewRecord}
        showPatient={true}
      />
    </div>
  );
};

export default SuperbillReports;
