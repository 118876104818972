import { PlusOutlined, EditOutlined, DeleteOutlined } from "@ant-design/icons";
import dayjs from "dayjs";
import React, { useEffect, useState } from "react";
import { Modal, Form, Input, Button, Radio, Row, Col } from "antd";
import {  useParams } from "react-router-dom";
import { doPost, doPatch, doDelete } from "../../../../../API/apis";
import { useStateGlobalContext } from "../../../../../contexts/GlobalContext";
import BaseDatePicker from "../../../../../components/BaseDatePicker/BaseDatePicker";
import MedicalCardsTitle from "../MedicalCardsTitle";
import Notification from "../../../../../components/Notification/Notification";
import showConfirmModal from "../../../../../components/ModalConfirm/ModalConfirm";
import FormatDateTime from "../../../../../components/FormatDate/FormatDateTime";
import FormatDate from "../../../../../components/FormatDate/FormatDate";

const Allergies = () => { 
  const { id } = useParams();
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false)
  const [visible, setVisible] = useState(false);
  const [editMode, setEditMode] = useState(false);
  const [selectedItem, setSelectedItem] = useState(null);
  const [hoveredItemId, setHoveredItemId] = useState(null);
  const { patientAllergies, getAllergies } = useStateGlobalContext();
  const [allergiesModal, setAllergiesModalVisible] = useState(false);

  useEffect(() => {
    getAllergies(id);
  }, [id]);

  const openModal = () => {
    setVisible(true)
    form.setFieldsValue({ type: "drug" });
  }

  const disabledDate = (current) => current && current > new Date();

  const handleCancel = () => {
    setTimeout(() => {
      setVisible(false);
      setEditMode(false);
      setAllergiesModalVisible(false);
      form.resetFields();
    }, 100); 
  };
  

  const handleItemClick = (record) => {
    setSelectedItem(record);
    setAllergiesModalVisible(true);
  };

  const handleEdit = (record) => {
    setEditMode(true);
    setSelectedItem(record);
    const typeField = Object.keys(record).find((key) => record[key] === true);
    form.setFieldsValue({
      start_date: record.start_date && dayjs(record.start_date).format("YYYY-MM-DD"),
      end_date: record.end_date && dayjs(record.end_date).format('YYYY-MM-DD'),
      type: typeField,
      allergy: record.allergy_value,
      description: record.description,
    });
    setVisible(true);
  };

  const handleDelete = (record) => {
   showConfirmModal({
      title: "Confirm Delete?",
      content: "Are you sure you want to delete this?",
      okText: "Delete",
      okType: "danger",
      cancelText: "Cancel",
      className:"custom-modal",
      onOk() {
        deleteAllergy(record);
      },
    });
  };

  const deleteAllergy = async (record) => {
    try {
      const response = await doDelete(`/patient/delete-allergies/${id}/${record}/`);
      if (response.status === 200) {
        Notification.success("Allergy Deleted Successfully");
        getAllergies(id);
      }
    } catch {
      Notification.error("Something went wrong");
    }
  };

  const handleAllergy = async (values) => {
    setLoading(true)
    const data = {
      drug: values.type === "drug",
      food: values.type === "food",
      environment: values.type === "environment",
      other: values.type === "other",
      start_date: values.start_date,
      end_date: values.end_date,
      allergy_value: values.allergy,
      description: values.description,
    };

    try {
      const response = editMode
        ? await doPatch(`/patient/update-allergies/${id}/${selectedItem?.id}/`, data)
        : await doPost(`/patient/add-allergies/${id}/`, data);

      if (response.status === 200 || response.status === 201) {
        const successMessage = editMode
          ? "Allergies Updated Successfully"
          : "Allergies Added Successfully";
        Notification.success(successMessage);
        handleCancel();
        getAllergies(id);
      }
    } catch {
      Notification.warning("Something Went Wrong");
    } finally{
      setLoading(false)
    }
  };

  const allergyList = [
    { type: "drug", allergies: patientAllergies?.filter((item) => item?.drug) },
    { type: "food", allergies: patientAllergies?.filter((item) => item?.food) },
    { type: "environment", allergies: patientAllergies?.filter((item) => item?.environment) },
    { type: "other", allergies: patientAllergies?.filter((item) => item?.other) },
  ];

  return (
    <div>
      <div className="shadow-class p-4 border rounded-md h-[180px] max-h-[180px]">
        <div className="flex items-center justify-between">
          <MedicalCardsTitle title="Allergies" />
          <Button type="primary" size="small" shape="round" onClick={openModal}>
            Add <PlusOutlined />
          </Button>
        </div>
        <div className="flex flex-col max-h-[130px] overflow-auto mt-1">
          {allergyList.map(({ type, allergies }) => (
            <div key={type}>
              <p className="font-medium">{`${type.charAt(0).toUpperCase() + type.slice(1)} Allergies:`}</p>
              <ul>
                {allergies?.map((allergy) => (
                  <li
                    key={allergy?.id}
                    className="text-[#1890ff] cursor-pointer flex items-center"
                    onMouseEnter={() => setHoveredItemId(allergy?.id)}
                    onMouseLeave={() => setHoveredItemId(null)}
                  >
                    <span onClick={() => handleItemClick(allergy)}>{allergy?.allergy_value}</span>
                    {hoveredItemId === allergy?.id && (
                      <div className="flex items-center ml-3">
                        <EditOutlined className="text-[#1890ff] mr-2" onClick={() => handleEdit(allergy)} />
                        <DeleteOutlined className="text-[#1890ff]" onClick={() => handleDelete(allergy?.id)} />
                      </div>
                    )}
                  </li>
                ))}
              </ul>
            </div>
          ))}
        </div>
      </div>

      <Modal
        title={editMode ? "Update Allergy and Reaction" : "Add Allergy and Reaction"}
        maskClosable={false}
        open={visible}
        onCancel={handleCancel}
        footer={null}
        width={450}
        centered
        closeIcon={
          <Button size="small" className="app-close-icon" shape="round">
            Close
          </Button>
        }
      >
        <Form layout="vertical" form={form} onFinish={handleAllergy}>
          <Form.Item name="type" initialValues={{ type: "drug" }}>
            <Radio.Group defaultValue="drug" buttonStyle="solid">
              <Radio.Button value="drug">Drug</Radio.Button>
              <Radio.Button value="food">Food</Radio.Button>
              <Radio.Button value="environment">Environment</Radio.Button>
              <Radio.Button value="other">Other</Radio.Button>
            </Radio.Group>
          </Form.Item>
          <Row gutter={12} className="mt-5">
            <Col span={12}>
              <Form.Item name="start_date" label="Start Date">
                <BaseDatePicker  disabledDate={disabledDate} />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item name="end_date" label="End Date">
                <BaseDatePicker  disabledDate={disabledDate} />
              </Form.Item>
            </Col>
          </Row>
          <Form.Item name="allergy" label="Allergy">
            <Input maxLength={30} placeholder="Enter Name of Allergy" />
          </Form.Item>
          <Form.Item name="description" label="Reaction">
            <Input.TextArea placeholder="Enter Reaction to Above Allergy" maxLength={300} showCount />
          </Form.Item>
          <div className="flex justify-end">
            <Button onClick={handleCancel}>Cancel</Button>
            <Button type="primary" htmlType="submit" className="ml-2" loading={loading}>
              {editMode ? "Update" : "Save"}
            </Button>
          </div>
        </Form>
      </Modal>

      <Modal
        title="Allergy And Reaction"
        open={allergiesModal}
        onCancel={handleCancel}
        maskClosable={false}
        footer={null}
        width={450}
        closeIcon={
          <Button size="small" className="app-close-icon" shape="round">
            Close
          </Button>
        }
      >
        {selectedItem && (
          <>
            <p>
              <span className="font-medium">Start Date: </span>
              <span>
                {selectedItem?.start_date ? <FormatDate date={selectedItem?.start_date}/> : "No Date Entered"}
              </span>
            </p>
            <p>
              <span className="font-medium">End Date:</span>
              <span className="ml-2">
                {selectedItem?.end_date ? <FormatDate date={selectedItem?.end_date}/> : "No Date Entered"}
              </span>
            </p>
            <p>
              <span className="font-medium">Allergy Name:</span>
              <span className="ml-2">{selectedItem?.allergy_value}</span>
            </p>
            <p>
              <span className="font-medium">Reaction:</span>
              <span className="ml-2">{selectedItem?.description}</span>
            </p>
            <p>
              <span className="font-medium">Added By:</span>
              <span className="ml-2">{selectedItem?.added_by}</span>
            </p>
            <p>
              <span className="font-medium">Date:</span>
              <span className="ml-2">
                <FormatDateTime date={selectedItem?.date_time} type="datetime"/>
              </span>
            </p>
          </>
        )}
      </Modal>
    </div>
  );
};

export default Allergies;
