import { PlusOutlined, EditOutlined, DeleteOutlined } from "@ant-design/icons";
import React, { useEffect, useState } from "react";
import { Modal, Form, Input, Button, Spin } from "antd";
import { doPost, doGet, doPatch, doDelete } from "../../../../../API/apis";
import { useParams } from "react-router-dom";
import MedicalCardsTitle from "../MedicalCardsTitle";
import Notification from "../../../../../components/Notification/Notification";
import showConfirmModal from "../../../../../components/ModalConfirm/ModalConfirm";
import FormatDateTime from "../../../../../components/FormatDate/FormatDateTime";
import BaseDatePicker from "../../../../../components/BaseDatePicker/BaseDatePicker";
import FormatDate from "../../../../../components/FormatDate/FormatDate";

const PMH = () => {
  const { id } = useParams();
  const [form] = Form.useForm();
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [visible, setVisible] = useState(false);
  const [editMode, setEditMode] = useState(null);
  const [selectedItem, setSelectedItem] = useState(null);
  const [pmhModalVisible, setPMHModalVisible] = useState(false);
  const [hoveredItemId, setHoveredItemId] = useState(null);

  const openModal = () => {
    setVisible(true);
  };

  const disabledDate = (current) => {
    return current && current > new Date();
  };

  const handleCancel = () => {
    setVisible(false);
    setPMHModalVisible(false);
    setEditMode(false);
    form.resetFields();
  };

  const handleItemClick = (record) => {
    setSelectedItem(record);
    setPMHModalVisible(true);
  };

  const handleEdit = (record) => {
    setSelectedItem(record);
    setEditMode(true);
    form.setFieldsValue({
      pmh: record.past_medical_history,
      medical_history_date: record?.medical_history_date,
    });
    setVisible(true);
  };

  const handlePMH = async (values) => {
    setLoading(true);
    const data = {
      past_medical_history: values.pmh,
      medical_history_date: values?.medical_history_date,
    };
    try {
      const response = editMode
        ? await doPatch(`/patient/update-past-medical-history/${id}/${selectedItem?.id}/`, data)
        : await doPost(`/patient/add-past-medical-history/${id}/`, data);
      
      if ([200, 201].includes(response?.status)) {
        Notification.success(
          editMode ? "Past medical history updated successfully" : "Past medical history added successfully"
        );
        getPMH()
        handleCancel()
      } else {
        Notification.error("Something went wrong");
      }
    } catch (error) {
      console.error("Error updating medical history:", error);
      Notification.error("Something went wrong");
    } finally {
      setLoading(false);
    }
  };

  const handleDelete = (record) => {
    showConfirmModal({
      title: "Confirm Delete?",
      icon: null,
      content: "Are you sure you want to delete this?",
      okText: "Delete",
      okType: "danger",
      cancelText: "Cancel",
      className: "custom-modal",
      onOk() {
        deletePastMedicalHistory(record);
      },
    });
  };

  const deletePastMedicalHistory = async (record) => {
    try {
      const response = await doDelete(
        `/patient/delete-past-medical-history/${id}/${record}/`
      );
      if (response.status === 200) {
        Notification.success("Past medical history deleted successfully");
        getPMH();
      } else {
        Notification.error("Something went wrong");
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  const getPMH = async () => {
    setLoading(true);
    try {
      const response = await doGet(`/patient/add-past-medical-history/${id}/`);
      if (response.status === 200) {
        setData(response.data.reverse());
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    getPMH();
  }, []);

  return (
    <div>
      <div className="shadow-class p-4 border rounded-md h-[180px] min-h-[100px] max-h-[180px]">
        <div className="flex items-center justify-between">
          <MedicalCardsTitle title="Past Medical History" />
          <Button type="primary" size="small" shape="round" onClick={openModal}>
            Add <PlusOutlined />
          </Button>
        </div>
        {loading ? (
          <div className="flex justify-center items-center">
            <Spin size="small" />
          </div>
        ) : (
          <div className="flex max-h-[130px]  overflow-auto mt-1">
            <ul className="list-decimal">
              {data?.map((item, index) => (
                <li
                  key={item.id}
                  className="text-[#1890ff] cursor-pointer flex justify-between items-center"
                  onMouseEnter={() => setHoveredItemId(item.id)}
                  onMouseLeave={() => setHoveredItemId(null)}
                >
                  <span
                    onClick={() => {
                      handleItemClick(item);
                    }}
                    title={item?.past_medical_history}
                  >
                    {index + 1}. {item?.past_medical_history?.slice(0, 26)}
                    {item?.past_medical_history?.length > 26 ? "..." : ""}
                  </span>
                  {hoveredItemId === item.id && (
                    <div className="flex items-center ml-3">
                      <EditOutlined
                        className="text-[#1890ff] mr-2"
                        onClick={() => {
                          handleEdit(item);
                        }}
                      />
                      <DeleteOutlined
                        className="text-[#1890ff]"
                        onClick={() => {
                          handleDelete(item.id);
                        }}
                      />
                    </div>
                  )}
                </li>
              ))}
              {data.length === 0 && (
                <li className="flex justify-between items-center">
                  <span>No past medical history</span>
                </li>
              )}
            </ul>
          </div>
        )}
      </div>

      <Modal
        title="Past Medical History"
        open={visible}
        onCancel={handleCancel}
        closeIcon={
          <Button size="small" className="app-close-icon" shape="round">
            Close
          </Button>
        }
        footer={null}
        width={650}
        maskClosable={false}
      >
        <Form
          layout="vertical"
          name="past-medical-history"
          form={form}
          onFinish={handlePMH}
        >
          <Form.Item name="medical_history_date" label="Date">
            <BaseDatePicker disabledDate={disabledDate} />
          </Form.Item>
          <Form.Item name="pmh" label="Past Medical History" rules={[{
            required: true,
            message:"Past Medical History is Required"
          }]}>
            <Input.TextArea rows={2} maxLength={500} showCount />
          </Form.Item>
          <div className="flex justify-end">
            <Button onClick={handleCancel}>Cancel</Button>
            <Button type="primary" htmlType="submit" className="ml-2" loading={loading}>
              {editMode ? "Update" : "Save"}
            </Button>
          </div>
        </Form>
      </Modal>

      <Modal
        title="Past Medical History"
        open={pmhModalVisible}
        closeIcon={
          <Button size="small" className="app-close-icon" shape="round">
            Close
          </Button>
        }
        onCancel={handleCancel}
        footer={null}
        width={650}
      >
        {selectedItem && (
          <>
            <p>
              <span className="font-medium">Past Medical History Date:</span>
              <span className="ml-2"><FormatDate date={selectedItem?.medical_history_date}/></span>
            </p>
            <p>
              <span className="font-medium">Description:</span>
              <span className="ml-2">{selectedItem?.past_medical_history}</span>
            </p>
            <p>
              <span className="font-medium">Added By:</span>
              <span className="ml-2">{selectedItem?.added_by}</span>
            </p>
            <p>
              <span className="font-medium">Date:</span>
              <span className="ml-2">
                <FormatDateTime
                  date={selectedItem?.date_time}
                  type="datetime"
                />
              </span>
            </p>
          </>
        )}
      </Modal>
    </div>
  );
};

export default PMH;
