import { Dropdown, Table, Modal, Tooltip, Typography, Button } from "antd";
import { doPatch, doDelete } from "../../../../API/apis";
import { useEffect, useState } from "react";
import { EllipsisOutlined } from "@ant-design/icons";
import { ageCalculator } from "../../../../utils/ageCalculator";
import TimeClock from "../../../../components/TimeClock/TimeClock";
import { useStateGlobalContext } from "../../../../contexts/GlobalContext";
import Notification from "../../../../components/Notification/Notification";
import FormatDateTime from "../../../../components/FormatDate/FormatDateTime";
import showConfirmModal from "../../../../components/ModalConfirm/ModalConfirm";
import { useNavigate } from "react-router-dom";
import FormatDate from "../../../../components/FormatDate/FormatDate";
import { AppointmentStatus } from "../../../../components/GlobalComponents/StatusLabels";
import { todayAppointmentActions } from "../../../../utils/Data";


function AppointmentList() {
  const navigate = useNavigate()
  const [loading, setLoading] = useState(true);
  const { providers, appointments, getAppointments } = useStateGlobalContext();


  const coloumn = [
    {
      key: "#",
      title: "#",
      width: "5%",
      align: "center",
      render: (_, __, index) => index + 1,
    },
    {
      key: 1,
      title: "Time",
      align: "center",
      dataIndex: "appointment_date",
      render: (text, record) => (
        <Tooltip title={`${record?.duration} minutes`}>
          <span>{text ? <FormatDateTime date={text} type="time" /> : ""}</span>
        </Tooltip>
      ),
    },
    {
      key: 2,
      title: "Full Name",
      ellipsis: true,
      align: "center",
      dataIndex: "first_name",
      render: (text, record) => {

        if (record?.patient_id) {
          return (
            <Button
              type="link"
              onClick={() => {
                navigate(`/main/charts/patient/${record?.patient_id}/`);
              }}
              className="text-[#1890ff]"
              title={`${record?.patient_MRN}`}
            >
              {record?.first_name} {record?.last_name}
            </Button>
          );
        } else {
          return (
            <span title={`${record?.patient_MRN}`}>
              {record?.first_name} {record?.last_name}
            </span>
          );
        }
      },
    },
    {
      key: 4,
      title: "Age",
      align: "center",
      ellipsis: true,
      dataIndex: "dob",
      width: "5%",
      render: (text) =>
        text ? (
          <Tooltip title={<FormatDate date={text} type="time" />}>
          <span>{ageCalculator(text)} y/o</span>
        </Tooltip>
        ) : (
          ""
        ),
    },
    {
      key: 5,
      title: "Gender",
      align: "center",
      ellipsis: true,
      dataIndex: "gender",
      width: "5%",
    },
    {
      key: 6,
      title: "Reason for Visit",
      align: "center",
      ellipsis: true,
      dataIndex: "visit_reason",
      width: "20%",
      render: (text) => (
        <Tooltip title="Click to copy Reason For Visit">
          <Typography.Text
            className="cursor-pointer text-[#1890ff]"
            onClick={() => {
              navigator.clipboard.writeText(text);
              Notification.success("Reason For Visit copied to clipboard");
            }}
          >
            {text}
          </Typography.Text>
        </Tooltip>
      ),
    },
    {
      key: 7,
      title: "Provider",
      align: "center",
      ellipsis: true,
      dataIndex: "appointment_with",
      filters: providers?.map((provider) => ({
        text: provider.name,
        value: `${provider.name}`,
      })),
      onFilter: (value, record) => record.appointment_with === value,
    },
    {
      key: 8,
      title: "Service Type",
      align: "center",
      ellipsis: true,
      dataIndex: "service",
    },
    {
      key: 9,
      title: "Contact",
      align: "center",
      ellipsis: true,
      dataIndex: "contact",
    },
    {
      key: 10,
      title: "Status",
      align: "center",
      ellipsis: true,
      dataIndex: "current_status",
      render: (status) => <AppointmentStatus status={status}/>
    },
    {
      title: "Action",
      align: "center",
      render: (text, record) => (
        <Dropdown
          menu={{
            items: todayAppointmentActions.map((item) => ({
              ...item,
              onClick: () => {
                if (
                  item.key === 1 ||
                  item.key === 2 ||
                  item.key === 3 ||
                  item.key === 4 ||
                  item.key === 5 ||
                  item.key === 6 ||
                  item.key === 8
                ) {
                  handleStatus(record, item.key);
                } else if (item.key === 7) {
                  handleDelete(record?.id);
                } else {
                  console.log("Hello");
                }
              },
            })),
          }}
          trigger={["click"]}
        >
          <EllipsisOutlined className="cursor-pointer" />
        </Dropdown>
      ),
    },
  ];

  const handleStatus = async (record, key) => {
    const statusMap = {
      1: "Scheduled",
      8: "Confirmed",
      2: "Arrived",
      3: "Rescheduled",
      4: "Late Arrival",
      5: "Cancelled",
    };
  
    const data = { current_status: statusMap[key] || "No Show" };
  
    try {
      const response = await doPatch(`/appointments/update/${record.id}/`, data);
      if (response.status === 200) {
        Notification.success("Status updated successfully");
        getAppointments();
      } else {
        Notification.error("Failed to update status");
      }
    } catch (error) {
      console.error("Error updating status:", error);
      Notification.error("An error occurred while updating status");
    }
  };
  

  const handleDelete = (record) => {
    showConfirmModal({
      title: "Confirm Delete?",
      icon: null,
      content: "Are you sure you want to delete this?",
      okText: "Delete",
      okType: "danger",
      cancelText: "Cancel",
      className: "custom-modal",
      onOk() {
        deleteAppointment(record);
      },
    });
  };

  const deleteAppointment = async (record) => {
    try {
      const response = await doDelete(`/appointments/delete/${record}/`);
      if (response.status === 200) {
        Notification.success("Appointment deleted successfully");
        getAppointments();
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      try {
        await Promise.all([getAppointments()]);
      } catch (error) {
        Notification.error("Failed to fetch data");
      } finally {
        setLoading(false);
      }
    };
    fetchData();
  }, []);

  const sortedAppointments = appointments?.appointments_today
    ?.slice()
    .sort(
      (a, b) => new Date(a.appointment_date) - new Date(b.appointment_date)
    );

  return (
    <>
      <TimeClock />
      <Table
        columns={coloumn}
        dataSource={sortedAppointments}
        loading={loading}
        size="small"
        className="mt-4"
      />
    </>
  );
}

export default AppointmentList;
