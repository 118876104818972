import { Modal, Button, Form, Input, Row, Col, Select } from "antd";
import React, { useState, useEffect } from "react";
import { doPatch, doPost } from "../../../../API/apis";
import { useStateGlobalContext } from "../../../../contexts/GlobalContext";
import Notification from "../../../../components/Notification/Notification";
import SelectProvider from "../../../../components/SelectProvider/SelectProvider";

const SUCCESS_MESSAGES = {
  update: "Task Updated Successfully",
  add: "Task Created Successfully",
};

const TasksModal = ({
  visible,
  onClose,
  edit,
  check,
  selectedItem,
  taskActions,
}) => {
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(true);
  const { getTasks } = useStateGlobalContext();

  const handleCancel = () => {
    setTimeout(() => {
      form.resetFields();
    }, 500);

    onClose();
  };

  useEffect(() => {
    if (edit) {
      form.setFieldsValue({
        detail: selectedItem?.detail,
        assigned_to: selectedItem?.assigned_to,
        status: selectedItem?.status,
      });
    }
  }, [edit]);

  const handleTasks = async (values) => {
    const { detail } = values;
    const data = {
      detail,
      assigned_to: values.assigned_to,
      status: values.status,
    };
    setLoading(true);
    try {
      const response = edit
        ? await doPatch(`/tasks/update/${selectedItem?.id}`, data)
        : await doPost("/tasks/add/", data);
      if (response.status === 201 || response.status === 200) {
        Notification.success(
          edit ? SUCCESS_MESSAGES.update : SUCCESS_MESSAGES.add
        );
        getTasks(check);
        handleCancel();
      }
    } catch (error) {
      Notification.error("Something Went Wrong");
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      await getTasks(check);
      setLoading(false);
    };

    fetchData();
  }, []);

  return (
    <Modal
      title={edit ? "Update Task" : "New Task"}
      open={visible}
      footer={null}
      maskClosable={false}
      closeIcon={
        <Button size="small" className="app-close-icon" shape="round">
          Close
        </Button>
      }
      onCancel={handleCancel}
    >
      <Form layout="vertical" form={form} onFinish={handleTasks}>
        <Form.Item
          name="detail"
          label="Details"
          rules={[{ required: true, message: "Please Enter task Details" }]}
        >
          <Input.TextArea
            placeholder="Task Details"
            showCount
            maxLength={300}
          />
        </Form.Item>
        <Row gutter={[16, 16]}>
          <Col span={12}>
            <Form.Item
              name="assigned_to"
              label="Assigned To"
              rules={[
                { required: true, message: "Please Select any Provider" },
              ]}
            >
              <SelectProvider />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item label="Status" name="status">
              <Select placeholder="Select Task Action" defaultValue="To Do">
                {taskActions
                  ?.filter((action) => action.label !== "Edit") // Filter out the "Edit" action
                  .map((action) => (
                    <Select.Option key={action.key} value={action.label}>
                      {action.label}
                    </Select.Option>
                  ))}
              </Select>
            </Form.Item>
          </Col>
        </Row>
        <div className="flex justify-end">
          <Button className="mr-2" onClick={handleCancel}>
            Cancel
          </Button>
          <Button loading={loading} type="primary" htmlType="submit">
            {edit ? "Update" : "Assign"}
          </Button>
        </div>
      </Form>
    </Modal>
  );
};

export default TasksModal;
