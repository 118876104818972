import { Drawer } from 'antd'
import React from 'react'

const PrintDrawer = ({visible, onClose, children}) => {
  return (
    <Drawer
      title="Kashif"
      placement="bottom"
      height="100vh"
      footer={null}
      open={visible}
      onClose={onClose}
      headerStyle={{
        position: "fixed",
        backgroundColor: "white",
        zIndex:"999",
        width: "100%",
        top: 0,
        boxShadow: "0px 0px 5px 0px rgba(0,0,0,0.25)",
      }}
      className="prescription-drawer"
    >
        {children}
    </Drawer>
  )
}

export default PrintDrawer
