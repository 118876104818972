import React, { useEffect, useState } from "react";
import {  Button,  Form, Card } from "antd";
import { doGet } from "../../../../API/apis";
import { CSVLink } from "react-csv";
import {
  formatDate,
  formatTime,
} from "../../../../utils/DateFormat";
import { useOutletContext } from "react-router-dom";
import Notification from "../../../../components/Notification/Notification";
import FormatDateTime from "../../../../components/FormatDate/FormatDateTime";
import CustomTable from "../../../../components/CustomTable/CustomTable";
import DateRangePickerCard from "../../../../components/GlobalComponents/DateRangePickerCard";


const AppointmentReports = () => {
  const [form] = Form.useForm()
  const [loading, setLoading] = useState(false);
  const [appointments, setAppointments] = useState(null);
  const [selectedDateRange, setSelectedDateRange] = useState(null);
  const [searchClicked, setSearchClicked] = useState(false);
  const [officeDateFormat, officeTimeFormat, officeTimezone] =
    useOutletContext();

  const transformAppointmentsForCSV = (appointments) => {
    if (!appointments) {
      return [];
    }

    return appointments.map((appointment) => ({
      "Patient MRN": appointment?.patient_MRN,
      "Patient Name": `${appointment?.first_name} ${appointment?.last_name}`,
      Date: formatDate(
        appointment.appointment_date,
        officeDateFormat,
        officeTimezone
      ),
      Time: formatTime(
        appointment.appointment_date,
        officeTimeFormat,
        officeTimezone
      ),
      Duration: `${appointment.duration} minutes`,
      Service: appointment.service,
      "Appointment With": appointment.appointment_with,
      "Visit Reason": appointment.visit_reason,
      Status: appointment.current_status,
    }));
  };

  const coloumn = [

    {
      key: 1,
      title: "Patient Name",
      dataIndex: "patient_name",
      render: (_, record) => (
        <p>
          {record?.first_name} {record?.last_name}
        </p>
      ),
    },
    {
      key: 2,
      title: "Date",
      align: "center",
      dataIndex: "appointment_date",
      render: (text) => (
        <FormatDateTime date={text} type="date"/>
      ),
    },
    {
      key: "time",
      title: "Time",
      align: "center",
      dataIndex: "appointment_date",
      render: (text) => <FormatDateTime date={text} type="time"/>,
    },
    {
      key: 3,
      title: "Duration",
      align: "center",
      dataIndex: "duration",
      render: (text) => <span>{text} minutes</span>,
    },
    {
      key: 4,
      title: "Service",
      align: "center",
      dataIndex: "service",
    },
    {
      key: 5,
      title: "Appointment With",
      align: "center",
      dataIndex: "appointment_with",
    },
    {
      key: 7,
      title: "Visit Reason",
      align: "center",
      dataIndex: "visit_reason",
    },
    {
      key: 8,
      title: "Status",
      align: "center",
      ellipsis: true,
      dataIndex: "current_status",
      render: (text) => {
        let bgColor = "";
        let textColor = "";
        switch (text) {
          case "Scheduled":
            bgColor = "bg-blue-200";
            textColor = "text-blue-500";
            break;
          case "Confirmed":
            bgColor = "bg-green-200";
            textColor = "text-green-500";
            break;
          case "Rescheduled":
            bgColor = "bg-blue-200";
            textColor = "text-blue-500";
            break;
          case "Late Arrival":
            bgColor = "bg-yellow-200";
            textColor = "text-yellow-500";
            break;
          case "No Show":
            bgColor = "bg-red-200";
            textColor = "text-red-500";
            break;
          case "Cancelled":
            bgColor = "bg-red-200";
            textColor = "text-red-500";
            break;
          default:
            bgColor = "bg-green-700";
            textColor = "text-white";
            break;
        }
        return (
          <span
            className={`${bgColor} px-3 py-1 ${textColor} font-semibold text-xs rounded-md`}
          >
            {text}
          </span>
        );
      },
    },
  ];

  const handleDateChange = (dates) => {
    setSelectedDateRange(dates);
  };

  const handleSearch = () => {
    if (selectedDateRange && selectedDateRange.length === 2) {
      setSearchClicked(true);
      getAppointments();
    } else {
      Notification.warning("Please select a date range");
    }
  };

  const getAppointments = async () => {
    setLoading(true);
    try {
      if (selectedDateRange && selectedDateRange.length === 2) {
        const startDate = selectedDateRange[0].format("YYYY-MM-DD");
        const endDate = selectedDateRange[1].format("YYYY-MM-DD");

        const response = await doGet(
          `/reports/appointments/?start_date=${startDate}&end_date=${endDate}`
        );
        if (response.status === 200) {
          setAppointments(response?.data);
        }
      }
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  const handleReset=()=>{
    setAppointments([])
    form.resetFields()
  }

  useEffect(() => {
    if (searchClicked) {
      getAppointments();
      setSearchClicked(false);
    }
  }, [searchClicked]);

  return (
    <div>
      <h1 className="font-bold text-lg mb-2">Appointments Report </h1>
      <DateRangePickerCard
        onDateChange={handleDateChange}
        onSearch={handleSearch}
        onReset={handleReset}
      />

      <Card className="shadow-class">
        <div className="flex justify-between items-center mb-2">
          <h1>Search Results</h1>
          {appointments?.length > 0 && (
            <Button className="mr-2" type="primary">
              <CSVLink
                data={transformAppointmentsForCSV(appointments)}
                filename={"Appointment_reports.csv"}
                className="csv-link"
              >
                Download Report
              </CSVLink>
            </Button>
          )}
        </div>
        <CustomTable
          loading={loading}
          columns={coloumn}
          dataSource={appointments}
          key="appointments_report"
        />
      </Card>
    </div>
  );
};

export default AppointmentReports;
