import React, { useEffect, useState } from "react";
import { Form, Button, Modal, Select, Table, Dropdown, Card, Drawer } from "antd";
import { EllipsisOutlined } from "@ant-design/icons";
import { doDelete, doGet, doPatch, doPost } from "../../../../API/apis";
import ReactQuill from "react-quill";
import { useParams } from "react-router-dom";
import ViewEncounter from "./ViewEncounter";
import VisitPad from "./components/VisitPad";
import { useStateGlobalContext } from "../../../../contexts/GlobalContext";
import Notification from "../../../../components/Notification/Notification";
import showConfirmModal from "../../../../components/ModalConfirm/ModalConfirm";
import FormatDateTime from "../../../../components/FormatDate/FormatDateTime";
import { modules } from "../../../../components/GlobalComponents/GlobalComponents";
import NotesHeader from "../GeneralNotes/NotesHeader";
import CustomTable from "../../../../components/CustomTable/CustomTable";
import { encounter_Status, visits_actions } from "../../../../utils/Data";
import { InvoiceStatus } from "../../../../components/GlobalComponents/StatusLabels";


const { Option } = Select;


const Visits = () => {
  const { id } = useParams();
  const [form] = Form.useForm();
  const [form1] = Form.useForm();
  const [edit, setEdit] = useState(false);
  const [title, setTitle] = useState(null);
  const [loading, setLoading] = useState(false);
  const [selectedItem, setSelectedItem] = useState(null);
  const [viewEncounter, setViewEncounter] = useState(false);
  const [entcounterData, setEncounterData] = useState(null);
  const [encountersData, setEncountersData] = useState(null);
  const [waitingRoomData, setWaitingRoomData] = useState(null);
  const [draftLoading, setDraftLoading] = useState(false)
  const { profile, submitLoading, setSubmitLoading, templates, getTemplates } = useStateGlobalContext();

  const role = profile && profile?.user_info[0]?.role;

  const [print, setPrint] = useState({
    show: false,
    data: null,
  });


  const [previousData, setPreviousData] = useState({
    serviceName: "",
    reasonForVisit: "",
    noteType: "",
    template: "",
  });
  const [encounter, setEncounter] = useState(false);
  const [visible, setVisible] = useState(false);

  const coloumn = [
    {
      key: 1,
      title: "Service Name",
      dataIndex: "service_name",
      render:(text, record)=>(
        <span className='cursor-pointer' onClick={()=> handleView(record)}>{text}</span>
      )
    },
    {
      key: 2,
      title: "Note Type",
      dataIndex: "note_type",
      render:(text, record)=>(
        <span className="text-[#1890ff] cursor-pointer" onClick={()=> handleView(record)}>
        {text}
      </span>
      )
    },
    {
      key: 3,
      title: "Reason for Visit",
      dataIndex: "reason_of_visit",
    },
    {
      key: 4,
      title: "Provider",
      dataIndex: "provider_name",
    },
    {
      key: 5,
      title: "Date",
      align: "center",
      dataIndex: "encounter_start_date",
      render: (text) => (
          <FormatDateTime date={text} type="date"/>
      ),
    },
    {
      key: "time",
      align: "center",
      title: "Time",
      dataIndex: "encounter_start_date",
      render: (text) => (
        <FormatDateTime date={text} type="time"/>
      ),
    },
    {
      title: "Status",
      dataIndex: "status",
      key: 6,
      align: "center",
      filters: encounter_Status.map((text) => ({
        text: text.label,
        value: text.label,
      })),
      onFilter: (value, record) =>
        record?.status?.toLowerCase().includes(value.toLowerCase()),
      defaultFilteredValue: ["Draft", "Signed"], // Set default filters
      render: (status) =>
        <InvoiceStatus status={status}/>
    },
    {
      key: 7,
      title: "Action",
      // fixed: "right",
      align: "center",
      width: "8%",
      render: (text, record) => {
        const { status } = record;
        let availableItems;
        if(status === "Cancelled"){
          availableItems = visits_actions.filter((item) => ![2,5, 6].includes(item.key));
        }
        else if (role === "nurse") {
          availableItems =
            status === "Draft"
              ? visits_actions.filter((item) => [1, 2, 5].includes(item.key))
              : visits_actions.filter((item) => [1, 3].includes(item.key));
        } else {
          availableItems =
            status === "Draft"
              ? visits_actions.filter((item) => [1, 2, 5].includes(item.key))
              : visits_actions.filter((item) => [1, 3, 6].includes(item.key));
        }

        return (
          <Dropdown
            menu={{
              items: availableItems.map((item) => ({
                ...item,
                onClick: () => {
                  if (item.key === 3) {
                    handlePrint(record);
                  } else if (item.key === 1) {
                    handleView(record);
                  } else if (item.key === 2) {
                    handleEdit(record, true);
                  } else if (item.key === 6) {
                    handleCancel(record);
                  } else if (item.key === 5) {
                    handleDelete(record?.id);
                  }
                },
              })),
            }}
            trigger={["click"]}
          >
            <EllipsisOutlined className="cursor-pointer" />
          </Dropdown>
        );
      },
    },
  ];



  const openModal = () => {
    setVisible(true);
  };

  const handlePrint = (record) => {
    setPrint({
      show: true,
      data: record,
    });
  };

  const hidePrint = () => {
    setPrint({
      show: false,
      data: null,
    });
  };

  const cancelVisit = async (record) => {
    try {
      await doPatch(`/visits/update/${id}/${record?.id}/`, { status: "Cancelled" });
      Notification.success("Visit cancelled successfully.");
      getEncounters()
    } catch (error) {
      Notification.error("Failed to cancel visit.");
    }
  };

  const handleCancel = (record) => {
    showConfirmModal({
      title: "Confirm Cancel?",
      content: "Are you sure you want to cancel this encounter?",
      okText: "Cancel Encounter",
      okType: "danger",
      cancelText: "Keep",
      className: "custom-modal",
      onOk: () => cancelVisit(record),
    });
  };

  const handleDelete = (record) => {
    showConfirmModal({
      title: "Confirm Delete?",
      content: "Are you sure you want to delete this?",
      okText: "Delete Draft",
      okType: "danger",
      cancelText: "Keep",
      className: "custom-modal",
      onOk: () => deleteVisit(record),
    });
  };

  const deleteVisit = async (record) => {
    try {
      const response = await doDelete(`/visits/delete/${id}/${record}/`);
      if (response.status === 200) {
        Notification.success("Encounter Deleted Successfully");
        getEncounters(id);
      }
    } catch (error) {
      Notification.error("Something went wrong");
    }
  };

  const handleView = (record) => {
    setEncounterData(record);
    setViewEncounter(true);
  };

  const onCancel = () => {
    if (!edit) {
      showConfirmModal({
        title: "Confirm Close?",
        icon: null,
        content: "Are you sure? Current Data will be Lost",
        okText: "Clear",
        okType: "danger",
        cancelText: "Cancel",
        className: "custom-modal",
        onOk() {
          setVisible(false);
          setEncounter(false);

          setTimeout(() => {
            setEdit(false);
            setSelectedItem(null);
            form.resetFields();
            form1.resetFields();
          }, 100);
        },
      });
    } else {
      setVisible(false);
      setEncounter(false);

      setTimeout(() => {
        setEdit(false);
        setSelectedItem(null);
        form.resetFields();
        form1.resetFields();
      }, 100);
    }
  };

  const closeSelectEncounter = () => {
    setVisible(false);
    setEncounter(false);

    setTimeout(() => {
      setSelectedItem(null);
      form.resetFields();
    }, 100);
  };

  const closeVisit = () => {
    setVisible(false);
    setEncounter(false);

    setTimeout(() => {
      setEdit(false);
      setSelectedItem(null);
      form.resetFields();
      form1.resetFields();
    }, 200);
  };

  const getReasonForVisit = async () => {
    try {
      const response = await doGet(`/visits/get/${id}/`);
      if (response.status === 200) {
        const sortedWaitingRoom = response.data.sort((a, b) => {
          return new Date(b?.checkin_time) - new Date(a?.checkin_time);
        });
        setWaitingRoomData(sortedWaitingRoom)
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handleEncounter = (values) => {
    const selectedTemplate = templates?.find(
      (item) => item.title === values.template
    );
    const selectedReasonForVisit = waitingRoomData?.find(
      (item) => item?.reason_of_visit === values?.reason_for_visit
    );

    setPreviousData({
      serviceName: selectedReasonForVisit?.service_name || "",
      reasonForVisit: values.reason_for_visit,
      noteType: selectedTemplate?.title,
      template: selectedTemplate?.template,
    });

    setEncounter(true);
    setVisible(false);
    setTitle(selectedTemplate?.title);
    setTimeout(() => {
      form1.setFieldsValue({
        encounter: selectedTemplate?.template,
      });
    }, 0);
    form.resetFields();
  };

  const handleEdit = (record, isEdit) => {
    setSelectedItem(record);
    setEdit(isEdit);
    setEncounter(true);

    // Ensure the modal is fully rendered before setting form values:
    setTimeout(() => {
      form1.setFieldsValue({
        encounter: record?.encounter_details,
        notes: record?.notes,
      });
    }, 0);
  }; 


  const handleDraftVisitEncounter = async () => {
    setDraftLoading(true)
    let data = {};
    edit
      ? (data = { encounter_details: form1.getFieldValue("encounter")})
      : (data = {
          service_name: previousData?.serviceName,
          reason_of_visit: previousData?.reasonForVisit,
          note_type: previousData?.noteType,
          encounter_details: form1.getFieldValue("encounter"),
          status: "Draft",
        });
    try {
      const response = edit
        ? await doPatch(`/visits/update/${id}/${selectedItem?.id}/`, data)
        : await doPost(`/visits/save/${id}/?q=draft/`, data);
      if (response.status === 201 || response.status === 200) {
        edit
          ? Notification.success("Encounter is Updated Successfully")
          : Notification.success("Encounter is Saved as Draft Successfully");
        closeVisit();
        getEncounters();
      }
    } catch (error) {
      console.log(error);
    } finally{
      setDraftLoading(false)
    }
  };

  const handleVisitEncounter = async (values) => {
    setSubmitLoading(true)
    let data = {};
    edit
      ? (data = {
          encounter_details: form1.getFieldValue("encounter"),
          status: "Signed",
        })
      : (data = {
          service_name: previousData?.serviceName,
          reason_of_visit: previousData?.reasonForVisit,
          note_type: previousData?.noteType,
          encounter_details: values?.encounter,
          status: "Signed",
        });
    try {
      const response = edit
        ? await doPatch(`/visits/update/${id}/${selectedItem?.id}/`, data)
        : await doPost(`/visits/save/${id}/?q=sign`, data);
      if (response.status === 201 || response.status === 200) {
        edit
          ? Notification.success("Encounter is Updated Successfully")
          : Notification.success("Encounter is Saved Successfully");
        closeVisit();
        getEncounters();
      }
    } catch (error) {
      console.log(error);
    } finally{
      setSubmitLoading(false)
    }
  };

  const getEncounters = async () => {
    setLoading(true);
    try {
      const response = await doGet(`/visits/view/${id}/`);
      if (response.status === 200) {
        const sortedTasks = response.data.sort(
          (a, b) =>
            new Date(b.encounter_start_date) - new Date(a.encounter_start_date)
        );
        setEncountersData(sortedTasks);
      }
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  const handleClear = () => {
    showConfirmModal({
      title: "Confirm Clear?",
      icon: null,
      content: "Are you sure? Current Data will be Lost",
      okText: "Clear",
      okType: "danger",
      cancelText: "Cancel",
      className: "custom-modal",
      onOk() {
        form1.resetFields();
      },
    });
  };

  useEffect(() => {
    getEncounters();
  }, []);

  useEffect(() => {
    getTemplates();
    getReasonForVisit();
  }, []);

  return (
    <Card>
      <div className="flex justify-between items-center">
        <h1 className="text-lg font-bold">Encounters </h1>
        <Button type="primary" onClick={openModal}>
          Start New Encounter
        </Button>
      </div>

      <CustomTable
        loading={loading}
        columns={coloumn}
        dataSource={encountersData?.filter(
          (encounter) => encounter?.status !== "Cancel"
        )}
        className="mt-4"
        key="visits"
      />

      <Modal
        title="New Encounter"
        open={visible}
        footer={null}
        onCancel={closeSelectEncounter}
        closeIcon={
          <Button size="small" className="app-close-icon" shape="round">
            Close
          </Button>
        }
      >
        <Form layout="vertical" form={form} onFinish={handleEncounter}>
          <Form.Item
            name="reason_for_visit"
            label="Reason For Visit"
            rules={[
              {
                required: true,
                message: "Please Enter Reason for Visit",
              },
            ]}
          >
            <Select placeholder="Choose Reason for Visit">
              {waitingRoomData &&
                waitingRoomData?.map((item, index) => (
                  <Option key={index} value={item?.reason_of_visit}>
                    <div>
                      <p>
                        <span style={{ fontWeight: "bold" }}>
                          Check-in Time:
                        </span>{" "}
                        <FormatDateTime date={item?.checkin_time} type="datetime"/>
                      </p>
                      <p>
                        <span style={{ fontWeight: "bold" }}>
                          Reason for Visit:
                        </span>{" "}
                        {item?.reason_of_visit}
                      </p>
                      <p>
                        <span style={{ fontWeight: "bold" }}>
                          Service Name:
                        </span>{" "}
                        {item?.service_name}
                      </p>
                    </div>
                  </Option>
                ))}
            </Select>
          </Form.Item>

          <Form.Item
            name="template"
            label="Choose Template / Note Type"
            rules={[
              {
                required: true,
                message: "Please Choose any Template",
              },
            ]}
          >
            <Select placeholder="Choose Template / Note Type">
              {templates &&
                templates?.map((item) => (
                  <Option key={item.id} value={item.title}>
                    {item.title}
                  </Option>
                ))}
            </Select>
          </Form.Item>

          <div className="flex justify-end">
            <Button onClick={closeSelectEncounter}>Cancel</Button>
            <Button type="primary" htmlType="submit" className="ml-2">
              Start Encounter
            </Button>
          </div>
        </Form>
      </Modal>

      <Drawer
        title={edit ? "Update Encounter" : "New Encounter"}
        open={encounter}
         placement="bottom"
         height={"100vh"}
        footer={null}
        onClose={onCancel}
        width={1000}
        maskClosable={false}
        headerStyle={{
          position: "fixed",
          backgroundColor: "white",
          width: "100%",
          top: 0,
          zIndex:999,
          boxShadow: "0px 0px 5px 0px rgba(0,0,0,0.25)",
        }}
      >
        <div className="mt-10"/>
         <NotesHeader />
        <Form layout="vertical" form={form1} onFinish={handleVisitEncounter}>
          <Form.Item name="encounter" label={`Note Type: ${title}`}>
            <ReactQuill
              theme="snow"
              modules={modules}
              style={{ height: "62vh", }}
              value={form1.getFieldValue("encounter")}
            />
          </Form.Item>

          <div className="flex justify-between mt-14">
            <Button onClick={handleClear}>Clear</Button>
            <p className="text-gray-400">Maximum Attachment Size is 1MB</p>
            <div>
              <Button
                type="primary"
                onClick={handleDraftVisitEncounter}
                className={role !== "nurse" && "mr-2"}
                loading={draftLoading}
              >
                Save as Draft
              </Button>
              {role !== "nurse" && (
                <Button type="primary" htmlType="submit" loading={submitLoading}>
                  Sign
                </Button>
              )}
            </div>
          </div>
        </Form>
      </Drawer>

      <ViewEncounter
        open={viewEncounter}
        selectedEncounterData={entcounterData}
        onClose={() => setViewEncounter(false)}
      />

      <VisitPad visible={print?.show} data={print?.data} onClose={hidePrint} />

    </Card>
  );
};

export default Visits;
