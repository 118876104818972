import { Divider, Drawer, Typography } from "antd";
import React, { useRef , useState, useEffect} from "react";
import { useReactToPrint } from "react-to-print";
import Header from "../../Visits/components/Header";
import { useStateGlobalContext } from "../../../../../contexts/GlobalContext";
import ViewTemplate from "../../../Templates/components/ViewTemplate";



const {  Title, Paragraph } = Typography;
const  GeneralNotesPad = ({ visible, data, onClose }) => {
  const [loading, setLoading] = useState(true)
  const { officeSetting, patientBasicInfo ,providerDetails , getProviderDetails } = useStateGlobalContext()
  const full_name = `${patientBasicInfo?.first_name || ''} ${patientBasicInfo?.middle_name ? patientBasicInfo.middle_name + ' ' : ''}${patientBasicInfo?.last_name || ''}`.trim();


  const contentRef = useRef(null);
  const handlePrint = useReactToPrint({
    content: () => contentRef.current,
  });

  useEffect(() => {
    if (data?.provider_id) {
      getProviderDetails(data?.provider_id);
      setLoading(false)
    }
  }, [data?.provider_id]);

  return (
    <Drawer
      title={full_name}
      placement="bottom"
      height="100vh"
      footer={null}
      open={visible}
      onClose={onClose}
      headerStyle={{
        position: "fixed",
        backgroundColor: "white",
        width: "100%",
        top: 0,
        zIndex: 999,
        boxShadow: "0px 0px 5px 0px rgba(0,0,0,0.25)",
      }}
      className="prescription-drawer"
    >
      <div className="invoices-print">
        <div className="invoice-container">
          <button className="guest-button" onClick={handlePrint}>
            Print Document
          </button>
          <div className="invoice-content" ref={contentRef}>
            <h2 className="text-center mt-4 mb-4">Patient Notes: {data?.status === 'Cancelled' ? <span className="font-medium text-red-500">{data.status}</span>: data?.status}</h2>
            <Header
              officeInfo={officeSetting}
              patientInfo={patientBasicInfo}
              date={data?.signed_date}
              providerDetails={providerDetails}
            />
            <Divider />
            <Title className="text-center mt-4" level={5}>
              Patient Notes
            </Title>
            
            <Paragraph className="font-semibold">Reason: {data?.reason}</Paragraph>
            <ViewTemplate template={data?.notes}/>
          </div>
        </div>
      </div>
    </Drawer>
  );
};

export default GeneralNotesPad
