import { Button, Modal } from 'antd'
import React from 'react'
import { NumberFormatter } from '../../../../../../../../utils/validator'
import FormatDateTime from '../../../../../../../../components/FormatDate/FormatDateTime'
import { CopyableText } from '../../../../../../../../components/GlobalComponents/GlobalComponents'
import LabelDisplay from '../../../../../../../../components/LabelDisplay/LabelDisplay'
import SingleInvoiceComponent from './SingleInvoiceComponent'

const ViewInvoice = ({visible, onClose, rxData}) => {
  
  return (
    <Modal
     title={<CopyableText text={rxData?.invoice_id}/>}
     open={visible}
     onCancel={onClose}
     footer={null}
     closeIcon={
      <Button size="small" className="app-close-icon" shape="round">Close</Button>
    }
    >
      <SingleInvoiceComponent rxData={rxData}/>
    </Modal>
  )
}

export default ViewInvoice
