import React, { useEffect, useState } from "react";
import { Card,  Dropdown, Tooltip, Button} from "antd";
import { useParams } from "react-router-dom";
import { EllipsisOutlined } from "@ant-design/icons";
import { useStateGlobalContext } from "../../../../../../../contexts/GlobalContext";
import {  doPatch } from "../../../../../../../API/apis";
import ProductsDrawer from "./ProductsDrawer";
import PrescriptionPad from "./component/PrescriptionPad";
import Notification from "../../../../../../../components/Notification/Notification";
import FormatDateTime from "../../../../../../../components/FormatDate/FormatDateTime";
import { NumberFormatter } from "../../../../../../../utils/validator";
import CustomTable from "../../../../../../../components/CustomTable/CustomTable";
import { invoiceStatus } from "../../../../../../../utils/Data";
import { InvoiceStatus } from "../../../../../../../components/GlobalComponents/StatusLabels";

const items = [
  {
    key: 1,
    label: "View",
  },
  {
    key: 3,
    label: "Print",
  },
  {
    key: 4,
    label: "Cancel Invoice",
  },
]; 


const ProductInvoices = () => {
  const { id } = useParams();
  const [productDrawer, setProductDrawer] = useState(false);
  const [drawerData, setDrawerData] = useState([])
  const [loading, setLoading] = useState(false)
  const [productPad, setProductPad] = useState(false)
  const [productRecord, setProductRecord] = useState(null)
  const {productData, getProductInvoices} = useStateGlobalContext()
  

  const coloumn = [
    {
      ke: 1,
      title: "Issue Date",
      align: "center",
      dataIndex: "issue_date",
      render: (text) => (
        <Tooltip title={<FormatDateTime date={text} type="time" />}>
        <span>{text ? <FormatDateTime date={text} type="date" /> : ""}</span>
      </Tooltip>
      ),
    },
    {
      ke: 5,
      title: "Product Name",
      align: "center",
      dataIndex: "product_details",
      render:(product, record)=>{
        if (product && product.length > 0) {
          const prdNames = [];
    
          for (let i = 0; i < Math.min(product.length, 3); i++) {
            const prd = product[i];
            if (prd.items) {
              prdNames.push(prd.items);
            }
          }
    
          const displayText =  product.length > 3 ? prdNames.join(", ") + "..." : prdNames.join(", ");
          
          return (
            <Button onClick={()=>handleView(record, true)} type="link">
               {displayText}
            </Button>
          );
        } else {
          return (
            <div style={{ cursor: 'pointer' }}>
              N/A
            </div>
          );
        }
      }
    },
    {
        ke: 'payment-method',
        title: "Payment Method",
        align: "center",
        dataIndex: "payment_method",
      },
    {
      ke: 2,
      title: "Price",
      align: "center",
      dataIndex: "price",
      render:(text)=> <NumberFormatter value={text}/>
    },
    {
      ke: 3,
      title: "Status",
      align: "center",
      dataIndex: "status",
      filters: invoiceStatus?.map((text) => ({
        text: text.label,
        value: text.label,
      })),
      onFilter: (value, record) =>
        record?.status?.toLowerCase().includes(value.toLowerCase()),
      defaultFilteredValue: ["Paid"], 
      render: (status) =>
        <InvoiceStatus status={status}/>
    },
    {
      ke: 4,
      title: "Action",
      align: "center",
      render: (_, record) => {
        const { status } = record;
        const availableItems =
          status === "Paid"
            ? items.filter((item) => [1, 3, 4].includes(item.key))
            : items.filter((item) => [1,  3, 4].includes(item.key));
        return (
          <Dropdown
            menu={{
              items: availableItems.map((item) => ({
                ...item,
                onClick: () => {
                  if (item.key === 4) {
                    handelCancelInvoice(record);
                  } else if (item.key === 1) {
                    handleView(record, true);
                  } 
                   else if (item.key === 3) {
                    handlePrint(record)
                  }
                },
              })),
            }}
            trigger={["click"]}
          >
            <EllipsisOutlined className="cursor-pointer" />
          </Dropdown>
        );
      },
    },
  ];

  const handlePrint =(record)=>{
    setProductPad(true)
    setProductRecord(record)
  }

  const closePrint = ()=>{
    setProductPad(false)
  }

  const handelCancelInvoice = async (record) => {
    try {
      const response = await doPatch(
        `/inventory/update-prod-invoice/${id}/${record?.id}/`,
        {status: "Cancelled"}
      );
      if (response.status === 200) {
        Notification.success("Product Invoice Cancelled Successfully");
        getProductInvoices(id)
      }
    } catch (error) {
      console.log(error);
    }
  };



  const handleView = (record) => {
    setDrawerData(record)
    setProductDrawer(true);
  };

  const onClose = () => {
    setProductDrawer(false)
  };


  useEffect(() => {
    const fetchData = async () => {
      setLoading(true)
      try {
        await Promise.all([getProductInvoices(id)]);
      } catch (error) {
        Notification.error("Failed to fetch data");
      } finally {
        setLoading(false);
      }
    };
    fetchData();
  }, [id]);


  return (
    <>
        <Card className="shadow-class">
          <h1 className="mb-2">Product Invoices </h1>
          <CustomTable
            columns={coloumn}
            loading={loading}
            dataSource={productData}
            key="product_invoice"
          />
        </Card>

      <ProductsDrawer
        visible={productDrawer}
        data={drawerData}
        onClose={onClose}
      />
      <PrescriptionPad visible={productPad} onClose={closePrint} rxData={productRecord}/>
    </>
  );
};

export default ProductInvoices;
