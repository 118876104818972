import React, { useEffect, useState } from "react";
import { PlusOutlined, EditOutlined, DeleteOutlined } from "@ant-design/icons";
import { Modal, Form, Input, Button, Radio, Row, Col } from "antd";
import { doPost, doGet, doPatch, doDelete } from "../../../../../API/apis";
import { useParams } from "react-router-dom";
import { yearCalculator } from "../../../../../utils/ageCalculator";
import BaseDatePicker from "../../../../../components/BaseDatePicker/BaseDatePicker";
import MedicalCardsTitle from "../MedicalCardsTitle";
import Notification from "../../../../../components/Notification/Notification";
import showConfirmModal from "../../../../../components/ModalConfirm/ModalConfirm";
import FormatDate from "../../../../../components/FormatDate/FormatDate";
import FormatDateTime from "../../../../../components/FormatDate/FormatDateTime";

function SocialHistory() {
  const { id } = useParams();
  const [form] = Form.useForm();
  const [data, setData] = useState(null);
  const [visible, setVisible] = useState(false);
  const [loading, setLoading] = useState(false);
  const [editMode, setEditMode] = useState(false);
  const [selectedItem, setSelectedItem] = useState(null);
  const [hoveredItemId, setHoveredItemId] = useState(null);
  const [socialHistoryModal, setSocialHistoryModal] = useState(false);

  const disabledDate = (current) => current && current > new Date();

  const openModal = () => {
    form.setFieldsValue({ type: "tobacco" });
    setVisible(true);
  };

  const handleDateChange = (field, date) => {
    form.setFieldsValue({ [field]: date });
  };

  const handleCancel = () => {
    setTimeout(() => {
      setVisible(false);
      setEditMode(false);
      setSocialHistoryModal(false);
      form.resetFields();
    }, 300);
  };

  const handleItemClick = (record) => {
    setSelectedItem(record);
    setSocialHistoryModal(true);
  };

  const handleEdit = (record) => {
    setEditMode(true);
    setSelectedItem(record);
    const typeField = Object.keys(record).find((key) => record[key] === true);
    form.setFieldsValue({
      start_date: record?.start_date,
      end_date: record?.end_date,
      type: typeField,
      social_history: record.social_history,
    });
    setVisible(true);
  };

  const handleDelete = (record) => {
    showConfirmModal({
      title: "Confirm Delete?",
      icon: null,
      content: "Are you sure you want to delete this?",
      okText: "Delete",
      okType: "danger",
      cancelText: "Cancel",
      className: "custom-modal",
      onOk: async()=> {
        try {
          const response = await doDelete(
            `/patient/delete-social-history/${id}/${record}/`
          );
          if (response.status === 200) {
            Notification.success("Social History Deleted Successfully");
            getSocialHistory();
          }
        } catch (error) {
          Notification.error("Something went wrong");
        }
      },
    });
  };


  const handleSocialHistory = async (values) => {
    setLoading(true)
    const { type, start_date, end_date, social_history } = values;
    const data = {
      tobacco: type === "tobacco",
      alcohol: type === "alcohol",
      drugs: type === "drugs",
      other: type === "other",
      start_date,
      end_date,
      social_history,
    };

    try {
      const response = editMode
        ? await doPatch(
            `/patient/update-social-history/${id}/${selectedItem?.id}/`,
            data
          )
        : await doPost(`/patient/add-social-history/${id}/`, data);

      if (response.status === 200 || response?.status === 201) {
        const successMessage = editMode
          ? "Social History Updated Successfully"
          : "Social History Added Successfully";
        Notification.success(successMessage);
        handleCancel();
        getSocialHistory();
      }
    } catch (error) {
      Notification.warning("Something Went Wrong");
    } finally{
      setLoading(false)
    }
  };

  const getSocialHistory = async () => {
    setLoading(true);
    try {
      const response = await doGet(`/patient/get-social-history/${id}/`);
      if (response?.status === 200) {
        setData(response?.data);
      }
    } catch (error) {
      Notification.warning("Something went wrong");
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    getSocialHistory();
  }, [id]);

  const renderItem = (type) => {
    const items = data?.filter((item) => item[type]);

    return (
      <div>
        <p className="font-medium">
          {type.charAt(0).toUpperCase() + type.slice(1)}:
        </p>
        <ul>
          {items?.map((item) => (
            <li
              key={item?.id}
              className="text-[#1890ff] cursor-pointer flex items-center"
              onMouseEnter={() => setHoveredItemId(item?.id)}
              onMouseLeave={() => setHoveredItemId(null)}
            >
              <span onClick={() => handleItemClick(item)}>
                {item?.social_history}
                {item?.end_date
                  ? ` (${yearCalculator(
                      item?.start_date,
                      item?.end_date
                    )} years)`
                  : " (Present)"}
              </span>
              {hoveredItemId === item?.id && (
                <div className="flex items-center ml-3">
                  <EditOutlined
                    className="text-[#1890ff] mr-2"
                    onClick={() => handleEdit(item)}
                  />
                  <DeleteOutlined
                    className="text-[#1890ff]"
                    onClick={() => handleDelete(item?.id)}
                  />
                </div>
              )}
            </li>
          ))}
        </ul>
      </div>
    );
  };

  return (
    <div>
      <div className="shadow-class p-4 border rounded-md h-[180px] max-h-[180px]">
        <div className="flex items-center justify-between">
          <MedicalCardsTitle title="Social History" />
          <Button type="primary" size="small" shape="round" onClick={openModal}>
            Add <PlusOutlined />
          </Button>
        </div>
        <div className="flex flex-col max-h-[130px] overflow-auto mt-1">
          {renderItem("tobacco")}
          {renderItem("alcohol")}
          {renderItem("drugs")}
          {renderItem("other")}
        </div>
      </div>

      <Modal
        title={editMode ? "Update Social History" : "Add Social History"}
        open={visible}
        onCancel={handleCancel}
        maskClosable={false}
        closeIcon={
          <Button size="small" className="app-close-icon" shape="round">
            Close
          </Button>
        }
        footer={null}
        width={650}
      >
        <Form
          layout="vertical"
          form={form}
          onFinish={handleSocialHistory}
          initialValues={{ type: "tobacco" }}
        >
          <Form.Item name="type">
            <Radio.Group defaultValue="tobacco" buttonStyle="solid">
              <Radio.Button value="tobacco">Tobacco</Radio.Button>
              <Radio.Button value="alcohol">Alcohol</Radio.Button>
              <Radio.Button value="drugs">Drugs</Radio.Button>
              <Radio.Button value="other">Other</Radio.Button>
            </Radio.Group>
          </Form.Item>
          <Row gutter={16}>
            <Col span={6}>
              <Form.Item
                name="start_date"
                label="Start Date"
                rules={[
                  {
                    required: true,
                    message: "Start Date is compulsory",
                  },
                ]}
              >
                <BaseDatePicker
                  disabledDate={disabledDate}
                  value={form.getFieldValue("start_date")}
                  onChange={(date) => handleDateChange("start_date", date)}
                />
              </Form.Item>
            </Col>
            <Col span={6}>
              <Form.Item
                name="end_date"
                label="Stop Date"
                rules={[
                  ({ getFieldValue }) => ({
                    validator(_, value) {
                      const startDate = getFieldValue("start_date");
                      if (!value || !startDate || value >= startDate) {
                        return Promise.resolve();
                      }
                      return Promise.reject(
                        new Error("End Date cannot be before than Start Date")
                      );
                    },
                  }),
                ]}
              >
                <BaseDatePicker disabledDate={disabledDate} />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                name="social_history"
                label="Social History"
                rules={[
                  {
                    required: true,
                    message: "Enter Social History",
                  },
                ]}
              >
                <Input maxLength={30} />
              </Form.Item>
            </Col>
          </Row>
          <div className="flex justify-end">
            <Button onClick={handleCancel}>Cancel</Button>
            <Button type="primary" htmlType="submit" className="ml-2" loading={loading}>
              {editMode ? "Update" : "Save"}
            </Button>
          </div>
        </Form>
      </Modal>

      <Modal
        title="Social History"
        open={socialHistoryModal}
        onCancel={handleCancel}
        maskClosable={false}
        footer={null}
        width={650}
        closeIcon={
          <Button size="small" className="app-close-icon" shape="round">
            Close
          </Button>
        }
      >
        {selectedItem && (
          <>
            <p>
              <span className="font-medium">Start Date:</span>
              <span className="ml-2">
                {selectedItem.start_date ? (
                  <FormatDate date={selectedItem?.start_date} />
                ) : (
                  "No Date Entered"
                )}
              </span>
            </p>
            <p>
              <span className="font-medium">End Date:</span>
              <span className="ml-2">
                {selectedItem.end_date ? (
                  <FormatDate date={selectedItem?.end_date} />
                ) : (
                  "Present"
                )}
              </span>
            </p>
            <p>
              <span className="font-medium">Number of Years: </span>
              <span>
                {yearCalculator(selectedItem.start_date, selectedItem.end_date)}{" "}
                years
              </span>
            </p>
            <p>
              <span className="font-medium">Social History:</span>
              <span className="ml-2">{selectedItem.social_history}</span>
            </p>
            <p>
              <span className="font-medium">Added By:</span>
              <span className="ml-2">{selectedItem.added_by}</span>
            </p>
            <p>
              <span className="font-medium">Date:</span>
              <span className="ml-2">
                <FormatDateTime
                  date={selectedItem?.date_time}
                  type="datetime"
                />
              </span>
            </p>
          </>
        )}
      </Modal>
    </div>
  );
}

export default SocialHistory;
