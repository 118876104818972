import React, { useEffect, useState } from "react";
import {
  Button,
  Input,
  Form,
  Modal,
  Upload,
  Dropdown,
  Card,
} from "antd";
import { InboxOutlined, EllipsisOutlined } from "@ant-design/icons";
import { doDelete, doGet, doPatch, doPost } from "../../../../API/apis";
import { useParams } from "react-router-dom";
import ViewPatientFiles from "./component/ViewPatientFiles";
import Notification from "../../../../components/Notification/Notification";
import showConfirmModal from "../../../../components/ModalConfirm/ModalConfirm";
import FormatDateTime from "../../../../components/FormatDate/FormatDateTime";
import CustomTable from "../../../../components/CustomTable/CustomTable";

const { Dragger } = Upload;
const MAX_FILE_SIZE = 5 * 1024 * 1024;

const items = [
  {
    key: 1,
    label: "View",
  },
  {
    key: 2,
    label: "Edit",
  },
  {
    key: 3,
    label: "Delete",
  },
];

const headers = {
  "Content-Type": "multipart/form-data",
};

const PatientFiles = () => {
  const { id } = useParams();
  const [form] = Form.useForm();
  const [edit, setEdit] = useState(false);
  const [editRecord, setEditRecord] = useState(null);
  const [fileData, setFileData] = useState([]);
  const [fileLoading, setFileLoading] = useState(false);
  const [view, setView] = useState({
    viewState: false,
    viewData: [],
  });
  const [loading, setLoading] = useState(false);
  const [patientFiles, setPatientFiles] = useState([]);
  const [visible, setVisible] = useState(false);
  const [fileList, setFileList] = useState([]);

  const columns = [
    {
      key: 2,
      title: "File Name",
      dataIndex: "filename",
    },
    {
      key: 3,
      title: "Description",
      dataIndex: "description",
      render :(text) => text === 'undefined' ? 'No Description Added' : text
    },
    {
      key: 4,
      title: "Uploaded By",
      dataIndex: "added_by",
    },
    {
      key: 5,
      title: "Date",
      dataIndex: "date_time",
      render: (text) => <FormatDateTime date={text} type="datetime" />,
    },
    {
      title: "Action",
      key: "action",
      align: "center",
      width: "10%",
      render: (text, record) => (
        <Dropdown
          menu={{
            items: items.map((item) => ({
              ...item,
              onClick: () => {
                if (item.key === 1) {
                  handleView(record);
                } else if (item.key === 2) {
                  handleUpdate(record);
                } else if (item.key === 3) {
                  handleDelete(record);
                }
              },
            })),
          }}
          trigger={["click"]}
        >
          <EllipsisOutlined className="cursor-pointer" />
        </Dropdown>
      ),
    },
  ];

  const handleView = async (record) => {
    await handleFile(record?.uploaded_file_encrypted_name);
    setView({
      ...view,
      viewState: true,
      viewData: record,
    });
  };

  const handleUpdate = (record) => {
    setEdit(true);
    setEditRecord(record);
    setVisible(true);
    form.setFieldsValue({
      file_name: record?.filename,
      description: record?.description,
      file: record?.file,
    });
  };

  const handleFile = async (record) => {
    setFileLoading(true);
    try {
      const response = await doGet(`/upload/media/retrieve/${record}/`);
      if (response.status === 200) {
        const fileContent = response.data;
        setFileData(fileContent);
      }
    } catch (error) {
      console.log(error);
    } finally {
      setFileLoading(false);
    }
  };

  const closeView = () => {
    setView({
      ...view,
      viewState: false,
      viewData: [],
    });
  };

  const openModal = () => {
    setVisible(true);
  };

  const onCancel = () => {
    setVisible(false);
    form.resetFields();
    setFileList([]);
    setEdit(false);
    setEditRecord(null);
  };

  const handlePatientFile = async (values) => {
    setLoading(true);
    let data = {};
    const formData = new FormData();
    if (!edit) {
      formData.append("filename", values.file_name);
      formData.append("description", values.description || ""); 
      fileList.forEach((file) => {
        formData.append("file", file);
      });
    } else {
      data = {
        filename: values.file_name,
        description: values.description || "",
      };
    }

    try {
      const url = edit
        ? `/patient/update-file/${id}/${editRecord?.id}/`
        : `/patient/add-file/${id}/`;

      const response = await (edit
        ? doPatch(url, !edit ? formData: data, !edit && headers) 
        : doPost(url, formData, headers));
      if (response.status === 201 || response.status === 200) {
        edit
          ? Notification.success("Patient File(s) Updated Successfully")
          : Notification.success("Patient File(s) Uploaded Successfully");
        getPatientFiles();
        onCancel();
      }
    } catch (error) {
      console.error("Error uploading file:", error);
      Notification.error("Error uploading file. Please try again.");
    } finally {
      setLoading(false);
    }
  };

  const beforeUpload = (file) => {
    if (file.size > MAX_FILE_SIZE) {
      Notification.error("File size exceeds the maximum limit (5MB)");
      return false;
    }

    if (fileList.length === 1) {
      Notification.warning("You can only upload one file.");
      return false;
    }

    setFileList([file]);
    return false;
  };

  const onRemove = (file) => {
    const index = fileList.indexOf(file);
    const newFileList = [...fileList];
    newFileList.splice(index, 1);
    setFileList(newFileList);
  };

  const getPatientFiles = async () => {
    setLoading(true);
    try {
      const response = await doGet(`/patient/get-file/${id}/`);
      if (response.status === 200) {
        const sortedPatientFiles = response.data.sort((a, b) => {
          return new Date(b.date_time) - new Date(a.date_time);
        });
        setPatientFiles(sortedPatientFiles);
      }
    } catch (error) {
      Notification.error("Something Went Wrong");
    } finally {
      setLoading(false);
    }
  };

  const handleDelete = (record) => {
    showConfirmModal({
      title: "Confirm Delete?",
      icon: null,
      content: "Are you sure you want to delete this?",
      okText: "Delete",
      okType: "danger",
      cancelText: "Cancel",
      className: "custom-modal",
      onOk() {
        deleteUploadedFile(record);
      },
    });
  };

  const deleteUploadedFile = async (record) => {
    setLoading(true);
    try {
      const response = await doDelete(
        `/patient/delete-file/${id}/${record.id}/`
      );
      if (response.status === 200) {
        Notification.success("File Deleted Successfully");
        getPatientFiles();
      }
    } catch (error) {
      console.error("Error deleting file:", error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    getPatientFiles();
  }, []);

  return (
    <Card>
      <div className="flex justify-between items-center mb-4">
        <h1 className="text-[1.125rem] font-semibold">Files</h1>
        <Button type="primary" onClick={openModal}>
          New File
        </Button>
      </div>
      <CustomTable
        loading={loading}
        columns={columns}
        dataSource={patientFiles}
        className="mt-4"
        key="patient_files"
      />

      <Modal
        title={edit ? "Update Patient Files" : "Patient Files"}
        open={visible}
        onCancel={onCancel}
        maskClosable={false}
        footer={null}
        closeIcon={
          <Button size="small" className="app-close-icon" shape="round">
            Close
          </Button>
        }
      >
        <Form
          layout="vertical"
          name="file_form"
          form={form}
          onFinish={handlePatientFile}
        >
          <Form.Item
            label="File Name"
            name="file_name"
            rules={[
              {
                required: true,
                message: "Please Enter File Name",
              },
            ]}
          >
            <Input placeholder="File Name" size="medium" maxLength={30} />
          </Form.Item>
          <Form.Item label="Description" name="description">
            <Input.TextArea
              placeholder="Enter Description"
              maxLength={500}
              showCount
            />
          </Form.Item>
          {!edit && (
            <Form.Item
              name="file"
              label="File"
              rules={[
                {
                  required: true,
                  message: "Please Select any file",
                },
              ]}
            >
              <Dragger
                fileList={fileList}
                beforeUpload={beforeUpload}
                onRemove={onRemove}
                accept=".pdf, .jpg, .jpeg, .png"
                multiple={false}
              >
                <p className="ant-upload-drag-icon">
                  <InboxOutlined className="upload__icon" />
                </p>
                <p className="ant-upload-text">
                  Click or drag pdf, jpg, jpeg files to this area to upload
                </p>
                <p className="ant-upload-hint">Maximum size: 5MB.</p>
              </Dragger>
            </Form.Item>
          )}
          <div className="flex justify-end">
            <Button onClick={onCancel}>Cancel</Button>
            <Button
              type="primary"
              htmlType="submit"
              loading={loading}
              className="ml-2"
            >
              {edit ? "Update" : "Save"}
            </Button>
          </div>
        </Form>
      </Modal>

      <ViewPatientFiles
        visible={view?.viewState}
        onClose={closeView}
        data={view?.viewData}
        fileData={fileData}
        loading={fileLoading}
      />
    </Card>
  );
};

export default PatientFiles;
