import React, { useState, useCallback } from "react";
import {
  Table,
  Modal,
  Empty,
  Button,
  Row,
  Col,
  Avatar,
  Spin,
  Dropdown,
} from "antd";
import { EllipsisOutlined } from "@ant-design/icons";
import { doGet, doDelete } from "../../API/apis";
import "./userroles.css";
import AddStaffModal from "../AddStaffModal";
import Notification from "../Notification/Notification";
import { useStateGlobalContext } from "../../contexts/GlobalContext";
import showConfirmModal from "../ModalConfirm/ModalConfirm";
import LabelDisplay from "../../components/LabelDisplay/LabelDisplay";

const items = [
  {
    key: 1,
    label: "Edit",
  },
  {
    key: 2,
    label: "Delete",
  },
];

const UserTable = ({ users, loading, showActionButton }) => {
  const [modalVisible, setModalVisible] = useState(false);
  const { getStaff, getDeletedStaff } = useStateGlobalContext();
  const [data, setData] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [user_name, setUsername] = useState(null);
  const [editRecord, setEditRecord] = useState({
    show: false,
    data: null,
  });

  const endpoint = "/settings/user-settings/";

  const openModal = useCallback(async (username) => {
    setModalVisible(true);
    setIsLoading(true);
    setUsername(username);
    try {
      const response = await doGet(`${endpoint}${username}/`);
      console.log(response.data, "RESPONSE IS");
      if (response.status === 200) {
        setData(response?.data);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    } finally {
      setIsLoading(false);
    }
  }, []);

  const editUser = (record) => {
    setEditRecord({
      ...editRecord,
      show: true,
      data: record,
    });
  };

  const hideModal = () => {
    setEditRecord({
      ...editRecord,
      show: false,
      data: null,
    });
  };

  const actionColumn = {
    key: 5,
    title: "Action",
    align: "center",
    width: "10%",
    dataIndex: "action",
    render: (_, record) => {
      const availableItems = items;
      return (
        <Dropdown
          menu={{
            items: availableItems.map((item) => ({
              ...item,
              onClick: () => {
                if (item.key === 1) {
                  editUser(record);
                } else if (item.key === 2) {
                  handleDelete(record);
                }
              },
            })),
          }}
          trigger={["click"]}
        >
          <EllipsisOutlined className="cursor-pointer" />
        </Dropdown>
      );
    },
  };

  const columns = [
    {
      key: 1,
      title: "Username",
      dataIndex: "username",
      align: "center",
      width: "12%",
      render: (_, record) => (
        <Button
          type="link"
          onClick={() => record?.username && openModal(record?.username)}
          disabled={!record?.username}
        >
          {record.username || record?.user__username}
        </Button>
      ),
    },
    {
      key: 2,
      title: "Email",
      dataIndex: "email",
      align: "center",
      width: "30%",
      render: (_, record) => `${record?.email || record?.user__email}`,
    },
    {
      key: 3,
      title: "Full Name",
      width: "15%",
      align: "center",
      render: (_, record) => {
        const firstName = record?.first_name || record?.user__first_name || "";
        const lastName = record?.last_name || "";
        return `${firstName} ${lastName}`.trim();
      },
    },
    {
      key: "role",
      title: "Role",
      dataIndex: "role",
      width: "10%",
      align: "center",
      render: (_, record) => {
        const role = record?.role || record?.user__role;

        switch (role) {
          case "nurse":
            return "Nurse";
          case "clinic":
            return "Owner";
          case "doctor":
            return "Provider";
          case "manager":
            return "Admin";
          default:
            return role;
        }
      },
    },
    ...(showActionButton ? [actionColumn] : []),
  ];

  const handleDelete = (record) => {
    showConfirmModal({
      title: "Confirm Delete?",
      icon: null,
      content: "Are you sure you want to delete this?",
      okText: "Delete",
      okType: "danger",
      cancelText: "Cancel",
      className: "custom-modal",
      onOk() {
        handleConfirmDelete(record);
      },
    });
  };

  const handleConfirmDelete = async (record) => {
    try {
      const userId = record?.id || record?.user__id;
      if (!userId) {
        Notification.error("User ID is missing");
        return;
      }
      const result = await doDelete(`/user/delete-staff/${userId}/`);
      if (result.status === 200) {
        Notification.success("Record deleted successfully");
        getStaff();
        getDeletedStaff();
      }
    } catch (error) {
      Notification.error(error.message);
    }
  };

  const emptyText = () => {
    return (
      <Empty
        image={Empty.PRESENTED_IMAGE_SIMPLE}
        description={<span>No Users Added </span>}
      />
    );
  };

  return (
    <>
      <Table
        loading={loading}
        dataSource={users}
        columns={columns}
        pagination={false}
        rowKey="id"
        locale={{
          emptyText: emptyText(),
        }}
        size="small"
      />

      <AddStaffModal
        open={editRecord?.show}
        record={editRecord?.data}
        onClose={hideModal}
      />

      <Modal
        open={modalVisible}
        title="User Details"
        onCancel={() => setModalVisible(false)}
        closeIcon={
          <Button size="small" className="app-close-icon" shape="round">
            Close
          </Button>
        }
        footer={null}
        width={600}
      >
        {isLoading ? (
          <div className="text-center">
            <Spin size="size" />
          </div>
        ) : (
          <>
            <div>
              <h4 className="font-semibold">Personal Details</h4>
              <div className="flex mt-2">
                <div className="mr-3">
                  <Avatar
                    shape="square"
                    size={{ xs: 24, sm: 32, md: 40, lg: 64, xl: 80, xxl: 100 }}
                    src={data?.personal_settings?.user_profile_picture || ""}
                  >
                    {data?.personal_settings?.middle_name
                      ?.charAt(0)
                      .toUpperCase() || "N/A"}
                  </Avatar>
                </div>
                <div className="flex flex-col justify-center">
                  <LabelDisplay title={"Username"} description={user_name} />
                  <LabelDisplay
                    title={"Mobile Number"}
                    description={
                      data?.personal_settings?.contact?.phone_number || "N/A"
                    }
                  />
                  <LabelDisplay
                    title={"Work Number"}
                    description={
                      data?.personal_settings?.contact?.work_phone || "N/A"
                    }
                  />
                  <LabelDisplay
                    title={"Phone Number"}
                    description={
                      data?.personal_settings?.contact?.home_phone || "N/A"
                    }
                  />
                </div>
              </div>
            </div>

            <div className="mt-3">
              <h4 className="font-semibold mb-2">Work Schedule</h4>
              {[
                "monday",
                "tuesday",
                "wednesday",
                "thursday",
                "friday",
                "saturday",
                "sunday",
              ].map((day) => (
                <div
                  key={day}
                  className="border rounded-md px-2 py-1 mt-2 flex justify-between cursor-pointer"
                >
                  <span>{day.charAt(0).toUpperCase() + day.slice(1)}:</span>
                  <span>
                    {data?.work_schedule?.[day]?.[0] || "N/A"} -{" "}
                    {data?.work_schedule?.[day]?.[1] || "N/A"}
                  </span>
                </div>
              ))}
            </div>
            <br />

            <div>
              <h4 className="font-semibold">Medical Identifers</h4>
              <Row gutter={16}>
                <Col span={12}>
                  <LabelDisplay
                    title={"Medical License"}
                    description={
                      data?.medical_identifier && data?.medical_identifier?.medical_license[0] || "N/A"
                    }
                  />
                </Col>
                <Col span={12}>
                  <LabelDisplay
                    title={"State License"}
                    description={
                      data?.medical_identifier &&  data?.medical_identifier?.state_license[0] || "N/A"
                    }
                  />
                </Col>
              </Row>

              <Row gutter={16}>
                <Col span={12}>
                  <LabelDisplay
                    title={"Provional Registration Number"}
                    description={
                      data?.medical_identifier && data?.medical_identifier?.provisional_registration[0] ||
                      "N/A"
                    }
                  />
                </Col>
                <Col span={12}>
                  <LabelDisplay
                    title={"DEA Number"}
                    description={
                      data?.medical_identifier &&  data?.medical_identifier?.dea_number[0] || "N/A"
                    }
                  />
                </Col>
              </Row>
            </div>

            <Row gutter={16}>
              <Col span={12}>
                <LabelDisplay
                  title={"Primary Speciality"}
                  description={
                    data?.medical_identifier && data?.medical_identifier?.primary_speciality[0] || "N/A"
                  }
                />
              </Col>
              <Col span={12}>
                <LabelDisplay
                  title={"Secondary Speciality"}
                  description={
                    data?.medical_identifier && data?.medical_identifier?.secondary_speciality[0] || "N/A"
                  }
                />
              </Col>
            </Row>

            <Row gutter={16}>
              <Col span={12}>
                <LabelDisplay
                  title={"Provider Tax ID"}
                  description={
                    data?.medical_identifier && data?.medical_identifier?.provider_tax_id[0] || "N/A"
                  }
                />
              </Col>
              <Col span={12}>
                <LabelDisplay
                  title={"Taxonomy Code"}
                  description={
                    data?.medical_identifier && data?.medical_identifier?.taxonomy_code[0] || "N/A"
                  }
                />
              </Col>
            </Row>

            <LabelDisplay
              title={"Additonal Data 1"}
              description={
                data?.medical_identifier && data?.medical_identifier?.additional_data_1[0] || "N/A"
              }
            />

            <LabelDisplay
              title={"Additonal Data 2"}
              description={
                data?.medical_identifier && data?.medical_identifier?.additional_data_2[0] || "N/A"
              }
            />

            <LabelDisplay
              title={"Additonal Data 3"}
              description={
                data?.medical_identifier && data?.medical_identifier?.additional_data_3[0] || "N/A"
              }
            />
          </>
        )}
      </Modal>
    </>
  );
};

export default UserTable;
