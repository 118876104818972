import React, { useState, useEffect } from "react";
import { Button, Dropdown, Card, Tooltip } from "antd";
import { doGet, doPatch } from "../../../../../API/apis";
import { NumberFormatter } from "../../../../../utils/validator";
import { EllipsisOutlined } from "@ant-design/icons";
import "../../../Inventory/GuestCheckout/guestCheckout.css";
import { useStateGlobalContext } from "../../../../../contexts/GlobalContext";
import PrescriptionPad from "../../../Inventory/GuestCheckout/PrescriptionPad";
import GuestCheckoutView from "../../../../../components/GuestCheckOutView/GuestCheckoutView";
import Notification from "../../../../../components/Notification/Notification";
import FormatDateTime from "../../../../../components/FormatDate/FormatDateTime";
import CustomTable from "../../../../../components/CustomTable/CustomTable";
import DateRangePickerCard from "../../../../../components/GlobalComponents/DateRangePickerCard";


const items = [
  {
    key: 1,
    label: "View",
  },
  {
    key: 2,
    label: "Cancel Invoice",
  },
  {
    key: 4,
    label: "Print",
  },
];

const GuestCheckoutReports = () => {
  const [view, setView] = useState(false)
  const [rxData, setRxData] = useState(null);
  const [loading, setLoading] = useState(false);
  const [viewRecord, setViewRecord] = useState(null)
  const [drawerOpen, setDrawerOpen] = useState(false);
  const [searchClicked, setSearchClicked] = useState(false);
  const [selectedDateRange, setSelectedDateRange] = useState(null);
  const [guestCheckoutReports, setGuestCheckOutReports] = useState(null)


  const {
    officeSetting,
    getOfficeSettings,
    profile
  } = useStateGlobalContext();
  const [clinicInfo, setClinicInfo] = useState({
    clinic_name: "",
    address_line_1: "",
    clinic_phone_number: "",
    clinic_office_phone: "",
    clinic_fax: "",
    additional_data_1: "",
    additional_data_2: "",
    additional_data_3: "",
  });

  const role = profile && profile?.user_info[0]?.role;

  const handleDateChange = (dates) => {
    setSelectedDateRange(dates);
  };

  const handleReset = ()=>{
    setGuestCheckOutReports([])
  }

  const handleSearch = () => {
    if (selectedDateRange && selectedDateRange.length === 2) {
      setSearchClicked(true); 
      getGuestCheckoutReports()
    } else {
      Notification.warning("Please select a date range");
    }
  };

  useEffect(() => {
    getGuestCheckoutReports()
  }, [searchClicked]);

  useEffect(() => {
    getOfficeSettings();
    if (officeSetting) {
      setClinicInfo({
        clinic_name: officeSetting?.facility_name[1]
          ? officeSetting?.facility_name[0]
          : "",
        address_line_1: officeSetting?.facility_address[1]
          ? officeSetting?.facility_address[0]
          : "",
        clinic_phone_number: "",
      });
    }
  }, []);

  const column1 = [
    {
      key: 2,
      title: "Invoice ID #",
      dataIndex: "invoice_id",
      searchable:true,
      ellipsis: true,
      width: "10%",
      align: "center",
      render: (text, record)=> <Button type="link" onClick={()=> handleView(record)}>{text}</Button>,
    },
    {
      key: 3,
      title: "Customer Name",
      dataIndex: "patient_name",
      searchable:true,
      ellipsis: true,
      width: "10%",
    },
    {
      key: 4,
      title: "Items",
      dataIndex: "product_details",
      ellipsis: true,
      render: (productDetails) => {
        if (Array.isArray(productDetails) && productDetails.length > 0) {
          const itemNames = productDetails.map((item) => item.items).join(", ");
          return <span>{itemNames}</span>;
        }
        return null;
      },
    },
    {
      key: 5,
      title: "Price",
      dataIndex: "price",
      align: "center",
      ellipsis: true,
      width: "10%",
      render: (text, record) =>
        record?.status === "Cancelled" ? (
          <span className="text-red-600">
            <NumberFormatter value={text} />
          </span>
        ) : (
          <NumberFormatter value={text} />
        ),
    },
    {
      key: 6,
      title: "Issued By",
      dataIndex: "added_by",
      align: "center",
      ellipsis: true,
      width: "10%",
    },
    {
      key: 1,
      title: "Issue Date",
      dataIndex: "issue_date",
      width: "10%",
      align: "center",
      render: (text) => (
        <Tooltip title={<FormatDateTime date={text} type="time" />}>
        <span>{text ? <FormatDateTime date={text} type="date" /> : ""}</span>
      </Tooltip>
      )
    },
    {
      key: 7,
      title: "Status",
      dataIndex: "status",
      width: "10%",
      align: "center",
      render: (text) => {
        let bgColor = "";
        let textColor = "";
        switch (text) {
          case "paid":
            bgColor = "bg-green-200";
            textColor = "text-green-500";
            break;
          case "Cancelled":
            bgColor = "bg-red-200";
            textColor = "text-red-500";
            break;
          default:
            bgColor = "bg-green-200";
            textColor = "text-green-500";
        }
        return (
          <span
            className={`px-3 py-1 ${textColor} ${bgColor} font-semibold text-xs rounded-md`}
          >
            {text === "paid" ? "Paid" : text}
          </span>
        );
      },
    },
    {
      key: 8,
      title: "Action",
      fixed: "right",
      align: "center",
      width: "8%",
      render: (text, record) => {
        const { status } = record;
        const availableItems =
          status === "Cancelled"
            ? items.filter((item) => [1, 3, 4].includes(item.key))
            : items.filter((item) => [1, 2, 3, 4].includes(item.key));

        return (
          <Dropdown
            menu={{
              items: availableItems.map((item) => ({
                ...item,
                onClick: () => {
                  if (item.key === 4) {
                    handlePrint(record);
                  } else if (item.key === 2) {
                    handleStatus(record);
                  } else if (item.key === 1) {
                    handleView(record)
                  }
                },
              })),
            }}
            trigger={["click"]}
          >
            <EllipsisOutlined className="cursor-pointer" />
          </Dropdown>
        );
      },
    },
  ];

  const handleView = (record)=>{
    setView(true)
    setViewRecord(record)
  }

  const closeView = ()=>{
    setView(false)
    setViewRecord(null)
  }

  const handlePrint = (record) => {
    setDrawerOpen(true);
    setRxData(record);
  };

  const handleCloseDrawer = () => {
    setDrawerOpen(false);
  };


  const handleStatus = async (record) => {
    const response = await doPatch(
      `/inventory/update-status-guest/${record.id}/`,
      {status: "Cancelled",}
    );
    if (response.status === 200) {
      Notification.success("Invoice Cancelled Successfully");
      getGuestCheckoutReports()
    }
  };

  const getGuestCheckoutReports = async () => {
    setLoading(true);
    try {
      if (selectedDateRange && selectedDateRange.length === 2) {
        const startDate = selectedDateRange[0].format("YYYY-MM-DD");
        const endDate = selectedDateRange[1].format("YYYY-MM-DD");
        const response = await doGet(`/reports/guest-checkout-reporting/?start_date=${startDate}&end_date=${endDate}`);
        if (response.status === 200) {
          setGuestCheckOutReports(response.data);
        }
      } 
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    getGuestCheckoutReports()
  }, []);

  return (
    <>
            <DateRangePickerCard
        onDateChange={handleDateChange}
        onSearch={handleSearch}
        onReset={handleReset}
      />
      <Card>
        <div className="flex justify-between mb-2">
      <h1>Search Results</h1>
      {role !== 'nurse' && role !=='doctor' &&  <Button type="primary">Download Report</Button>}
      </div>
      <CustomTable
        loading={loading}
        columns={column1}
        dataSource={guestCheckoutReports}
        key="guestcheckout_report"
      />
      </Card>
      <PrescriptionPad
        visible={drawerOpen}
        onClose={handleCloseDrawer}
        rxData={rxData}
        clinic_information={clinicInfo}
      />
      <GuestCheckoutView visible={view} data={viewRecord} onClose={closeView}/>
    </>
  );
};

export default GuestCheckoutReports;
