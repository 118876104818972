import React, { useEffect, useState } from "react";
import { Select, Form, Row, Col, Input, Button, message } from "antd";
import { useStateGlobalContext } from "../../../../../../contexts/GlobalContext";
import { doPatch } from "../../../../../../API/apis";
import { useParams } from "react-router-dom";
import Loader from "../../../../../../components/Loader/Loader";
import Notification from "../../../../../../components/Notification/Notification";

const { Option } = Select;

const Demographics = () => {
  const { id } = useParams();
  const [form] = Form.useForm();
  const [edit, setEdit] = useState(false);
  const { patientBasicInfo, getPatientData, loading } = useStateGlobalContext();

  const { race, ethnicity, religion, languages, demographics_notes } =
    patientBasicInfo || {};

  useEffect(() => {
    const fetchData = async () => {
      await getPatientData(id);
      if (patientBasicInfo) {
        form.setFieldsValue({
          race,
          ethnicity,
          languages,
          religion,
          demographics_notes,
        });
      }
    };

    fetchData();
  }, [id]);

  const onEdit = () => {
    setEdit(true);
  };

  const onEditCancel = () => {
    setEdit(false);
  };

  const handleUpdate = async (values) => {
    try {
      const response = await doPatch(`/patient/update-info/${id}/`, values);
      if (response.status === 200) {
        Notification.success("Patient Profile is Updated Successfully");
        await getPatientData(id);
        onEditCancel();
      }
    } catch (error) {
      console.log(error);
    }
  };

  if (loading) {
    return <Loader />;
  }

  return (
    <div className="wrapper shadow-class">
      <div className="flex justify-between items-center">
        <h1 className="text-[1.125rem] font-bold mb-4">Demographics</h1>
        {edit ? (
          <Button onClick={onEditCancel}>Close</Button>
        ) : (
          <Button onClick={onEdit}>Edit</Button>
        )}
      </div>

      {edit ? (
        <Form layout="vertical" form={form} onFinish={handleUpdate}>
          <Row gutter={{ xs: 10, md: 15, xl: 30 }}>
            <Col xs={24} md={12} lg={12}>
              <Form.Item label="Specify Race" name="race">
                <Select defaultValue="notAsked">
                  <Option value="americanIndian">
                    American Indian or Alaska Native
                  </Option>
                  <Option value="asian">Asian</Option>
                  <Option value="black">Black or African American</Option>
                  <Option value="nativeHawaiian">
                    Native Hawaiian or Other Pacific Islander
                  </Option>
                  <Option value="white">White</Option>
                  <Option value="other">Other</Option>
                  <Option value="decline">Decline to specify</Option>
                  <Option value="notAsked">Provider didn't ask</Option>
                </Select>
              </Form.Item>
            </Col>
            <Col xs={24} md={12} lg={12}>
              <Form.Item label="Ethnicity" name="ethnicity">
                <Select defaultValue="notAsked">
                  <Option value="hispanic">Hispanic or Latino </Option>
                  <Option value="notHispanic">Not Hispanic or Latino </Option>
                  <Option value="decline">Decline to Specify </Option>
                  <Option value="notAsked"> Provider didn't Ask </Option>
                </Select>
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={[16, 16]}>
            <Col xs={24} md={12} lg={12}>
              <Form.Item label="Religion" name="religion">
                <Input placeholder="Religion" maxLength={30} />
              </Form.Item>
            </Col>
            <Col xs={24} md={12} lg={12}>
              <Form.Item label="Preferred Languages" name="languages">
              <Input placeholder="Enter each language with comma ," maxLength={100} />
              </Form.Item>
            </Col>
          </Row>
          <Form.Item label="Notes" name="demographics_notes">
            <Input.TextArea placeholder="Notes..." maxLength={300} showCount />
          </Form.Item>

          <div className="flex justify-end">
            <Button onClick={onEditCancel}>Cancel Changes</Button>
            <Button type="primary" className="ml-2" htmlType="submit">
              Save Changes
            </Button>
          </div>
        </Form>
      ) : (
        <div className="view-wrapper">
          <p>
            Race:{" "}
            {race === "americanIndian"
              ? "American Indian or Alaska Native"
              : race === "asian"
              ? "Asian"
              : race === "black"
              ? "Black or African American"
              : race === "nativeHawaiian"
              ? "Native Hawaiian or Other Pacific Islander"
              : race === "white"
              ? "White"
              : race === "other"
              ? "Other" 
              : race === 'decline'
              ? "Decline to Specify"
              : "Provider didn't ask"}
          </p>
          <p>
            Ethnicity:{" "}
            {ethnicity === "hispanic"
              ? "Hispanic or Latino"
              : ethnicity === "notHispanic"
              ? "Not Hispanic or Latino"
              : ethnicity === "decline"
              ? "Decline to Specify"
              : "Provider didn't ask"}
          </p>
          <p>Religion: {religion}</p>
          <p>Preferred Languages: {languages}</p>
          <p className="notes">Notes: {demographics_notes}</p>
        </div>
      )}
    </div>
  );
};

export default Demographics;
