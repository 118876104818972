import {
  PlusOutlined,
  EditOutlined,
  DeleteOutlined,
} from "@ant-design/icons";
import { useParams } from "react-router-dom";
import React, { useEffect, useState } from "react";
import { Modal, Form, Input, Button,  Spin } from "antd";
import { doPost, doGet, doPatch, doDelete } from "../../../../../API/apis";
import MedicalCardsTitle from "../MedicalCardsTitle";
import showConfirmModal from "../../../../../components/ModalConfirm/ModalConfirm";
import Notification from "../../../../../components/Notification/Notification";
import FormatDateTime from "../../../../../components/FormatDate/FormatDateTime";


function PatientGoals( ) {
  const { id } = useParams();
  const [form] = Form.useForm();
  const [data, setData] = useState([]);
  const [visible, setVisible] = useState(false);
  const [editMode, setEditMode] = useState(null);
  const [loading, setLoading] = useState(false);
  const [selectedItem, setSelectedItem] = useState(null);
  const [hoveredItemId, setHoveredItemId] = useState(null);
  const [patientGoalsVisible, setPatientGoalsVisible] = useState(false);

  const openModal = () => {
    setVisible(true);
  };

  const handleCancel = () => {
    setVisible(false);
    setEditMode(false);
    setPatientGoalsVisible(false);
    form.resetFields();
  };

  const handleText = (record) => {
    setSelectedItem(record);
    setPatientGoalsVisible(true);
  };

  const handleEdit = (record) => {
    setSelectedItem(record);
    setEditMode(true);
    form.setFieldsValue({
      patient_goals: record.patient_goals,
    });
    setVisible(true);
  };

  const handleImportantNotes = async (values) => {
    setLoading(true)
    const data = {
      patient_goals: values.patient_goals,
    };
    try {
      const response = editMode ?  await doPatch(
          `/patient/update-patient-goals/${id}/${selectedItem?.id}/`,
          data,
        ) : await doPost(
          `/patient/add-patient-goals/${id}/`,
          data,
        );
      if (response.status === 200 || response.status === 201) {
        const successMessage = editMode
          ? "Patient Goal updated successfully"
          : "Patient Goal added successfully";
        Notification.success(successMessage);
        getPatientGoals();
        handleCancel();
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    } finally{
      setLoading(false)
    }
  };

  const getPatientGoals = async () => {
    setLoading(true);
    try {
      const response = await doGet(
        `/patient/get-patient-goals/${id}/`,
      );
      if (response.status === 200) {
        setData(response?.data.reverse());
      } 
    } catch (error) {
      console.error("Error fetching data:", error);
    } finally{
      setLoading(false)
    }
  };

  const handleDelete = (record) => {
    showConfirmModal({
      title: "Confirm Delete?",
      icon: null,
      content: "Are you sure you want to delete this?",
      okText: "Delete",
      okType: "danger",
      cancelText: "Cancel",
      className: "custom-modal",
      onOk() {
        deletePatientGoal(record);
      },
    });
  };

  const deletePatientGoal = async (record) => {
    try {
      const response = await doDelete(
        `/patient/delete-patient-goals/${id}/${record}/`,
      );
      if (response.status === 200) {
        Notification.success("Patient Goals deleted successfully");
        getPatientGoals();
      } else {
        Notification.error("Something went wrong");
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };
  useEffect(() => {
    getPatientGoals();
  }, []);

  return (
    <div>
      <div className="shadow-class p-4 border rounded-md  h-[180px]  max-h-[180px]">
        <div className="flex items-center justify-between">
          <MedicalCardsTitle title='Patient Health Goals'/>
          <Button type="primary" size="small" shape='round' onClick={openModal}>Add <PlusOutlined  /></Button>
        </div>
        {loading ? (
          <div className="flex justify-center items-center">
            <Spin size="small" />
          </div>
        ) : (
          <div className="flex max-h-[130px]  overflow-auto mt-1">
            <ul>
              {data.map((item, index) => (
                <li
                  key={item.id}
                  className="text-[#1890ff] cursor-pointer flex justify-between items-center"
                  onMouseEnter={() => setHoveredItemId(item.id)}
                  onMouseLeave={() => setHoveredItemId(null)}
                >
                  <span
                    onClick={() => {
                      handleText(item);
                    }}
                    title={item?.patient_goals}
                  >
                    {index + 1}. {item?.patient_goals?.slice(0, 26)}
                    {item.patient_goals?.length > 26 ? "..." : ""}
                  </span>

                  {hoveredItemId === item.id && (
                    <div className="flex items-center ml-3">
                      <EditOutlined
                        className="text-[#1890ff] mr-2"
                        onClick={() => {
                          handleEdit(item);
                        }}
                      />
                      <DeleteOutlined
                        className="text-[#1890ff]"
                        onClick={() => {
                          handleDelete(item.id);
                        }}
                      />
                    </div>
                  )}
                </li>
              ))}
              {data.length === 0 && (
                <li className="flex justify-between items-center">
                  <span>No Patient Goals Added</span>
                </li>
              )}
            </ul>
          </div>
        )}
      </div>

      <Modal
        title="Patient Health Goals"
        open={visible}
        onCancel={handleCancel}
        closeIcon={
          <Button size="small" className="app-close-icon" shape="round">Close</Button>
        }
        footer={null}
        maskClosable={false}
        width={650}
      >
        <Form
          layout="vertical"
          name="patient-goals"
          form={form}
          onFinish={handleImportantNotes}
        >
          <Form.Item name="patient_goals" label="Patient Goals">
            <Input.TextArea rows={4} maxLength={500} showCount />
          </Form.Item>
          <div className="flex justify-end">
            <Button onClick={handleCancel}>Cancel</Button>
            <Button type="primary" htmlType="submit" className="ml-2" loading={loading}>
              {editMode ? "Update" : "Save"}
            </Button>
          </div>
        </Form>
      </Modal>

      <Modal
        title="Patient Health Goals"
        open={patientGoalsVisible}
        onCancel={handleCancel}
        maskClosable={false}
        footer={null}
        width={650}
        closeIcon={
          <Button size="small" className="app-close-icon" shape="round">Close</Button>
        }
      >
        {selectedItem && (
          <>
            <p>
              <span className="font-medium">Description:</span>
              <span className="ml-2">{selectedItem?.patient_goals}</span>
            </p>
            <p>
              <span className="font-medium">Added By:</span>
              <span className="ml-2">{selectedItem?.added_by}</span>
            </p>
            <p>
              <span className="font-medium">Date:</span>
              <span className="ml-2">
                <FormatDateTime date={selectedItem?.date_time} type="datetime"/>
              </span>
            </p>
          </>
        )}
      </Modal>
    </div>
  );
}

export default PatientGoals;
