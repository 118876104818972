import React, { useEffect, useState } from "react";
import { useStateGlobalContext } from "../../../../contexts/GlobalContext";
import { ageCalculator } from "../../../../utils/ageCalculator";
import { useParams } from "react-router-dom";
import FormatDate from "../../../../components/FormatDate/FormatDate";
import Loader from "../../../../components/Loader/Loader";

const NotesHeader = ({ showDetails, patientRecord }) => {
  const { id } = useParams();
  const [loading, setLoading] = useState(true);
  const { patientBasicInfo, getPatientData, patientAllergies, getAllergies } =
    useStateGlobalContext();

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      try {
        await Promise.all([
          getPatientData(id || patientRecord),
          getAllergies(id || patientRecord),
        ]);
      } catch (error) {
        Notification.error("Failed to fetch data");
      } finally {
        setLoading(false);
      }
    };
    fetchData();
  }, [id || patientRecord]);

  const {
    first_name,
    middle_name,
    last_name,
    date_of_birth,
    sex,
    other_Gender,
    gender,
    employment,
    employment_status,
    other_Employment_Status,
    marital_status,
    patient_email,
    patient_phone,
    current_address_line_1,
    current_address_line_2,
    current_city,
    current_state,
    current_country,
    current_zipcode,
  } = patientBasicInfo || {};

  const patientFullName =
    `${first_name || ""} ${middle_name ? middle_name : " "} ${
      last_name || ""
    }` || "";

  const patientGender = sex === "other" ? other_Gender : gender;
  const age = ageCalculator(date_of_birth);
  const employment_Status =
    employment_status === "other" ? other_Employment_Status : employment_status;

  if(loading){
    return <Loader/>
  }

  return (
    <div className="flex justify-between bg-[#C4dBFA] p-4 rounded-lg mb-2">
      <div className="w-1/2">
        <p>
          <span className="font-medium">Name: </span>
          <span>{patientFullName}</span>
        </p>

        <p>
          <span className="font-medium">Marital Status: </span>
          <span>{marital_status}</span>
        </p>
        {showDetails && (
          <p>
            <span className="font-medium">Full Address: </span>{" "}
            {`${current_address_line_1 || ""} ${
              current_address_line_2 ? `, ${current_address_line_2}` : ""
            } ${current_city ? `, ${current_city}` : ""} ${
              current_state ? `, ${current_state}` : ""
            } ${current_country ? `, ${current_country}` : ""}`}{" "}
          </p>
        )}
        {showDetails && (
          <p>
            <span className="font-medium">Phone: </span> {patient_phone}
          </p>
        )}
        {showDetails && (
          <p>
            <span className="font-medium">Email: </span>
            {patient_email}
          </p>
        )}
      </div>

      <div className="w-1/2">
        <p>
          <span className="font-medium">Gender: </span>
          <span className="mr-2">{patientGender}</span>|
          <span className="ml-2 mr-2">{age} y/o</span>|
          <span className="ml-2">
            <FormatDate date={date_of_birth} />{" "}
          </span>
        </p>

        <p>
          <span className="font-medium">Allergies: </span>
          <span className="text-red-600 font-medium">
            {patientAllergies?.length > 0
              ? patientAllergies
                  .map((allergy) => allergy?.allergy_value)
                  .join(", ")
              : "None"}
          </span>
        </p>
        <p>
          <span className="font-medium">Employment Status: </span>
          <span>{employment}</span>
        </p>
      </div>
    </div>
  );
};

export default NotesHeader;
