import React, {useEffect} from 'react'
import { useNavigate } from 'react-router-dom';
import { getSession, clearSession } from '../customHooks/useSession';
import { useIdle } from "@uidotdev/usehooks";

function Protected(props) {
  const {Component} = props;
  const navigate = useNavigate()
  const idle = useIdle(1800000);


  useEffect(()=>{
    const token = getSession("token");
    if(!token){
        navigate("/")
    }
  },[])

  useEffect(() => {
    if (idle) {
      clearSession()
      localStorage.clear()
      sessionStorage.clear()
    } 
  }, [idle]);

  return getSession("token") && (
    <div>
        <Component/>
    </div>
  )
}

export default Protected