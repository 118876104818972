import { Card, Form,  Button} from "antd";
import React, { useEffect, useState } from "react";
import { doGet } from "../../../../../API/apis";
import { useOutletContext } from "react-router-dom";
import { formatDate, formatTime } from "../../../../../utils/DateFormat";
import { useStateGlobalContext } from "../../../../../contexts/GlobalContext";
import { CSVLink } from "react-csv";
import Notification from "../../../../../components/Notification/Notification";
import CustomTable from "../../../../../components/CustomTable/CustomTable";
import DateRangePickerCard from "../../../../../components/GlobalComponents/DateRangePickerCard";
import FormatDateTime from "../../../../../components/FormatDate/FormatDateTime";


const AuditReports = () => {
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState([]);
  const [selectedDateRange, setSelectedDateRange] = useState(null);
  const [searchClicked, setSearchClicked] = useState(false);
  const { profile } = useStateGlobalContext();
  const [officeDateFormat, officeTimeFormat, officeTimezone] =
  useOutletContext()


  const transformedData = (records) => {
    if (!records || !Array.isArray(records)) {
      return [];
    }
  
    return records.map((record) => {
      // Transform action field
      let action = record?.action;
      if (action.includes("PATCH")) {
        action = "Update";
      } else if (action.includes("POST")) {
        action = "Login Request";
      } else if (action.includes("DELETE")) {
        action = "Delete";
      }
  
      // Transform details field
      let details = record?.details;
      if (details.includes("Request: PATCH")) {
        details = details.replace("Request: PATCH :", "").trim();
      } else if (details.includes("Request: DELETE")) {
        details = details.replace("Request: DELETE :", "").trim();
      }
  
      return {
        ID: record?.id,
        Name: record?.name,
        Role: record?.roles,
        Username: record?.username,
        Action: action,
        Details: details,
        Date: formatDate(record?.created_at, officeDateFormat, officeTimezone),
        Time: formatTime(record?.created_at, officeTimeFormat, officeTimezone),
      };
    });
  };
  

  const role = profile && profile?.user_info[0]?.role;


  const handleDateChange = (dates) => {
    setSelectedDateRange(dates);
  };

  const handleReset = ()=>{
    setData([])
  }

  const handleSearch = () => {
    if (selectedDateRange && selectedDateRange.length === 2) {
      setSearchClicked(true);
    } else {
      Notification.warning("Please select a date range");
    }
  };

  const column = [
    {
      key: 2,
      title: "Name",
      dataIndex:"name",
      searchable: true,
    },
    {
      key: 3,
      title: "Role",
      dataIndex:"roles"
    },
    {
      key: 4,
      title: "Username",
      dataIndex:"username"
    },
    {
      key: 5,
      title: "Action Performed",
      dataIndex:"action",
      searchable: true,
    },
    {
        key: 6,
        title: "Details",
        dataIndex: "details",
        searchable: true,
        render: (text) => {
          if (text.includes("Request: PATCH")) {
            return text.replace("Request: PATCH :", "").trim();
          } else if (text.includes("Request: DELETE")) {
            return text.replace("Request: DELETE :", "").trim();
          } else if (text.includes("Request: POST :")) {
            return text.replace("Request: POST :", "").trim();
          }
          return text;
        },
      },
    {
      key: 7,
      title: "Date Time",
      dataIndex:"created_at",
      render:(text)=> <FormatDateTime date={text} type="datetime"/>
    },
  ];

  const getAuditReports = async () => {
    setLoading(true);
    try {
      if (selectedDateRange && selectedDateRange.length === 2) {
        const startDate = selectedDateRange[0].format("YYYY-MM-DD");
        const endDate = selectedDateRange[1].format("YYYY-MM-DD");

        const response = await doGet(
          `/reports/audit-log-report/?start_date=${startDate}&end_date=${endDate}`
        );
        if (response.status === 200) {
          setData(response.data);
        }
      } else {
        Notification.warning("Please select a valid date range");
      }
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (searchClicked) {
      getAuditReports();
      setSearchClicked(false);
    }
  }, [searchClicked, selectedDateRange]);

  return (
    <div>
      <h1 className="font-bold text-lg mb-2">Audit Reports </h1>
      <DateRangePickerCard
        onDateChange={handleDateChange}
        onSearch={handleSearch}
        onReset={handleReset}
      />
      <Card className="shadow-class">
      <div className="flex justify-between items-center mb-2">
          <h1>Search Results</h1>
          {role !== 'nurse' && role !=='doctor' &&  data?.length > 0 && <Button  type="primary">
            <CSVLink
              data={transformedData(data)}
              filename={"Audit_Report.csv"}
              className="csv-link"
            >
              Download Report
            </CSVLink>
          </Button>}
        </div>
      <CustomTable
        loading={loading}
        columns={column}
        dataSource={data}
        scroll={{
          x: 1500,
        }}
        key="audit_reports"
      />
      </Card>
    </div>
  );
};

export default AuditReports;
