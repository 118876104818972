import { Drawer, Table } from "antd";
import React, { useRef } from "react";
import "./prescriptionpad.css";
import { useReactToPrint } from "react-to-print";
import ClinicInformation from "../../../../components/PrescriptionsPad/ClinicInformation";
import LabelDisplay from "../../../../components/LabelDisplay/LabelDisplay";
import { NumberFormatter } from "../../../../utils/validator";
import FormatDateTime from "../../../../components/FormatDate/FormatDateTime";
import { Status } from "../../../../utils/Data";

const coloumn = [
  {
    key: 1,
    title: "#",
    render: (_, record, index) => index + 1,
  },
  {
    key: 2,
    title: "Product Name",
    dataIndex: "items",
  },
  {
    key: 3,
    title: "Quanity",
    dataIndex: "quantity",
    align:"center"
  },
  {
    key: 4,
    title: "Price",
    dataIndex: "price",
    render:(price)=> <NumberFormatter value={price}/>
  },
];

const PrescriptionPad = ({ visible, onClose, rxData, clinic_information }) => {
  const contentRef = useRef(null);
  const handlePrint = useReactToPrint({
    content: () => contentRef.current,
  });
  return (
    <Drawer
      title={rxData?.patient_name}
      open={visible}
      placement="bottom"
      height="100vh"
      onClose={onClose}
      headerStyle={{
        position: "fixed",
        backgroundColor: "white",
        width: "100%",
        top: 0,
        boxShadow: "0px 0px 5px 0px rgba(0,0,0,0.25)",
      }}
      className="prescription-drawer"
    >
      <div className="invoice-print">
        <div className="invoice-container">
          <button onClick={handlePrint} className="guest-button">
            Print Document
          </button>
          <div className="invoice-content" ref={contentRef}>
          <h2 className="text-center mt-4 mb-4">Invoice</h2> 
          <div className="flex justify-center gap-4">
          <LabelDisplay title={"Invoice ID"} description={rxData?.invoice_id}/>
          <LabelDisplay title={"Status"} description={<Status status ={rxData?.status}/>}/>
          </div>
          <div className="flex justify-between">
            <div className="text-left">
              <h3>Clinic Information</h3>
              {ClinicInformation(clinic_information, "text-left")}
            </div>
            <div className="text-left">
              <h3>Customer Information </h3>
              <LabelDisplay title={"Name"} description={rxData?.patient_name}/>
              <LabelDisplay title={"Date"} description={<FormatDateTime date={rxData?.issue_date} type="datetime"/>}/>
            </div>
          </div>
          
          <hr className="border-1 mb-2" />
          <div className="print-content">

            <p className="font-bold">Medication</p>
            <Table
              className="mt-2 mb-10"
              size="small"
              columns={coloumn}
              dataSource={rxData?.product_details}
              pagination={false}
            />

            <div className="flex flex-col items-end p-2 prescription-total">
              <LabelDisplay title={"Sub Total"} description={<NumberFormatter value={rxData?.price}/>}/>
              <LabelDisplay title={`Discount ${rxData?.discount}%`} description={<NumberFormatter value={rxData?.discounted_amount}/>}/>
              <LabelDisplay title={"Tax"} description={<NumberFormatter value={rxData?.tax}/>}/>
              <LabelDisplay title={"Grand Total"} description={<NumberFormatter value={rxData?.grand_total}/>}/>

            </div>
            <p className="guest-notes font-medium">Notes:</p>
            <p>{rxData?.notes}</p>

            <div className="flex justify-end flex-col items-end mt-10 signature">
              <p>_______________________________</p>
              <h3>Signature</h3>
            </div>
            </div>
          </div>
        </div>
      </div>
    </Drawer>
  );
};

export default PrescriptionPad;
