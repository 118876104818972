import React, { useEffect, useState } from "react";
import {  Card, Input, Col } from "antd";
import { doGet } from "../../../../API/apis";
import { useNavigate } from "react-router-dom";
import Notification from "../../../../components/Notification/Notification";
import { RecentPatientsColumns } from "../../../../components/TableColumns/TableColumns";
import CustomTable from "../../../../components/CustomTable/CustomTable";

const { Search } = Input;

const RecentPatients = () => {
  const navigate = useNavigate();
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [filterData, setFilterData] = useState([]);

  const getPatients = async () => {
    setLoading(true);
    try {
      const response = await doGet(`/patient/patient-details/?q=recent`);
      if (response.status === 200) {
        const sortedData = response.data.sort((a, b) => {
          return new Date(b.registration_date) - new Date(a.registration_date);
        });
        setData(sortedData);
        setFilterData(sortedData);
      }
    } catch (err) {
      Notification.error("Error fetching data");
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    getPatients();
  }, []);

  const handleSearch = (value) => {
    const filteredData = data.filter((record) => {
      const fullName = `${record?.first_name} ${
        record?.middle_name ? record?.middle_name : ""
      } ${record?.last_name}`;
      return fullName?.toLowerCase().includes(value.toLowerCase());
    });
    setFilterData(filteredData);
  };

  return (
    <Col xs={24} md={24} lg={8} xl={8} className="mb-4">
      <Card className="shadow-md max-h-screen">
        <div className="flex justify-between items-center mb-2">
          <h1 className="font-bold text-lg">Recently Added</h1>
          <Search
            placeholder="Search Patient"
            onChange={(e) => handleSearch(e.target.value)}
            onSearch={(value) => handleSearch(value)}
            className="w-[200px]"
          />
        </div>

        <CustomTable
          columns={RecentPatientsColumns(navigate)}
          dataSource={filterData}
          loading={loading}
          key="recent_patients"
          indexColWidth="20%"
        />
      </Card>
    </Col>
  );
};

export default RecentPatients;
