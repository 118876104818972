import { useParams } from "react-router-dom";
import React, { useState, useEffect } from "react";
import MedicalCardsTitle from "../MedicalCardsTitle";
import { PlusOutlined, EditOutlined } from "@ant-design/icons";
import { doPost, doPatch} from "../../../../../API/apis";
import { Button, Form, Input, Modal, Spin, Row, Col } from "antd";
import { phoneValidator, emailValidator } from "../../../../../utils/validator";
import { useStateGlobalContext } from "../../../../../contexts/GlobalContext";
import Notification from "../../../../../components/Notification/Notification";
import FormatDateTime from "../../../../../components/FormatDate/FormatDateTime";

const { TextArea } = Input;



const PrefferedLab = ()=> {
  const { id } = useParams();
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);
  const [visible, setVisible] = useState(false);
  const [editLab, setEditLab] = useState(null);
  const {preferredLab: data, getPreferredLab} = useStateGlobalContext()

  const openModal = () => {
    setVisible(true);
  };

  const handleCancel = () => {
    setVisible(false);
    form.resetFields();
  };

  const handleEdit = (data) => () => {
    setEditLab(data[0]?.id);
    form.setFieldsValue({
      lab_name: data[0]?.name,
      address: data[0]?.address,
      phone_number: data[0]?.phone,
      fax_number: data[0]?.fax,
      email: data[0]?.email,
      preffered_lab_notes: data[0]?.pref_labs_notes,
    });
    setVisible(true);
  };

  const handlePrefferedLab = async (values) => {
    setLoading(true)
    const data = {
      name: values.lab_name,
      address: values.address,
      phone: values.phone_number,
      fax: values.fax_number,
      email: values.email,
      pref_labs_notes: values.preffered_lab_notes,
    };
    try {
      const response = editLab
        ? await doPatch(
            `/patient/update-prefered-labs/${id}/${editLab}/`,
            data,
          )
        : await doPost(`/patient/add-prefered-labs/${id}/`, data);
      if (response.status === 201 || response.status === 200) {
        editLab
          ? Notification.success("Preffered Lab Updated Successfully")
          : Notification.success("Lab Added Successfully");
        handleCancel();
        getPreferredLab(id);
      }
    } catch (error) {
      console.log(error);
    }finally{
      setLoading(false)
    }
  };


  useEffect(() => {
    getPreferredLab(id);
  }, []);

  return (
    <div>
      <div className="shadow-class p-4 border rounded-md  h-[180px]  max-h-[180px]">
        <div className="flex items-center justify-between">
          <MedicalCardsTitle title="Preferred Lab" />
          {data && data?.length === 0 ? (
            <Button
              type="primary"
              size="small"
              shape="round"
              onClick={openModal}
            >
              Add <PlusOutlined />
            </Button>
          ) : (
            <Button
              type="default"
              size="small"
              shape="round"
              onClick={handleEdit(data)}
            >
              Edit <EditOutlined />
            </Button>
          )}
        </div>
        {loading ? (
          <div className="flex justify-center items-center h-[130px]">
            <Spin />
          </div>
        ) : (
          <div className="flex flex-col max-h-[130px]  overflow-auto mt-1">
            <span>
              <span className="font-medium">Lab Name: </span>{data && data[0]?.name}
            </span>
            <span>
              <span className="font-medium">Address: </span>{data && data[0]?.address}
            </span>
            <span>
              <span className="font-medium">Phone: </span>{data && data[0]?.phone}
            </span>
            <span>
              <span className="font-medium">Fax Number: </span>{data && data[0]?.fax}
            </span>
            <span>
              <span className="font-medium">Email: </span>{data &&data[0]?.email}
            </span>

            <span>
              <span className="font-medium">Notes: </span>{data && data[0]?.pref_labs_notes}
            </span>

            <span>
              <span className="font-medium">Added By: </span>{data && data[0]?.added_by}
            </span>
            <span>
              <span className="font-medium">Date: </span>
              {data && <FormatDateTime type="datetime" date={data[0]?.date_time}/>}
            </span>
          </div>
        )}
      </div>

      <Modal
        title={editLab ? "Update Preferred Lab": "Add Preferred Lab"}
        open={visible}
        maskClosable={false}
        onCancel={handleCancel}
        closeIcon={
          <Button size="small" className="app-close-icon" shape="round">Close</Button>
        }
        footer={null}
      >
        <Form layout="vertical" form={form} onFinish={handlePrefferedLab}>
          <Form.Item
            name="lab_name"
            label="Lab Name"
            rules={[
              {
                required: true,
                message: "Please Enter Lab Name!",
              },
            ]}
          >
            <Input placeholder="Lab Name" size="medium" maxLength={30} />
          </Form.Item>
          <Form.Item name="address" label="Address">
            <TextArea
              placeholder="Address"
              rows={3}
              showCount
              maxLength={300}
            />
          </Form.Item>

          <Row gutter={16}>
            <Col span={12}>
              <Form.Item
                name="phone_number"
                label="Phone Number"
                rules={[
                  {
                    validator: phoneValidator,
                  },
                ]}
              >
                <Input
                  placeholder="Phone Number"
                  size="medium"
                  maxLength={30}
                />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item name="fax_number" label="Fax Number">
                <Input placeholder="Fax Number" size="medium" maxLength={30} />
              </Form.Item>
            </Col>
          </Row>
          <Form.Item
            name="email"
            label="Email"
            rules={[
              {
                validator: emailValidator,
              },
            ]}
          >
            <Input placeholder="Email" size="medium" maxLength={30} />
          </Form.Item>
          <Form.Item name="preffered_lab_notes" label="Notes">
            <TextArea placeholder="Notes" rows={3} maxLength={300} showCount />
          </Form.Item>

          <div className="flex justify-end">
            <Button onClick={handleCancel}>Cancel</Button>
            <Button type="primary" htmlType="submit" className="ml-2" loading={loading}>
              {editLab ? "Update" : "Save"}
            </Button>
          </div>
        </Form>
      </Modal>
    </div>
  );
}

export default PrefferedLab;
