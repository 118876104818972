import React from "react";
import LabelDisplay from "../LabelDisplay/LabelDisplay";

const ClinicInformation = (officeInformation, className) => {
  if (!officeInformation || typeof officeInformation !== "object") {
    return <p>No clinic information available.</p>;
  }

  // Helper function to check if the first index is true
  const shouldDisplay = (data) => Array.isArray(data) && data[1] === true;

  return (
    <div className={className}>
        <LabelDisplay
          title={"Facility Name"}
          description={officeInformation.facility_name[0]}
        />
      {shouldDisplay(officeInformation.speciality_type) && (
        <LabelDisplay
          title={"Practice Speciality"}
          description={officeInformation.speciality_type[0]}
        />
      )}
      {shouldDisplay(officeInformation.facility_address) && (
        <LabelDisplay
          title={"Facility Address"}
          description={officeInformation.facility_address[0]}
        />
      )}
      {shouldDisplay(officeInformation.city) && (
        <LabelDisplay title={"City"} description={officeInformation.city[0]} />
      )}
      {shouldDisplay(officeInformation.state) && (
        <LabelDisplay
          title={"State"}
          description={officeInformation.state[0]}
        />
      )}
      {shouldDisplay(officeInformation.country) && (
        <LabelDisplay
          title={"Country"}
          description={officeInformation.country[0]}
        />
      )}
      {shouldDisplay(officeInformation.zipcode) && (
        <LabelDisplay
          title={"Zip Code"}
          description={officeInformation.zipcode[0]}
        />
      )}
      {shouldDisplay(officeInformation.office_phone) && (
        <LabelDisplay
          title={"Office Phone"}
          description={officeInformation.office_phone[0]}
        />
      )}
      {shouldDisplay(officeInformation.office_fax) && (
        <LabelDisplay
          title={"Office Fax"}
          description={officeInformation.office_fax[0]}
        />
      )}
      {shouldDisplay(officeInformation.buisness_email) && (
        <LabelDisplay
          title={"Business Email"}
          description={officeInformation.buisness_email[0]}
        />
      )}
      {shouldDisplay(officeInformation.additional_basic_data_1) && (
        <LabelDisplay
          title={"Additional Data 1"}
          description={officeInformation.additional_basic_data_1[0]}
        />
      )}
      {shouldDisplay(officeInformation.additional_basic_data_2) && (
        <LabelDisplay
          title={"Additional Data 2"}
          description={officeInformation.additional_basic_data_2[0]}
        />
      )}
      {shouldDisplay(officeInformation.additional_basic_data_3) && (
        <LabelDisplay
          title={"Additional Data 3"}
          description={officeInformation.additional_basic_data_3[0]}
        />
      )}
      {shouldDisplay(officeInformation.exam_rooms) && (
        <div>
          <LabelDisplay title={"Exam Rooms"} description={""} />
          <ul>
            {Object.entries(officeInformation.exam_rooms).map(
              ([key, value]) => (
                <li key={key}>
                  {key}: {value}
                </li>
              )
            )}
          </ul>
        </div>
      )}
      {shouldDisplay(officeInformation.operating_hrs) && (
        <LabelDisplay
          title={"Operating Hours"}
          description={`${officeInformation.operating_hrs[0].start_time} to ${officeInformation.operating_hrs[0].end_time}`}
        />
      )}
    </div>
  );
};

export default ClinicInformation;
