import React from "react";
import { Button, Dropdown, Select, Tooltip } from "antd";
import FormatDate from "../FormatDate/FormatDate";
import { ageCalculator } from "../../utils/ageCalculator";
import { NumberFormatter } from "../../utils/validator";
import {
  DeleteOutlined,
  DownOutlined,
  EditOutlined,
  EllipsisOutlined,
} from "@ant-design/icons";
import FormatDateTime from "../FormatDate/FormatDateTime";
import { InvoiceStatus, StatusBadge } from "../GlobalComponents/StatusLabels";
import { encounter_Status } from "../../utils/Data";

const { Option } = Select;

const fullName = ({ first_name, middle_name, last_name }) => {
  return `${first_name} ${middle_name ? middle_name : ""} ${last_name}`;
};

const recentEncountersColumns = (navigate) => [
  {
    key: 2,
    title: "Full Name",
    align: "center",
    dataIndex: "first_name",
    searchable: true,
    render: (_, record) => <span>{fullName(record)}</span>,
  },
  {
    key: 3,
    title: "Gender",
    align: "center",
    dataIndex: "gender",
    width: "10%",
  },
  {
    key: 4,
    title: "Age",
    align: "center",
    ellipsis: true,
    dataIndex: "date_of_birth",
    width: "10%",
    render: (text) => (
      <Tooltip title={<FormatDate date={text} type="time" />}>
        <span>{ageCalculator(text)} y/o</span>
      </Tooltip>
    ),
  },
  {
    key: 6,
    title: "Service Name",
    dataIndex: "service_name",
    ellipsis: true,
  },
  {
    key: 7,
    title: "Note Type",
    dataIndex: "note_type",
    width: "30%",
    ellipsis: true,
    render: (text, record) => (
      <Button
        size="small"
        type="link"
        onClick={() => {
          navigate(`/main/charts/patient/${record?.patient_id}/visits`);
        }}
      >
        {text}
      </Button>
    ),
  },
  {
    key: 8,
    title: "Status",
    dataIndex: "status",
    align: "center",
    filters: encounter_Status.map((text) => ({
      text: text.label,
      value: text.label,
    })),
    onFilter: (value, record) =>
      record?.status?.toLowerCase().includes(value.toLowerCase()),
    defaultFilteredValue: ["Draft", "Signed"], // Set default filters
    render: (status) => <InvoiceStatus status={status} />,
  },
];

const RecentPatientsColumns = (navigate) => [
  {
    key: 2,
    title: "Full Name",
    dataIndex: "first_name",
    searchable: true,
    render: (_, record) => (
      <Button
        size="small"
        type="link"
        onClick={() => navigate(`/main/charts/patient/${record?.id}/`)}
      >
        {fullName(record)}
      </Button>
    ),
  },
  {
    key: 3,
    title: "Age",
    align: "center",
    ellipsis: true,
    dataIndex: "date_of_birth",
    width: "20%",
    render: (text) => (
      <Tooltip title={<FormatDate date={text} type="time" />}>
        <span>{ageCalculator(text)} y/o</span>
      </Tooltip>
    ),
  },
];

const getServiceListColumns = ({ handleEdit, showDeleteConfirm }) => [
  {
    title: "Service Name",
    dataIndex: "service_names",
    key: "service_names",
    ellipsis: true,
    searchable: true,
  },
  {
    title: "Types",
    dataIndex: "service_type",
    key: "service_type",
    ellipsis: true,
    searchable: true,
  },
  {
    title: "Groups",
    dataIndex: "service_group_name",
    key: "service_group_name",
    ellipsis: true,
    searchable: true,
  },
  {
    title: "Price",
    dataIndex: "price",
    key: "price",
    align: "center",
    ellipsis: true,
    render: (text) => <NumberFormatter value={text} />,
  },
  {
    title: "Action",
    dataIndex: "action",
    key: "action",
    ellipsis: true,
    align: "center",
    render: (_, record) => (
      <div>
        <EditOutlined
          className="mr-5 text-[#1890ff]"
          onClick={() => handleEdit(record)}
        />
        <DeleteOutlined
          className="text-[#F44336]"
          onClick={() => showDeleteConfirm(record.id)}
        />
      </div>
    ),
  },
];

const getLabsListColumn = ({ handleEdit, showDeleteConfirm }) => [
  {
    title: "Lab Name",
    dataIndex: "service_names",
    key: "service_names",
    ellipsis: true,
    searchable: true,
  },
  {
    title: "Types",
    dataIndex: "service_type",
    key: "service_type",
    ellipsis: true,
    searchable: true,
  },
  {
    title: "Groups",
    dataIndex: "service_group_name",
    key: "service_group_name",
    ellipsis: true,
    searchable: true,
  },
  {
    title: "Price",
    dataIndex: "price",
    key: "price",
    align: "center",
    ellipsis: true,
    render: (text) => <NumberFormatter value={text} />,
  },
  {
    title: "Action",
    dataIndex: "action",
    key: "action",
    ellipsis: true,
    align: "center",
    render: (_, record) => (
      <div>
        <EditOutlined
          className="mr-5 text-[#1890ff]"
          onClick={() => handleEdit(record)}
        />
        <DeleteOutlined
          className="text-[#F44336]"
          onClick={() => showDeleteConfirm(record.id)}
        />
      </div>
    ),
  },
];

const getGroupColumn = ({ handleEdit, handleDelete }) => [
  {
    title: "Group Name",
    dataIndex: "group",
    key: "group",
    width: "80%",
    searchable: true,
  },
  {
    title: "Action",
    dataIndex: "action",
    key: "action",
    render: (_, record) => (
      <div>
        <EditOutlined
          className="mr-2 text-[#1890ff]"
          onClick={() => handleEdit(record)}
        />
        <DeleteOutlined
          className="text-[#F44336]"
          onClick={() => handleDelete(record)}
        />
      </div>
    ),
  },
];

const getServiceTypeColumn = ({ handleEdit, handleDelete }) => [
  {
    title: "Type Name",
    dataIndex: "service_type",
    key: "service_type",
    width: "80%",
    searchable: true,
    sortable: true,
  },
  {
    title: "Action",
    dataIndex: "action",
    key: "action",
    align: "center",
    render: (_, record) => (
      <div>
        <EditOutlined
          className="mr-2 text-[#1890ff]"
          onClick={() => handleEdit(record)}
        />
        <DeleteOutlined
          className="text-[#F44336]"
          onClick={() => handleDelete(record.id)}
        />
      </div>
    ),
  },
];

const getTasksColumn = ({
  providers,
  taskActions,
  handleUpdate,
  handleStatus,
  setViewTask,
}) => [
  {
    key: 1,
    title: "Assigned By",
    ellipsis: true,
    dataIndex: "assigned_by",
    filters: providers?.map((provider) => ({
      text: provider.name,
      value: `assignedBy_${provider.name}`,
    })),
    onFilter: (value, record) =>
      record?.assigned_by
        ?.toLowerCase()
        .includes(value.split("_")[1].toLowerCase()),
    render: (text) => <span>{text}</span>,
  },
  {
    key: 2,
    title: "Detail",
    ellipsis: true,
    dataIndex: "detail",
    render: (text, record) => (
      <Button
        type="link"
        onClick={() =>
          setViewTask({
            open: true,
            detail: record,
          })
        }
      >
        {text}
      </Button>
    ),
  },
  {
    key: 3,
    title: "Assigned To",
    ellipsis: true,
    width: "10%",
    dataIndex: "assigned_to",
    filters: providers?.map((provider) => ({
      text: provider.name,
      value: `assignedTo_${provider.name}`,
    })),
    onFilter: (value, record) =>
      record?.assigned_to
        ?.toLowerCase()
        .includes(value.split("_")[1].toLowerCase()),
    render: (text) => <span>{text}</span>,
  },
  {
    key: 4,
    title: "Assigned Date",
    ellipsis: true,
    align: "center",
    dataIndex: "entered_date",
    sorter: (a, b) => new Date(a.entered_date) - new Date(b.entered_date),
    render: (text) => (
      <Tooltip title={<FormatDateTime date={text} type="time" />}>
        <span>{text ? <FormatDateTime date={text} type="date" /> : ""}</span>
      </Tooltip>
    ),
  },
  {
    key: 5,
    title: "Status Date",
    ellipsis: true,
    width: "10%",
    align: "center",
    dataIndex: "status_updated_on",
    sorter: (a, b) =>
      new Date(a?.status_updated_on) - new Date(b?.status_updated_on),
    render: (text) => (
      <Tooltip title={<FormatDateTime date={text} type="time" />}>
        <span>{text ? <FormatDateTime date={text} type="date" /> : ""}</span>
      </Tooltip>
    ),
  },
  {
    key: 6,
    title: "Signed By",
    ellipsis: true,
    align: "center",
    dataIndex: "signed_by",
  },
  {
    key: 7,
    title: "Status",
    ellipsis: true,
    width: "8%",
    align: "center",
    dataIndex: "status",
    render: (text) => {
      const statusStyles = {
        "In Progress": ["bg-blue-200", "text-blue-500"],
        Completed: ["bg-green-200", "text-green-500"],
        "High Priority": ["bg-red-200", "text-red-500"],
        "To do": ["bg-yellow-200", "text-yellow-500"],
        "Need Help!": ["bg-orange-200", "text-orange-500"],
      };
      const [bgColor, textColor] = statusStyles[text] || [
        "bg-yellow-200",
        "text-yellow-500",
      ];
      return (
        <span
          className={`${bgColor} px-3 py-1 ${textColor} font-semibold text-xs rounded-md`}
        >
          {text}
        </span>
      );
    },
  },
  {
    key: 8,
    title: "Action",
    align: "center",
    width: "5%",
    render: (text, record) => (
      <Dropdown
        menu={{
          items: taskActions
            .filter(
              (item) =>
                !(item.key === 1 && record.status === "Completed") &&
                !(item.key === 6 && record.status === "Completed")
            )
            .map((item) => ({
              ...item,
              onClick: () => {
                if (item.key === 1) handleUpdate(record);
                else handleStatus(record, item.key);
              },
            })),
        }}
        trigger={["click"]}
      >
        <EllipsisOutlined className="cursor-pointer" />
      </Dropdown>
    ),
  },
];

const getWaitingRoomColumn = ({
  navigate,
  handleProviderChange,
  providers,
  handleRoomChange,
  handleWaitingRoomStatus,
  handleCheckout,
  rooms,
  waitingRoomStatus,
}) => [
  {
    key: 1,
    title: "Check in Date",
    ellipsis: true,
    width: "8%",
    align: "center",
    dataIndex: "checkin_time",
    render: (text) => (
      <Tooltip title={<FormatDateTime date={text} type="time" />}>
        <span>{text ? <FormatDateTime date={text} type="date" /> : ""}</span>
      </Tooltip>
    ),
  },
  {
    key: 2,
    title: "Patient Name",
    ellipsis: true,
    dataIndex: "patient",
    searchable: true,
    render: (_, record) => (
      <Button
        size="small"
        type="link"
        onClick={() => {
          navigate(`/main/charts/patient/${record?.patient_id}/`);
        }}
      >
        {record?.patient}
      </Button>
    ),
  },
  {
    key: 3,
    title: "Age",
    ellipsis: true,
    align: "center",
    dataIndex: "dob",
    width: "5%",
    render: (text) => (
      <span title={<FormatDate date={text} />}>{ageCalculator(text)} y/o</span>
    ),
  },
  {
    key: 4,
    title: "Reason for Visit",
    ellipsis: true,
    dataIndex: "reason_of_visit",
  },
  {
    key: 5,
    title: "Service Name",
    ellipsis: true,
    dataIndex: "service_name",
    searchable: true,
  },
  {
    key: 6,
    title: "Provider",
    // ellipsis: true,
    align: "center",
    width: "15%",
    dataIndex: "provider",
    render: (_, record) => (
      <Select
        style={{ width: 150 }}
        onChange={(value) => handleProviderChange(value, record)}
        value={record?.provider}
      >
        {providers?.map((provider) => (
          <Option key={provider?.id} value={`${provider?.name}`}>
            {provider?.name}
          </Option>
        ))}
      </Select>
    ),
    filters: providers?.map((provider) => ({
      text: provider?.name,
      value: `${provider?.name}`,
    })),
    onFilter: (value, record) => record?.provider === value,
  },
  {
    key: 7,
    title: "Room",
    ellipsis: true,
    align: "center",
    dataIndex: "room",
    render: (_, record) => (
      <Select
        style={{ width: 120 }}
        onChange={(value) => handleRoomChange(value, record)}
        value={record.room}
      >
        {Object?.keys(rooms)?.map((room) => (
          <Option key={room} value={room}>
            {rooms[room]}
          </Option>
        ))}
      </Select>
    ),
  },
  {
    key: 8,
    title: "Status",
    ellipsis: true,
    align: "center",
    dataIndex: "status",
    width: "12%",
    render: (text, record) => (
      <StatusBadge status={text} statusUpdatedOn={record?.status_updated_on} />
    ),
  },
  {
    key: 9,
    // title: "Action",
    align: "center",
    fixed: "right",
    width: "3%",
    render: (_, record) => (
      <Dropdown
        menu={{
          items:
            record.status === "Checked Out"
              ? []
              : waitingRoomStatus
                  ?.filter((item) => item.label !== record.status)
                  ?.map((item) => ({
                    ...item,
                    onClick: () => {
                      if (item.key >= 1 && item.key <= 7) {
                        handleWaitingRoomStatus(record, item.key);
                      } else if (item.key === 8) {
                        handleCheckout(record, item.key);
                      }
                    },
                  })),
        }}
        trigger={["click"]}
      >
        <DownOutlined className="cursor-pointer" />
      </Dropdown>
    ),
  },
];

const getGeneralNotesColumn = ({
  encounter_Status,
  role,
  generalNotesItems,
  handleDelete,
  handleViewNotes,
  openModal,
  cancelGeneralNotes,
  handlePrint,
}) => [
  {
    title: "Service Name",
    dataIndex: "service_type",
    width: "20%",
    ellipsis: true,
    render: (text, record) => (
      <span
        className="cursor-pointer text-[#1890ff]"
        onClick={() => handleViewNotes(record)}
      >
        {text}
      </span>
    ),
  },
  {
    title: "Description",
    dataIndex: "description",
    width: "20%",
    ellipsis: true,
  },
  {
    title: "Reason for Visit",
    dataIndex: "reason",
    width: "15%",
    ellipsis: true,
  },
  {
    title: "Created By",
    dataIndex: "created_by",
    width: "12%",
    align: "center",
    ellipsis: true,
  },
  {
    title: "Date",
    dataIndex: "date_time",
    key: "date_time",
    align: "center",
    ellipsis: true,
    render: (text) => <FormatDateTime date={text} type="date" />,
  },
  {
    title: "Time",
    dataIndex: "date_time",
    key: "time",
    align: "center",
    ellipsis: true,
    render: (text) => <FormatDateTime date={text} type="time" />,
  },
  {
    title: "Status",
    dataIndex: "status",
    key: 1,
    align: "center",
    filters: encounter_Status.map((text) => ({
      text: text.label,
      value: text.label,
    })),
    onFilter: (value, record) =>
      record?.status?.toLowerCase().includes(value.toLowerCase()),
    defaultFilteredValue: ["Draft", "Signed"],
    render: (status) => <InvoiceStatus status={status} />,
  },
  {
    title: "Action",
    fixed: "right",
    align: "center",
    width: "8%",
    render: (text, record) => {
      const { status } = record;
      let availableItems;
      if (status === "Cancelled") {
        availableItems = generalNotesItems.filter(
          (item) => ![2, 5, 6].includes(item.key)
        );
      } else if (role === "nurse") {
        availableItems =
          status === "Draft"
            ? generalNotesItems.filter((item) => [1, 2, 5].includes(item.key))
            : generalNotesItems.filter((item) => [1, 3].includes(item.key));
      } else {
        availableItems =
          status === "Draft"
            ? generalNotesItems.filter((item) => [1, 2, 5].includes(item.key))
            : generalNotesItems.filter((item) => [1, 3, 6].includes(item.key));
      }

      return (
        <Dropdown
          menu={{
            items: availableItems.map((item) => ({
              ...item,
              onClick: () => {
                if (item.key === 5) {
                  handleDelete(record.id);
                } else if (item.key === 1) {
                  handleViewNotes(record);
                } else if (item.key === 2) {
                  openModal(record, true);
                } else if (item.key === 6) {
                  cancelGeneralNotes(record);
                } else if (item.key === 3) {
                  handlePrint(record);
                }
              },
            })),
          }}
          trigger={["click"]}
        >
          <EllipsisOutlined className="cursor-pointer" />
        </Dropdown>
      );
    },
  },
];

const getInventoryColumns = ({ role, handleEdit, showDeleteConfirm }) => [
  {
    title: "SKU",
    dataIndex: "sku",
    width: "7%",
    align: "center",
    searchable: true,
  },
  {
    title: "Brand Name",
    dataIndex: "medicine_name",
    align: "left",
    width: "10%",
    ellipsis: true,
    searchable: true,
  },
  {
    title: "Generic Name",
    dataIndex: "generic_name",
    align: "center",
    ellipsis: true,
    searchable: true,
  },
  {
    title: "Manufacturer",
    dataIndex: "manufacturer",
    align: "center",
    ellipsis: true,
    searchable: true,
  },
  {
    title: "Category",
    dataIndex: "category",
    align: "center",
    ellipsis: true,
  },
  {
    title: "Strength",
    dataIndex: "strength",
    align: "center",
    ellipsis: true,
  },
  {
    title: "Location",
    dataIndex: "location",
    align: "center",
    ellipsis: true,
    searchable: true,
  },
  {
    title: "Quantity",
    dataIndex: "quantity",
    align: "center",
    ellipsis: true,
    render: (text) =>
      text > 2 && text < 5 ? (
        <span className="bg-yellow-200 px-3 py-[4px] font-medium text-xs rounded-md">
          {text}
        </span>
      ) : text < 2 ? (
        <span className="bg-red-300 px-3 py-[4px] font-medium text-xs rounded-md">
          {text}
        </span>
      ) : (
        <span>{text}</span>
      ),
  },
  {
    title: "Expiry Date",
    dataIndex: "expiry_date",
    align: "center",
    ellipsis: true,
    sorter: (a, b) => {
      const dateA = new Date(a.expiry_date);
      const dateB = new Date(b.expiry_date);
      return dateA - dateB;
    },
    render: (text) => {
      let backgroundColorClass = "";
      if (text !== null) {
        const expiryDate = text !== null ? new Date(text) : "";
        const today = new Date();
        const nextMonth = new Date();
        nextMonth.setDate(today.getDate() + 30);

        if (expiryDate < today) {
          backgroundColorClass = "bg-red-300";
        } else if (expiryDate < nextMonth) {
          backgroundColorClass = "bg-yellow-200";
        } else {
          backgroundColorClass = "bg-transparent";
        }
      }

      return (
        <span className={`px-3 py-[4px] rounded ${backgroundColorClass}`}>
          {text && <FormatDate date={text} />}
        </span>
      );
    },
  },
  {
    title: "MSRP",
    dataIndex: "MSRP",
    align: "center",
    ellipsis: true,
    render: (text) => <NumberFormatter value={text} />,
  },
  {
    title: "Sale Price",
    dataIndex: "sale_price",
    align: "center",
    ellipsis: true,
    render: (text) => <NumberFormatter value={text} />,
  },
  {
    title: "Date Added",
    dataIndex: "date_time",
    align: "center",
    ellipsis: true,
    render: (text) => text && <FormatDate date={text} />,
  },
  ...(role !== "nurse" && role !== "doctor"
    ? [
        {
          title: "Action",
          width: "6%",
          align: "center",
          fixed: "right",
          render: (record) => (
            <span>
              <EditOutlined
                className="mr-2 text-[#1890ff]"
                onClick={() => handleEdit(record)}
              />
              <DeleteOutlined
                className="text-red-500"
                onClick={() => showDeleteConfirm(record?.id)}
              />
            </span>
          ),
        },
      ]
    : []),
];

export {
  recentEncountersColumns,
  RecentPatientsColumns,
  getServiceListColumns,
  getLabsListColumn,
  getGroupColumn,
  getServiceTypeColumn,
  getWaitingRoomColumn,
  getGeneralNotesColumn,
  getInventoryColumns,
  getTasksColumn,
};
