import React, { useEffect } from "react";
import { useStateGlobalContext } from "../../contexts/GlobalContext";
import { useParams } from "react-router-dom";
import { Divider } from "antd";
import "tailwindcss/tailwind.css";
import FormatDate from "../FormatDate/FormatDate";

const renderInsuranceInfo = (insurance, type) => {
  if (!insurance || insurance.length === 0) {
    return (
      <>
        <h4 className="text-center mb-6 text-xl font-semibold">
          Patient's {type === 'primary' ? "Primary" : "Secondary"} Insurance
        </h4>
        <p>No {type} insurance data available</p>
      </>
    );
  }

  const insuranceData = insurance[0];

  return (
    <>
      <h4 className="text-center mb-6 text-xl font-semibold">
        Patient's {type === 'primary' ? "Primary" : "Secondary"} Insurance
      </h4>
      <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
        <p><strong>Type:</strong> {insuranceData.insurance_type}</p>
        <p><strong>Company:</strong> {insuranceData.insurance_company?.insurance_company_name}</p>
        <p><strong>Company Phone:</strong> {insuranceData.insurance_company?.insurance_company_phone}</p>
        <p><strong>Plan:</strong> {insuranceData.insured_person_info?.insurance_plan}</p>
        <p><strong>Group:</strong> {insuranceData.insured_person_info?.insured_group}</p>
        <p><strong>Policy:</strong> {insuranceData.insured_person_info?.insurance_policy}</p>
      </div>
    </>
  );
};


const FaceSheetHeader = ({ primaryInsurance, secondaryInsurance }) => {
  const { id } = useParams();
  const { patientBasicInfo: data, getPatientData } = useStateGlobalContext();

  const full_name = `${data?.first_name || ""} ${data?.middle_name || ""} ${data?.last_name || ""}`;

  useEffect(() => {
    getPatientData(id);
  }, [id]);
  // <p>Payment Method: {rxData?.payment_method ? rxData.payment_method.charAt(0).toUpperCase() + rxData.payment_method.slice(1) : ''}</p>

  return (
    <div className="p-4">
      <h4 className="text-center mb-6 text-xl font-semibold">
        Patient Demographics
      </h4>
      <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
        <p><strong>Full Name:</strong> {full_name}</p>
        <p><strong>Gender:</strong> {data?.gender?.charAt(0).toUpperCase() + data?.gender?.slice(1)}</p>
        <p><strong>DOB:</strong> <FormatDate date={data?.date_of_birth} type="date"/></p>
        <p><strong>Marital Status:</strong> {data?.marital_status}</p>
        <p><strong>Phone Number:</strong> {data?.patient_phone}</p>
        <p><strong>Email:</strong> {data?.patient_email}</p>
        <p><strong>Full Address: </strong> 
          {`${data?.current_address_line_1 || ""} ${data?.current_address_line_2 ? `, ${data?.current_address_line_2}` : ""} ${data?.current_city ? `, ${data?.current_city}` : ""} ${data?.current_state ? `, ${data?.current_state}` : ""} ${data?.current_country ? `, ${data?.current_country}` : ""}`}
        </p>
        <p><strong>Preferred Language:</strong> {data?.languages}</p>
        <p><strong>Religion:</strong> {data?.religion}</p>
        <p><strong>Social Security:</strong> {data?.ssn}</p>
        <p><strong>Driver Lic:</strong> {data?.license}</p>
      </div>

      <Divider className="my-6" />

      <div>
        <h4 className="text-center mb-6 text-xl font-semibold">
          Emergency Contact Information
        </h4>
        <div className="grid grid-cols-1 md:grid-cols-3 gap-4">
          <p><strong>Full Name:</strong> {data?.emergency_first_name} {data?.emergency_last_name}</p>
          <p><strong>Phone:</strong> {data?.emergency_phone_number}</p>
          <p><strong>Relation:</strong> {data?.emergency_relationship}</p>
        </div>
      </div>

      <Divider className="my-6" />

      <div>
        {primaryInsurance && renderInsuranceInfo(primaryInsurance, 'primary')}
        {primaryInsurance && <Divider className="my-6" />}
        {secondaryInsurance && renderInsuranceInfo(secondaryInsurance, 'secondary')}
      </div>
    </div>
  );
};

export default FaceSheetHeader;

